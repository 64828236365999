import React from 'react';
import { useSelector } from 'react-redux';
import SwitchSideBgIcon from '../../../../assets/icons/sniper/switch-side-bg.svg';
import SwitchSideIcon from '../../../../assets/icons/sniper/switch-side.svg';

import styles from './styles.module.scss';
import TokenBlock from './TokenBlock';

const InputsBox = ({
    side,
    amount,
    token,
    onTokenAmountChange,
    changeTokenAmount,
    amountOut,
    openTokenPicker,
    sideChangeHandler,
    amountOnWallet,
}) => {
    const blockchain = useSelector((state) => state.sniper.blockchain);

    return (
        <div className={styles.tokenBox}>
            {['sell', 'buy'].includes(side) ? (
                <>
                    <TokenBlock
                        isActive={true}
                        type={'sell'}
                        amount={amount}
                        side={side}
                        token={token}
                        onAmountChange={onTokenAmountChange}
                        openTokenPicker={openTokenPicker}
                        blockchain={blockchain}
                        amountOnWallet={amountOnWallet}
                        changeTokenAmount={changeTokenAmount}
                    />
                    <button
                        className={styles.sideBtn}
                        onClick={() => {
                            side === 'sell'
                                ? sideChangeHandler('buy')
                                : sideChangeHandler('sell');
                        }}
                    >
                        <img src={SwitchSideIcon} alt="switch side" />
                        <img
                            className={styles.switchSideBgIcon}
                            src={SwitchSideBgIcon}
                            alt="switch side"
                        />
                    </button>
                    <TokenBlock
                        isActive={false}
                        type={'buy'}
                        amount={amountOut}
                        side={side}
                        token={token}
                        onAmountChange={onTokenAmountChange}
                        openTokenPicker={openTokenPicker}
                        blockchain={blockchain}
                        amountOnWallet={amountOnWallet}
                        changeTokenAmount={changeTokenAmount}
                    />
                </>
            ) : (
                <>Invalid transaction side</>
            )}
        </div>
    );
};

export default InputsBox;
