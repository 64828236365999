import cn from 'classnames';
import React from 'react';
import ErrorIcon from '../../../assets/icons/sniper/notify/error.svg';
import SuccessIcon from '../../../assets/icons/sniper/notify/success.svg';
import styles from './styles.module.scss';

const TransactionNotify = ({ notifyData }) => {
    const { text, description, type, txLink } = notifyData;
    return (
        <div
            className={cn(
                styles.notifyContainer,
                type === 'success' && styles.notifyContainer_success,
                type === 'error' && styles.notifyContainer_error
            )}
        >
            <div className={styles.notifyHeader}>
                <div
                    className={cn(
                        styles.imgContainer,
                        type === 'success'
                            ? styles.imgContainer_success
                            : styles.imgContainer_error
                    )}
                >
                    <img
                        src={type === 'success' ? SuccessIcon : ErrorIcon}
                        alt={type}
                    />
                </div>
                <span
                    className={cn(
                        styles.notifyTitle,
                        type === 'success'
                            ? styles.notifyTitle_success
                            : styles.notifyTitle_error
                    )}
                >
                    {text}
                </span>
            </div>
            <p className={styles.notifyDescription}>{description}</p>
            {txLink && (
                <a
                    className={styles.notifyTxLink}
                    rel="noreferrer"
                    target="_blank"
                    href={txLink}
                >
                    View transaction
                </a>
            )}
        </div>
    );
};

export default TransactionNotify;
