import React from 'react';
import styles from './DeleteWalletModal.module.scss';

const DeleteWalletModal = ({ isOpen, onClose, onDelete, wallet }) => {
  if (!isOpen) return null;

  const handleDelete = () => {
    onDelete(wallet);
    onClose();
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h1 className={styles.title}>Confirm action?</h1>
        <p className={styles.description}>
          This section allows you to add wallets with which you will work, more detailed description see in Docs section
        </p>
        <div className={styles.buttonContainer}>
          <button className={styles.cancelButton} onClick={onClose}>Cancel</button>
          <button className={styles.deleteButton} onClick={handleDelete}>Delete wallet</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteWalletModal;