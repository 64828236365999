import React from 'react';
import OptionsMenu from '../shared/OptionsMenu';
import PosList from './PosList';
import styles from './styles.module.scss';

const PositionsTable = ({ showNotify }) => {
    return (
        <div className={styles.positionsContainer}>
            <div className={styles.positionsHeader}>
                <h3 className={styles.title}>Positions</h3>
                <OptionsMenu
                    containerClassName={styles.headerMenu}
                    chainBtnsClassName={styles.chainBtn}
                />
            </div>
            <PosList showNotify={showNotify} />
        </div>
    );
};

export default PositionsTable;
