import cn from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sniperBlockchains } from '../../../../constants';
import { chainsIconsMap } from '../../../../pages/DexTerminal/config';
import { changeSniperBlockchain } from '../../../../redux/action/sniper';
import styles from './styles.module.scss';

const ChainPicker = ({ containerClassName, itemClassName }) => {
    const dispatch = useDispatch();

    const blockchain = useSelector((state) => state.sniper.blockchain);

    return (
        <div className={cn(styles.chainsBlock, containerClassName)}>
            {sniperBlockchains.map((chain) => {
                return (
                    <button
                        className={cn(
                            styles.chainBtn,
                            itemClassName,
                            chain === 'solana'
                                ? styles.chainBtn_sol
                                : chain === 'eth'
                                  ? styles.chainBtn_eth
                                  : chain === 'bsc' && styles.chainBtn_bsc
                        )}
                        onClick={() => {
                            dispatch(changeSniperBlockchain(chain));
                        }}
                        key={chain}
                        disabled={blockchain === chain}
                    >
                        <img src={chainsIconsMap[chain]} alt={chain} />
                    </button>
                );
            })}
        </div>
    );
};

export default ChainPicker;
