import { arbitrageTypes } from '../../constants';

export const filterType = (item, hash) => {
    const type = hash.slice(1);
    return (
        (type === arbitrageTypes.free && item.Profit <= 10) ||
        item.Kind === hash.slice(1)
    );
};

export const getRandomItems = (array, count) => {
    const result = [];
    const usedIndices = new Set();

    while (result.length < count && result.length < array.length) {
        const randomIndex = Math.floor(Math.random() * array.length);
        if (!usedIndices.has(randomIndex)) {
            usedIndices.add(randomIndex);
            result.push(array[randomIndex]);
        }
    }

    return result;
};
