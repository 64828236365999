import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chart from '../../components/DexTerminalPage/Chart';
import Header from '../../components/DexTerminalPage/Header';
import Portfolio from '../../components/DexTerminalPage/Portfolio';
import PositionsTable from '../../components/DexTerminalPage/PositionsTabe';
import TransactionNotify from '../../components/DexTerminalPage/TransactionNotify';
import WalletMenu from '../../components/DexTerminalPage/WalletMenu';
import WidgetBlock from '../../components/DexTerminalPage/WidgetBlock';
import { useSniperSocket } from '../../hooks/useSniperSocket';
import {
    getQuote,
    getQuoteLoadingError,
    getQuoteLoadingStart,
    requestPositions,
    requestTokens,
} from '../../redux/action/sniper';
import styles from './DexTerminal.module.scss';

const Widget = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const data = useSelector((state) => state.sniper.quote);
    const blockchain = useSelector((state) => state.sniper.blockchain);

    const [token, setToken] = useState(null);
    const [side, setSide] = useState('buy');
    const [amount, setAmount] = useState(0);
    const [isWalletAdderOpened, setIsWalletAdderOpened] = useState(false);
    const [isPortfolioOpened, setIsPortfolioOpened] = useState(false);
    const [view, setView] = useState({
        isChart: false,
        isTable: false,
    });
    const [notify, setNotify] = useState(null);

    const quoteRequest = useMemo(
        () => ({
            event: 'quote',
            id: user?.id || -1,
            blockchain: blockchain,
            side: side,
            amount: amount,
            token_address: token?.address || '',
        }),
        [user?.id, blockchain, side, amount, token?.address]
    );

    const sendMessage = useSniperSocket(
        useCallback((message) => dispatch(getQuote(message)), [dispatch]),
        useCallback(() => dispatch(getQuoteLoadingStart()), [dispatch]),
        useCallback(
            (error) => dispatch(getQuoteLoadingError(error)),
            [dispatch]
        )
    );

    useEffect(() => {
        if (amount !== 0 && quoteRequest.token_address) {
            sendMessage(quoteRequest);
        }
    }, [amount, sendMessage, quoteRequest]);

    useEffect(() => {
        if (blockchain) {
            dispatch(requestTokens(blockchain));
        }
    }, [dispatch, blockchain]);

    useEffect(() => {
        if (user) {
            dispatch(
                requestPositions(
                    user.sol_contract,
                    user.eth_contract,
                    user.bsc_contract
                )
            );
        }
    }, [user, dispatch]);

    const amountOut = data?.amount_out || 0;
    const initGasPrice = data?.gas_price || 0;
    const tvl = data?.tvl || 0;

    const amountChangeHandler = useCallback(
        (newAmount) => setAmount(newAmount),
        []
    );
    const sideChangeHandler = useCallback((newSide) => setSide(newSide), []);

    const tokenChangeHandler = useCallback(
        (newToken) => setToken(newToken),
        []
    );

    const addWalletHandler = useCallback(() => {
        setIsWalletAdderOpened(true);
    }, []);

    const closeWalletMenuHandler = useCallback(() => {
        setIsWalletAdderOpened(false);
    }, []);

    const openPortfolioHandler = useCallback(() => {
        setIsPortfolioOpened(true);
    }, []);

    const closePortfolioHandler = useCallback(() => {
        setIsPortfolioOpened(false);
    }, []);

    const togglePanel = useCallback((type) => {
        setView((prev) => ({
            isChart: type === 'table' ? prev.isChart : !prev.isChart,
            isTable: type === 'chart' ? prev.isTable : !prev.isTable,
        }));
    }, []);

    const showNotify = (text, type, txLink, timeout, description) => {
        setNotify({ text, type, txLink, description });
        setTimeout(() => {
            setNotify(null);
        }, timeout);
    };

    if (data?.error) {
        let errorMsg = 'Unexpected error...';

        if (typeof data?.error === 'string') {
            errorMsg = data?.error;
        }

        showNotify(errorMsg, 'error', null, 3000);
    }

    return (
        <>
            <article className={styles.container}>
                {isWalletAdderOpened && (
                    <WalletMenu
                        blockchain={blockchain}
                        closeWalletMenuHandler={closeWalletMenuHandler}
                    />
                )}
                {isPortfolioOpened && (
                    <Portfolio
                        blockchain={blockchain}
                        closePortfolioHandler={closePortfolioHandler}
                    />
                )}

                {notify !== null && <TransactionNotify notifyData={notify} />}

                <Header
                    addWalletHandler={addWalletHandler}
                    openPortfolioHandler={openPortfolioHandler}
                />
                <main
                    className={cn(
                        styles.widgetsContainer,
                        (view.isChart || view.isTable) &&
                            styles.widgetsContainer_expandedPanel
                    )}
                >
                    <div
                        className={cn(
                            styles.infoWidgetsContainer,
                            view.isChart &&
                                view.isTable &&
                                styles.infoWidgetsContainer_expanded
                        )}
                    >
                        {view.isChart && (
                            <Chart
                                chain={blockchain}
                                tokenAddress={token?.address}
                            />
                        )}
                        {view.isTable && (
                            <PositionsTable showNotify={showNotify} />
                        )}
                    </div>
                    <WidgetBlock
                        isChartOpened={view.isChart}
                        isPosTableOpened={view.isTable}
                        togglePanel={togglePanel}
                        sideChangeHandler={sideChangeHandler}
                        amountChangeHandler={amountChangeHandler}
                        tokenChangeHandler={tokenChangeHandler}
                        tvl={tvl}
                        amountIn={quoteRequest.amount}
                        amountOut={amountOut}
                        blockchain={blockchain}
                        token={token}
                        side={quoteRequest.side}
                        userWallet={user?.wallet}
                        id={user?.id}
                        initialGasPrice={initGasPrice}
                        showNotify={showNotify}
                    />
                </main>
            </article>
        </>
    );
};

export default Widget;
