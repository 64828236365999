import React from 'react';
import MainInput from '../../../../layout/MainInput/MainInput';
import Svg from '../../../../layout/Svg/Svg';
import CheckboxFilter from './CkeckboxFilter';
import SelectFilter from './SelectFilter';
import styles from './styles.module.scss';


const FilterModalItem = ({
    filter,
    formData,
    onToggleCheckBox,
    onToggleBlockchainSelect,
    onToggleSortSelect,
    onChooseSortSelect,
    onChooseBlockchainSelect,
    onInputChange,
    chosenBlockchain,
    chosenSort,
    isBlockchainSelectOpened,
    isSortSelectOpened,
}) => {
    if (!filter) return <></>;

    const {
        filterType,
        filterKey,
        filterLabel,
        filterOptions,
        filterSubLabel,
        filterIcon,
    } = filter;

    return (
        <div className={styles['item']}>
            {filterLabel && (
                <>
                    <h6
                        className={`${styles.title} ${filterSubLabel && styles.title__withSubTitle}`}
                    >
                        {filterIcon && <Svg id={filterIcon} />}
                        {filterLabel}
                    </h6>
                    {filterSubLabel && (
                        <span className={styles.inputLabelText}>
                            {filterSubLabel}
                        </span>
                    )}
                </>
            )}
            {(filterKey === 'exchanges' ||
                filterKey === 'blockchains' ||
                filterKey === 'funding_kinds' ||
                filterKey === 'estimate') && (
                <>
                    <CheckboxFilter
                        options={filterOptions}
                        formData={formData}
                        onToggleCheckBox={onToggleCheckBox}
                        name={filterKey}
                    />
                    {filterKey === 'exchanges' && (
                        <b className={styles['sideBarLinks__linebtm']} />
                    )}
                </>
            )}
            {filterKey === 'blockchain' && (
                <SelectFilter
                    isSelectOpened={isBlockchainSelectOpened}
                    options={filterOptions}
                    onChooseSelect={onChooseBlockchainSelect}
                    onToggleSelect={onToggleBlockchainSelect}
                    chosenOption={chosenBlockchain}
                />
            )}
            {filterKey === 'sort' && (
                <SelectFilter
                    isSelectOpened={isSortSelectOpened}
                    options={filterOptions}
                    onChooseSelect={onChooseSortSelect}
                    onToggleSelect={onToggleSortSelect}
                    chosenOption={chosenSort}
                    name={filterKey}
                />
            )}
            {filterType === 'input' && (
                <div className={styles.inputContainer}>
                    <MainInput
                        onChange={onInputChange}
                        type="number"
                        className={styles['input']}
                        value={formData[filterKey]}
                        name={filterKey}
                        onWheel={(e) => e.target.blur()}
                    />
                    {(filterKey === 'update_time' || filterKey === 'hidden_time') && (
                        <div className={styles.timeInfo}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="1.75" y="1.75" width="10.5" height="10.5" rx="5.25" fill="#A0A0C4" fillOpacity="0.16"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M7 1.3125C3.85888 1.3125 1.3125 3.85888 1.3125 7C1.3125 10.1411 3.85888 12.6875 7 12.6875C10.1411 12.6875 12.6875 10.1411 12.6875 7C12.6875 3.85888 10.1411 1.3125 7 1.3125ZM2.1875 7C2.1875 4.34213 4.34213 2.1875 7 2.1875C9.65787 2.1875 11.8125 4.34213 11.8125 7C11.8125 9.65787 9.65787 11.8125 7 11.8125C4.34213 11.8125 2.1875 9.65787 2.1875 7ZM7.4375 4.66667C7.4375 4.42504 7.24162 4.22917 7 4.22917C6.75838 4.22917 6.5625 4.42504 6.5625 4.66667V7C6.5625 7.14628 6.63561 7.28288 6.75732 7.36402L8.50732 8.53069C8.70836 8.66472 8.97999 8.61039 9.11402 8.40935C9.24805 8.2083 9.19373 7.93667 8.99268 7.80265L7.4375 6.76586V4.66667Z" fill="#A0A0C4"/>
                            </svg>
                            <span className={styles.timeText}>
                                {filterKey === 'update_time' ? 'sec' : 'min'}
                            </span>
                        </div>
                    )}
                </div>

            )}
        </div>
    );
};

export default FilterModalItem;
