import cn from 'classnames';
import React, { useState } from 'react';
import DarkDividerIcon from '../../../assets/icons/sniper/hints/dark-divider.png';
import InfoIcon from '../../../assets/icons/sniper/hints/info.svg';
import HighlighIcon from '../../../assets/icons/sniper/input-highlight.png';
import styles from './styles.module.scss';

const ColumnTitle = ({ data }) => {
    const [isHovered, setIsHovered] = useState(false);

    const { title, subTitle, hint } = data;

    const handleMouseEnter = (e) => {
        if (!e.target.closest(`.${styles.colHint}`)) {
            setIsHovered(true);
        }
    };

    const handleMouseLeave = (e) => {
        if (!e.target.closest(`.${styles.colHint}`)) {
            setIsHovered(false);
        }
    };

    return (
        <div>
            <span
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={cn(
                    styles.colTitle,
                    isHovered && styles.colTitle_active
                )}
            >
                <div
                    className={cn(
                        styles.colHint,
                        isHovered && styles.colHint_visible
                    )}
                >
                    <div className={styles.hintHeader}>
                        <img src={InfoIcon} alt="info" />
                        <img
                            className={styles.hintHighlight}
                            src={HighlighIcon}
                            alt="highlight"
                        />
                        <span className={styles.hintTitle}>{subTitle}</span>
                    </div>
                    <img src={DarkDividerIcon} alt="divider" />
                    <p className={styles.hintText}>
                        <span className={styles.hintSubtitle}>{subTitle}</span>
                        <span>{hint}</span>
                    </p>
                </div>
                {title}
            </span>
        </div>
    );
};

export default ColumnTitle;
