import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GasIcon from '../../../../assets/icons/sniper/gas.svg';
import InputHighlightIcon from '../../../../assets/icons/sniper/input-highlight.png';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { chainsIconsMap } from '../../../../pages/DexTerminal/config';
import {
    changeSniperGasPrice,
    changeSniperSlippage,
} from '../../../../redux/action/sniper';
import NumericInput from '../../../layout/NumericInput';
import styles from './styles.module.scss';

const ExtendedSettings = ({ closeSettings, isOpened }) => {
    const dispatch = useDispatch();

    const initGasPrice = useSelector((state) => state.sniper.gasPrice);
    const blockchain = useSelector((state) => state.sniper.blockchain);
    const initSlippage = useSelector((state) => state.sniper.slippage);

    const [gasPrice, setGasPrice] = useState(0);
    const [slippage, setSlippage] = useState(0);

    const [inputsView, setInputsView] = useState({
        gasFocus: false,
        slippageFocus: false,
    });

    const ref = useOutsideClick(closeSettings);
    const gasRef = useRef(null);
    const slipRef = useRef(null);

    const inputSizes = {
        normal: '12px',
        small: '10px',
        extraSmall: '8px',
    };
    const inputBreaks = { breakSmall: 8, breakExtraSmall: 12 };

    useEffect(() => {
        if (initGasPrice) {
            setGasPrice(initGasPrice);
        }
        if (initSlippage) {
            setSlippage(initSlippage);
        }
    }, [initGasPrice, initSlippage]);

    return (
        <div
            ref={ref}
            className={cn(
                styles.extendedSettings,
                isOpened && styles.extendedSettings_visible
            )}
        >
            <div className={styles.inputContainer}>
                <h5 className={styles.inputTitle}>
                    {blockchain === 'solana' ? 'SET JITO TIP' : 'SET GAS PRICE'}
                </h5>
                <div
                    className={cn(
                        styles.inputWrapper,
                        inputsView.gasFocus && styles.inputWrapper_active
                    )}
                    onClick={() => {
                        gasRef.current?.focus();
                    }}
                >
                    <img
                        className={cn(
                            styles.inputHighlight,
                            inputsView.gasFocus && styles.inputHighlight_active
                        )}
                        src={InputHighlightIcon}
                        alt="focus"
                    />

                    <NumericInput
                        fontSizes={inputSizes}
                        sizeBreaks={inputBreaks}
                        value={gasPrice}
                        placeholder={0}
                        onChange={(newGas) => {
                            setGasPrice(newGas);
                        }}
                        ref={gasRef}
                        className={styles.input}
                        onFocus={() => {
                            setInputsView(() => {
                                return { gasFocus: true, slippageFocus: false };
                            });
                        }}
                        onBlur={() => {
                            setInputsView(() => {
                                return {
                                    gasFocus: false,
                                    slippageFocus: false,
                                };
                            });
                        }}
                    />
                    <img
                        src={
                            blockchain === 'solana'
                                ? chainsIconsMap['solana']
                                : GasIcon
                        }
                        alt={blockchain === 'solana' ? 'jito' : 'gas'}
                    />
                </div>
            </div>
            <div className={styles.inputContainer}>
                <h5 className={styles.inputTitle}>SET SLIPPAGE</h5>
                <div
                    onClick={() => {
                        slipRef.current?.focus();
                    }}
                    className={cn(
                        styles.inputWrapper,
                        inputsView.slippageFocus && styles.inputWrapper_active
                    )}
                >
                    <img
                        className={cn(
                            styles.inputHighlight,
                            inputsView.slippageFocus &&
                                styles.inputHighlight_active
                        )}
                        src={InputHighlightIcon}
                        alt="focus"
                    />

                    <NumericInput
                        fontSizes={inputSizes}
                        sizeBreaks={inputBreaks}
                        value={slippage}
                        placeholder={0}
                        onChange={(newSlip) => {
                            setSlippage(newSlip);
                        }}
                        ref={slipRef}
                        className={styles.input}
                        onFocus={() => {
                            setInputsView(() => {
                                return { gasFocus: false, slippageFocus: true };
                            });
                        }}
                        onBlur={() => {
                            setInputsView(() => {
                                return {
                                    gasFocus: false,
                                    slippageFocus: false,
                                };
                            });
                        }}
                    />
                    <span className={styles.inputLabel}>Max %</span>
                </div>
            </div>
            <button
                className={styles.saveBtn}
                onClick={() => {
                    dispatch(
                        changeSniperGasPrice(
                            !isNaN(Number(gasPrice))
                                ? Number(gasPrice)
                                : initGasPrice
                        )
                    );
                    dispatch(
                        changeSniperSlippage(
                            !isNaN(Number(slippage))
                                ? Number(slippage)
                                : initSlippage
                        )
                    );
                    closeSettings();
                    setGasPrice(0);
                    setSlippage(0);
                }}
            >
                Save
            </button>
        </div>
    );
};

export default ExtendedSettings;
