import cn from 'classnames';
import React from 'react';
import ErrorIcon from '../../../assets/icons/sniper/walletsMenu/error.svg';
import SuccessIcon from '../../../assets/icons/sniper/walletsMenu/success.svg';
import styles from './WalletMenu.module.scss';

const Notify = ({ notifyData }) => {
    const iconMap = {
        success: SuccessIcon,
        error: ErrorIcon,
    };

    const { text, type } = notifyData;

    return (
        <div
            className={cn(
                styles.notify,
                type === 'success' && styles.notify_success,
                type === 'error' && styles.notify_error
            )}
        >
            <img src={iconMap[type]} alt={type} />
            {text}
        </div>
    );
};

export default Notify;
