import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './TrackerPageHeader.module.scss';
import { trackerTypes } from '../../../../constants';

const TrackerPageHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentHash = location.hash.slice(1);

    const renderNavButton = (text, route, hasDelimiter = true) => (
        <>
            <button
                className={`${styles['trackerHeader__navButton']} ${
                    currentHash === route ? styles['trackerHeader__navButton_active'] : ''
                }`}
                onClick={() => navigate(`/tracker#${route}`)}
            >
                {text}
            </button>
            {hasDelimiter && <div className={styles['trackerHeader__divider']} />}
        </>
    );

    return (
        <div className={styles['trackerHeader']}>
            <h5 className={'pageRouteTitle'}>Tracker</h5>
            <nav className={styles['trackerHeader__panel']}>
                {renderNavButton('Cex-Tracker', trackerTypes.CEX)}
                {renderNavButton('Dex-Tracker', trackerTypes.DEX, false)}
            </nav>
        </div>
    );
};

export default TrackerPageHeader;
