import React, { memo, useState } from 'react';
import { useFormValue } from '../../../../hooks/useFormValue';
import MainBtn from '../../../layout/MainBtn/MainBtn';
import MainInput from '../../../layout/MainInput/MainInput';
import Divider from '../../Divider/Divider';

import Svg from '../../../layout/Svg/Svg';
import styles from './SettingsForm.module.scss';

const SettingsForm = memo(
    ({
        fields,
        onSubmit,
        loading,
        error,
        submitSuccessText,
        buttons,
        readOnlyFields,
    }) => {
        const initialData = fields.reduce((acc, cur) => {
            acc[cur.key] = cur.initialValue || '';
            return acc;
        }, {});
        const { formData, onChange } = useFormValue(initialData);
        const [submitSuccess, setSubmitSuccess] = useState(false);

        let errorText = '';

        if (error && typeof error === 'string') {
            const errorField = fields.find((item) => error.includes(item.key));
            errorText = errorField
                ? error.replace(errorField.key, errorField.placeholder)
                : error;
        }

        if (error?.message) {
            const errorField = fields.find((item) =>
                error.message.includes(item.key)
            );
            errorText = errorField
                ? error.message.replace(errorField.key, errorField.placeholder)
                : error;
        }

        const onSubmitForm = (e) => {
            e.preventDefault();
            
            if (formData.new_password && formData.repeat_password) {
                if (formData.new_password !== formData.repeat_password) {
                    errorText = 'Новый пароль и подтверждение пароля не совпадают';
                    return;
                }
            }
        
            // Создаем новый объект без repeat_password
            const dataToSubmit = {
                old_password: formData.old_password,
                new_password: formData.new_password,
                email: formData.email
            };
        
            const onSuccess = () => {
                setSubmitSuccess(true);
                setTimeout(() => setSubmitSuccess(false), 3000);
            };
            
            onSubmit(dataToSubmit, onSuccess);
        };

        return (
            <form
                method="POST"
                className={styles['settingsForm']}
                onSubmit={onSubmitForm}
            >
                {fields.map(({ icon, placeholder, key, type, title, label }, index) => (
                    <div key={index}>
                        {label && <h4 className={styles['settingsForm__fieldTitle']}>{label}</h4>}
                        {title && <div className={styles['settingsForm__fieldTitle']}>{title}</div>}
                        <MainInput
                            disabled={loading}
                            icon={icon}
                            value={formData[key]}
                            name={key}
                            onChange={onChange}
                            type={type || 'text'}
                            placeholder={placeholder}
                            className={`${styles['settingsForm__input']} ${key === 'repeat_password' ? styles['settingsForm__input_repeat'] : ''}`}

                        />
                    </div>
                ))}
                <p
                    className={`${styles['settingsForm__notText']} ${styles['settingsForm__notText_fail']} ${error?.length > 0 && Boolean(error) && styles['settingsForm__notText_active']}`}
                >
                    {errorText}
                </p>
                <p
                    className={`${styles['settingsForm__notText']} ${styles['settingsForm__notText_success']} ${submitSuccess && styles['settingsForm__notText_active']}`}
                >
                    {submitSuccessText}
                </p>
                {readOnlyFields &&
                    readOnlyFields.map(
                        ({ icon, fieldLable, fieldValue }, index) => (
                            <div
                                key={index}
                                className={
                                    styles['settingsForm__readOnlyField']
                                }
                            >
                                <Svg id={icon} />
                                <span>{`${fieldLable}: ${fieldValue}`}</span>
                            </div>
                        )
                    )}
                {buttons &&
                    buttons.map((button, index) => (
                        <div key={index}>
                            {button.title && (
                                <div className={styles['settingsForm__fieldTitle']}>
                                    {button.title}
                                </div>
                            )}
                            <MainBtn
                                className={`${styles['settingsForm__btn']} ${button.primary ? styles['settingsForm__btn_primary'] : ''}`}
                                key={index}
                                onClick={button.onClick}
                                type="button"
                                icon={button.icon}
                            >
                                {button.text}
                            </MainBtn>
                        </div>
                    ))}
                <b className={styles['settingsForm__line']} />
                <MainBtn
                    disabled={loading}
                    type={'submit'}
                    className={styles['settingsForm__btn']}
                >
                    {fields.some(field => field.type === 'password') ? 'Change password' : 'Save changes'}
                </MainBtn>
            </form>
        );
    }
);

export default SettingsForm;
