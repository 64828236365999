import React from 'react';
import MainBtn from '../../../../layout/MainBtn/MainBtn';
import styles from './styles.module.scss';

const ModalButtons = ({ onCancel }) => {
    return (
        <div className={styles['modalButtons']}>
            <MainBtn type={'submit'}>Apply filters</MainBtn>
            <MainBtn type={'button'} onClick={onCancel} isPassive={true}>
                Cancel
            </MainBtn>
        </div>
    );
};

export default ModalButtons;
