import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import InputHighlightIcon from '../../../assets/icons/sniper/input-highlight.png';
import styles from './styles.module.scss';

const inputPadding = 6;
const suffixGap = 12;

const ExtendedInput = ({
    customFocus,
    value,
    icon,
    className,
    wrapperClassName,
    iconWrapperClassName,
    iconClassName,
    measure,
    placeholder,
    onChange,
    ...props
}) => {
    const suffixRef = useRef(null);
    const iconRef = useRef(null);

    const [inputRightPadding, setInputRightPadding] = useState(inputPadding);
    const [inputLeftMargin, setInputLeftMargin] = useState(inputPadding);
    const [fakeValue, setFakeValue] = useState(value);

    useEffect(() => {
        setFakeValue(value);
    }, [value]);

    useEffect(() => {
        const updateSizes = () => {
            const suffixWidth = suffixRef.current?.offsetWidth || 0;
            const iconWidth = iconRef.current?.offsetWidth || 0;

            setInputRightPadding(
                measure && suffixWidth
                    ? suffixWidth + inputPadding + suffixGap
                    : inputPadding
            );

            setInputLeftMargin(
                icon && iconWidth
                    ? iconWidth + inputPadding + suffixGap
                    : inputPadding
            );
        };

        updateSizes();

        const suffixObserver = new ResizeObserver(updateSizes);
        const iconObserver = new ResizeObserver(updateSizes);

        if (suffixRef.current) {
            suffixObserver.observe(suffixRef.current);
        }
        if (iconRef.current) {
            iconObserver.observe(iconRef.current);
        }

        return () => {
            suffixObserver.disconnect();
            iconObserver.disconnect();
        };
    }, [measure, icon]);

    return (
        <div className={cn(styles.inputWrapper, wrapperClassName)}>
            {customFocus && (
                <img
                    className={styles.inputHighlight}
                    src={InputHighlightIcon}
                    alt="focus"
                />
            )}
            <input
                onScroll={(e) => {
                    e.preventDefault();
                }}
                className={cn(styles.input, className)}
                style={{
                    padding: inputPadding,
                    paddingTop: inputPadding - 2,
                    paddingRight: inputRightPadding,
                    marginLeft: inputLeftMargin,
                    width: `calc(100% - ${inputLeftMargin}px - ${suffixGap}px)`,
                }}
                value={fakeValue}
                placeholder={placeholder}
                {...props}
                onChange={(e) => {
                    setFakeValue(e.target.value);
                    if (onChange) {
                        onChange(e);
                    }
                }}
            />
            <div
                className={styles.inputFakeValueWrapper}
                style={{ gap: suffixGap, padding: inputPadding }}
            >
                {icon && (
                    <div
                        ref={iconRef}
                        className={cn(styles.iconWrapper, iconWrapperClassName)}
                    >
                        <img
                            alt="input icon"
                            className={cn(styles.icon, iconClassName)}
                            src={icon}
                        />
                    </div>
                )}
                <span className={styles.inputFakeValue}>
                    {fakeValue !== undefined && fakeValue !== ''
                        ? fakeValue
                        : placeholder}
                </span>
                <span
                    style={{ marginLeft: suffixGap, paddingRight: suffixGap }}
                    ref={suffixRef}
                    className={styles.suffix}
                >
                    {measure}
                </span>
            </div>
        </div>
    );
};

export default ExtendedInput;
