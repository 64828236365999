import {
    CHANGE_SNIPER_BLOCKCHAIN,
    CHANGE_SNIPER_GAS_PRICE,
    CHANGE_SNIPER_SLIPPAGE,
    GET_EXT_TOKENS_ERROR,
    GET_EXT_TOKENS_LOADING_START,
    GET_EXT_TOKENS_SUCCESS,
    GET_POSITIONS_ERROR,
    GET_POSITIONS_LOADING_START,
    GET_POSITIONS_SUCCESS,
    GET_QUOTE_ERROR,
    GET_QUOTE_LOADING_START,
    GET_QUOTE_SUCCESS,
    GET_SWAP_ERROR,
    GET_SWAP_LOADING_START,
    GET_SWAP_SUCCESS,
    GET_TOKENS_ERROR,
    GET_TOKENS_LOADING_START,
    GET_TOKENS_SUCCESS,
    GET_TRADES_ERROR,
    GET_TRADES_LOADING_START,
    GET_TRADES_SUCCESS,
} from '../types';

const initialState = {
    quote: null,
    getQuoteLoading: false,
    getQuoteError: null,
    swap: null,
    getSwapLoading: false,
    getSwapError: null,
    externalTokens: [],
    getExtTokensLoading: false,
    getExtTokensError: null,
    tokens: [],
    getTokensLoading: false,
    getTokensError: null,
    trades: [],
    getTradesLoading: false,
    getTradesError: null,
    positions: [
        {
            priceNative: 128.87,
            priceUsdt: 27353.11,
            amount: 100,
            img: 'https://dd.dexscreener.com/ds-data/tokens/solana/7qMeS3q2DVZAERya2ywr1wUZqZoMSCK32Cv4fhcfpump.png?size=lg&key=19f221',
            name: 'SolanaToken1',
            blockchain: 'solana',
            address: 'SolAddr1',
            investedUsdt: 10,
            investedNative: 0.2,
            initialPos: 0.1,
            initialMC: 1300000,
            curPos: 0.2,
            curMC: 2000000,
            soldUsdt: 0,
            soldNative: 0,
            remainingUsdt: 10,
            remainingNative: 0.2,
            changeInPnLUsdt: 1000,
            changeInPnLPercent: 10.5,
        },
        {
            priceNative: 130.5,
            priceUsdt: 28000,
            amount: 100,
            img: 'https://dd.dexscreener.com/ds-data/tokens/solana/8aBcS3q2DVZAERya2ywr1wUZqZoMSCK32Cv4fhcfpump.png?size=lg&key=19f222',
            name: 'SolanaToken2',
            blockchain: 'solana',
            address: 'SolAddr2',
            investedUsdt: 20,
            investedNative: 0.3,
            initialPos: 0.15,
            initialMC: 1500000,
            curPos: 0.25,
            curMC: 2500000,
            soldUsdt: 5,
            soldNative: 0.05,
            remainingUsdt: 15,
            remainingNative: 0.25,
            changeInPnLUsdt: 1200,
            changeInPnLPercent: 12.5,
        },
        {
            priceNative: 2500.3,
            priceUsdt: 2500.3,
            amount: 100,
            img: 'https://dd.dexscreener.com/ds-data/tokens/ethereum/3eXcB9oAAvZnQmXcZjF32rRQPJbA8ZyUtrGmP9.png?size=lg&key=19f223',
            name: 'EthereumToken1',
            blockchain: 'eth',
            address: 'EthAddr1',
            investedUsdt: 500,
            investedNative: 0.2,
            initialPos: 0.2,
            initialMC: 5000000,
            curPos: 0.22,
            curMC: 5200000,
            soldUsdt: 0,
            soldNative: 0,
            remainingUsdt: 500,
            remainingNative: 0.2,
            changeInPnLUsdt: 150,
            changeInPnLPercent: 3,
        },
        {
            priceNative: 2600.75,
            priceUsdt: 2600.75,
            amount: 100,
            img: 'https://dd.dexscreener.com/ds-data/tokens/ethereum/6sTRrB8wYqMeVqMzPdX9PAJcFZbA3U92YgTm.png?size=lg&key=19f224',
            name: 'EthereumToken2',
            blockchain: 'eth',
            address: 'EthAddr2',
            investedUsdt: 1000,
            investedNative: 0.4,
            initialPos: 0.4,
            initialMC: 10000000,
            curPos: 0.45,
            curMC: 11500000,
            soldUsdt: 100,
            soldNative: 0.04,
            remainingUsdt: 900,
            remainingNative: 0.36,
            changeInPnLUsdt: 2000,
            changeInPnLPercent: 8,
        },
        {
            priceNative: 300.23,
            priceUsdt: 300.23,
            amount: 100,
            img: 'https://dd.dexscreener.com/ds-data/tokens/bsc/9qAeS4r2DZWAERya2ywr2XUZqZoMSCK32Cv4fhcfpump.png?size=lg&key=19f225',
            name: 'BscToken1',
            blockchain: 'bsc',
            address: 'BscAddr1',
            investedUsdt: 50,
            investedNative: 0.1,
            initialPos: 0.1,
            initialMC: 3000000,
            curPos: 0.12,
            curMC: 3500000,
            soldUsdt: 0,
            soldNative: 0,
            remainingUsdt: 50,
            remainingNative: 0.1,
            changeInPnLUsdt: 500,
            changeInPnLPercent: 6,
        },
        {
            priceNative: 305.75,
            priceUsdt: 305.75,
            amount: 100,
            img: 'https://dd.dexscreener.com/ds-data/tokens/bsc/7mTwS3q2ZVTAERya2ywr1wUZqZoMSCK32Cv4fhcfpump.png?size=lg&key=19f226',
            name: 'BscToken2',
            blockchain: 'bsc',
            address: 'BscAddr2',
            investedUsdt: 75,
            investedNative: 0.15,
            initialPos: 0.15,
            initialMC: 3500000,
            curPos: 0.18,
            curMC: 4000000,
            soldUsdt: 0,
            soldNative: 0,
            remainingUsdt: 75,
            remainingNative: 0.15,
            changeInPnLUsdt: 700,
            changeInPnLPercent: 7.5,
        },
        {
            priceNative: 400.1,
            priceUsdt: 400.1,
            amount: 100,
            img: 'https://dd.dexscreener.com/ds-data/tokens/solana/6wAzT4q2ZXFAERya2ywr3wUZqZoMSCK32Cv4fhcfpump.png?size=lg&key=19f227',
            name: 'SolanaToken3',
            blockchain: 'solana',
            address: 'SolAddr3',
            investedUsdt: 90,
            investedNative: 0.3,
            initialPos: 0.3,
            initialMC: 4000000,
            curPos: 0.35,
            curMC: 4500000,
            soldUsdt: 0,
            soldNative: 0,
            remainingUsdt: 90,
            remainingNative: 0.3,
            changeInPnLUsdt: 600,
            changeInPnLPercent: 5.8,
        },
        {
            priceNative: 500.5,
            priceUsdt: 500.5,
            amount: 100,
            img: 'https://dd.dexscreener.com/ds-data/tokens/ethereum/8mKqB3q2ZXFAERya2ywr5wUZqZoMSCK32Cv4fhcfpump.png?size=lg&key=19f228',
            name: 'EthereumToken3',
            blockchain: 'eth',
            address: 'EthAddr3',
            investedUsdt: 200,
            investedNative: 0.5,
            initialPos: 0.5,
            initialMC: 8000000,
            curPos: 0.55,
            curMC: 8500000,
            soldUsdt: 50,
            soldNative: 0.05,
            remainingUsdt: 150,
            remainingNative: 0.45,
            changeInPnLUsdt: 1200,
            changeInPnLPercent: 9.2,
        },
        {
            priceNative: 600.75,
            priceUsdt: 600.75,
            amount: 100,
            img: 'https://dd.dexscreener.com/ds-data/tokens/bsc/5nTzA3q2ZXFAERya2ywr6wUZqZoMSCK32Cv4fhcfpump.png?size=lg&key=19f229',
            name: 'BscToken3',
            blockchain: 'bsc',
            address: 'BscAddr3',
            investedUsdt: 120,
            investedNative: 0.6,
            initialPos: 0.6,
            initialMC: 9000000,
            curPos: 0.65,
            curMC: 9500000,
            soldUsdt: 0,
            soldNative: 0,
            remainingUsdt: 120,
            remainingNative: 0.6,
            changeInPnLUsdt: 800,
            changeInPnLPercent: 6.7,
        },
    ],
    getPosLoading: false,
    getPosError: null,
    blockchain: 'solana',
    slippage: 0.5,
    gasPrice: 0,
};

export const sniperReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_QUOTE_SUCCESS: {
            return {
                ...state,
                quote: payload,
                getQuoteLoading: false,
            };
        }

        case GET_QUOTE_LOADING_START: {
            return {
                ...state,
                getQuoteLoading: true,
                getQuoteError: null,
            };
        }

        case GET_QUOTE_ERROR: {
            return {
                ...state,
                getQuoteError: payload,
                getQuoteLoading: false,
            };
        }

        case GET_SWAP_SUCCESS: {
            return {
                ...state,
                swap: payload,
                getSwapLoading: false,
            };
        }

        case GET_SWAP_LOADING_START: {
            return {
                ...state,
                getSwapLoading: true,
                getSwapError: null,
            };
        }

        case GET_SWAP_ERROR: {
            return {
                ...state,
                getSwapError: payload,
                getSwapLoading: false,
            };
        }

        case GET_TOKENS_SUCCESS: {
            return {
                ...state,
                tokens: payload,
                getTokensLoading: false,
            };
        }

        case GET_TOKENS_LOADING_START: {
            return {
                ...state,
                getTokensLoading: true,
                getTokensError: null,
            };
        }

        case GET_TOKENS_ERROR: {
            return {
                ...state,
                getTokensError: payload,
                getTokensLoading: false,
            };
        }

        case GET_EXT_TOKENS_SUCCESS: {
            return {
                ...state,
                externalTokens: payload,
                getExtTokensLoading: false,
            };
        }

        case GET_EXT_TOKENS_LOADING_START: {
            return {
                ...state,
                getExtTokensLoading: true,
                getExtTokensError: null,
            };
        }

        case GET_EXT_TOKENS_ERROR: {
            return {
                ...state,
                getExtTokensError: payload,
                getExtTokensLoading: false,
            };
        }

        case GET_TRADES_SUCCESS: {
            return {
                ...state,
                trades: payload,
                getTradesLoading: false,
            };
        }

        case GET_TRADES_LOADING_START: {
            return {
                ...state,
                getTradesLoading: true,
                getTradesError: null,
            };
        }

        case GET_TRADES_ERROR: {
            return {
                ...state,
                getTradesError: payload,
                getTradesLoading: false,
            };
        }

        case GET_POSITIONS_SUCCESS: {
            return {
                ...state,
                positions: payload,
                getPosLoading: false,
            };
        }

        case GET_POSITIONS_LOADING_START: {
            return {
                ...state,
                getPosLoading: true,
                getPosError: null,
            };
        }

        case GET_POSITIONS_ERROR: {
            return {
                ...state,
                getPosError: payload,
                getPosLoading: false,
            };
        }

        case CHANGE_SNIPER_BLOCKCHAIN: {
            return { ...state, blockchain: payload };
        }

        case CHANGE_SNIPER_SLIPPAGE: {
            return { ...state, slippage: payload };
        }

        case CHANGE_SNIPER_GAS_PRICE: {
            return { ...state, gasPrice: payload };
        }

        default:
            return state;
    }
};
