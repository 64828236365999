import { useCallback, useEffect, useRef } from 'react';

export const useSniperSocket = (
    onMessageReceived,
    onLoadingStart,
    onLoadingError
) => {
    const url = process.env.REACT_APP_SNIPE_BASE_URL;
    const socketRef = useRef(null);

    useEffect(() => {
        if (!socketRef.current) {
            const socketUrl = `wss://${url}/ws`;
            const socket = new WebSocket(socketUrl);
            socketRef.current = socket;

            socket.addEventListener('open', () => {
                console.log('WebSocket connection opened');
                onLoadingStart();
            });

            socket.addEventListener('message', (event) => {
                console.log('Message received');
                try {
                    const responseData = JSON.parse(event.data);
                    console.log(responseData);
                    if (responseData.error) {
                        onLoadingError(responseData.error);
                    }
                    if (!responseData.error) {
                        onMessageReceived(responseData);
                    }

                    if (responseData.id === -1) {
                        window.location.reload();
                    }
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                    onLoadingError(error);
                }
            });

            socket.addEventListener('close', (event) => {
                console.log('WebSocket connection closed:', event);
            });

            socket.addEventListener('error', (event) => {
                console.error('WebSocket error:', event);
                onLoadingError(event);
            });
        }
        return () => {
            if (socketRef.current) {
                socketRef.current.close();
                socketRef.current = null;
            }
        };
    }, [url, onMessageReceived, onLoadingStart, onLoadingError]);

    const sendMessage = useCallback((requestMessage) => {
        if (socketRef.current?.readyState === WebSocket.OPEN) {
            console.log('Sending request message:', requestMessage);
            socketRef.current.send(JSON.stringify(requestMessage));
        }
    }, []);

    return sendMessage;
};
