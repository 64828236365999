import React, { useState, useEffect } from 'react';
import styles from './EditWalletModal.module.scss';

const EditWalletModal = ({ isOpen, onClose, wallet }) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (wallet) {
      setName(wallet.name);
      setAddress(wallet.address);
    }
  }, [wallet]);

  const handleSave = () => {
    // Здесь добавить логику сохранения
    console.log('Saving:', { name, address });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h3>Edit Wallet</h3>
          <button onClick={onClose} className={styles.closeButton}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </button>
        </div>
        <div className={styles.modalContent}>
          <input 
            type="text"
            placeholder="Wallet Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.input}
          />
          <input 
            type="text"
            placeholder="Wallet Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className={styles.input}
          />
        </div>
        <div className={styles.modalFooter}>
          <button className={styles.cancelButton} onClick={onClose}>Cancel</button>
          <button className={styles.saveButton} onClick={handleSave}>Save Changes</button>
        </div>
      </div>
    </div>
  );
};

export default EditWalletModal;
