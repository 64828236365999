import {
    CHANGE_SNIPER_BLOCKCHAIN,
    CHANGE_SNIPER_GAS_PRICE,
    CHANGE_SNIPER_SLIPPAGE,
    GET_EXT_TOKENS_ERROR,
    GET_EXT_TOKENS_SUCCESS,
    GET_POSITIONS_ERROR,
    GET_POSITIONS_LOADING_START,
    GET_POSITIONS_SUCCESS,
    GET_QUOTE_ERROR,
    GET_QUOTE_LOADING_START,
    GET_QUOTE_SUCCESS,
    GET_SWAP_ERROR,
    GET_SWAP_LOADING_START,
    GET_SWAP_SUCCESS,
    GET_TOKENS_ERROR,
    GET_TOKENS_LOADING_START,
    GET_TOKENS_SUCCESS,
    GET_TRADES_ERROR,
    GET_TRADES_LOADING_START,
    GET_TRADES_SUCCESS,
} from '../types';

import {
    authConfig,
    fetchRequest,
    fetchSolTokens,
    getTokensUrl,
    getTradesUrl,
} from './fetchTools';

export const getQuote = (payload) => (dispatch) => {
    dispatch({ type: GET_QUOTE_SUCCESS, payload });
};

export const getQuoteLoadingStart = () => (dispatch) => {
    dispatch({ type: GET_QUOTE_LOADING_START });
};

export const getQuoteLoadingError = (payload) => (dispatch) => {
    dispatch({ type: GET_QUOTE_ERROR, payload });
};

export const getSwap = (payload) => (dispatch) => {
    dispatch({ type: GET_SWAP_SUCCESS, payload });
};

export const getSwapLoadingStart = () => (dispatch) => {
    dispatch({ type: GET_SWAP_LOADING_START });
};

export const getSwapLoadingError = (payload) => (dispatch) => {
    dispatch({ type: GET_SWAP_ERROR, payload });
};

export const getTokens = (payload) => (dispatch) => {
    dispatch({ type: GET_TOKENS_SUCCESS, payload });
};

export const requestTokens = (blockchain) => async (dispatch) => {
    dispatch({ type: GET_TOKENS_LOADING_START });

    try {
        const data = await fetchRequest(
            getTokensUrl,
            'POST',
            { blockchain },
            authConfig(),
            true
        );
        dispatch(getTokens(data || []));
    } catch (err) {
        dispatch({ type: GET_TOKENS_ERROR, err });
    }
};

export const getExtTokens = (payload) => (dispatch) => {
    dispatch({ type: GET_EXT_TOKENS_SUCCESS, payload });
};

export const requestExternalTokens = (query) => async (dispatch) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SCREENER_LINK}search?q=${query}`,
            {
                method: 'GET',
                headers: {},
            }
        );

        const data = await response.json();

        dispatch(getExtTokens(data.pairs || []));
    } catch (err) {
        dispatch({ type: GET_EXT_TOKENS_ERROR, err });
    }
};

export const getTrades = (payload) => (dispatch) => {
    dispatch({ type: GET_TRADES_SUCCESS, payload });
};

export const requestTrades = (id, blockchain) => async (dispatch) => {
    dispatch({ type: GET_TRADES_LOADING_START });

    try {
        const data = await fetchRequest(
            getTradesUrl,
            'POST',
            { user_id: id, blockchain },
            authConfig(),
            true
        );
        dispatch(getTrades(data || []));
    } catch (err) {
        dispatch({ type: GET_TRADES_ERROR, err });
    }
};

export const getPositions = (payload) => (dispatch) => {
    dispatch({ type: GET_POSITIONS_SUCCESS, payload });
};

export const requestPositions =
    (solContract, ethContract, bscContract) => async (dispatch) => {
        dispatch({ type: GET_POSITIONS_LOADING_START });

        try {
            const solPoses = await fetchSolTokens(solContract);
            const allPoses = [...solPoses];
            dispatch(getPositions(allPoses));
        } catch (err) {
            dispatch({ type: GET_POSITIONS_ERROR, err });
        }
    };

export const changeSniperBlockchain = (payload) => (dispatch) => {
    dispatch({ type: CHANGE_SNIPER_BLOCKCHAIN, payload });
};

export const changeSniperSlippage = (payload) => (dispatch) => {
    dispatch({ type: CHANGE_SNIPER_SLIPPAGE, payload });
};

export const changeSniperGasPrice = (payload) => (dispatch) => {
    dispatch({ type: CHANGE_SNIPER_GAS_PRICE, payload });
};
