import React from 'react';
import { useSelector } from 'react-redux';
import OptionsActiveIcon from '../../../../assets/icons/sniper/optionsMenu/options-active.svg';
import MenuDividerIcon from '../../../../assets/icons/sniper/optionsMenu/options-divider.svg';
import OptionsIcon from '../../../../assets/icons/sniper/optionsMenu/options.svg';
import styles from './styles.module.scss';

const OptionsBlock = ({ toggleIsSettingsOpened, isSettingsOpened }) => {
    const gasPrice = useSelector((state) => state.sniper.gasPrice);
    const blockchain = useSelector((state) => state.sniper.blockchain);
    const slippage = useSelector((state) => state.sniper.slippage);

    return (
        <div className={styles.optionsBlock}>
            <p className={styles.optionsText}>
                <span className={styles.descriptionText}>
                    {blockchain === 'solana' ? 'JITO' : 'GAS'}
                </span>
                <span className={styles.valueText}>{gasPrice}</span>
            </p>
            <img src={MenuDividerIcon} alt="divider" />
            <p className={styles.optionsText}>
                <span className={styles.descriptionText}>SLIP</span>
                <span className={styles.valueText}>{slippage}</span>
                <span className={styles.descriptionText}>%</span>
            </p>
            <img src={MenuDividerIcon} alt="divider" />
            <button
                className={styles.optionsBtn}
                onClick={toggleIsSettingsOpened}
                disabled={isSettingsOpened}
            >
                <img
                    src={isSettingsOpened ? OptionsActiveIcon : OptionsIcon}
                    alt="toggle options"
                />
            </button>
        </div>
    );
};

export default OptionsBlock;
