import React from 'react';

import {
    arrowUp,
    arrowFall,
    crossIcon,
    eyeIcon,
    reportFakeIcon,
    kucoinIcon,
    binanceIcon,
    bitgetIcon,
    mexcIcon,
    gateIcon,
    bybitIcon,
} from '../../../../../assets/svg';
import {
    getTimeDifference,
    setDateText,
} from '../../../../../utils/functions/date';
import MainBtn from '../../../../layout/MainBtn/MainBtn';
import Svg from '../../../../layout/Svg/Svg';

import { trackerTypes } from '../../../../../constants';
import styles from './styles.module.scss';

const TrackerPageListItem = ({
    token,
    exchange,
    blockchain,
    current_price,
    last_price,
    tvl,
    timestamp,
    change,
    link,
    type,
    Item,
    onSendReportMessage,
    onAddToHidden,
    onAddToBlackList,
    HoldersLink,
    DexScreenerLink,
    GoPlusLink,
    snipeLink
}) => {
    const spotedText = getTimeDifference(timestamp);
    const isUp = current_price > last_price;

    const isExchangeActive = (exchangeName) => {
    const exchangeUrls = {
        'bybit': 'bybit.com',
        'binance': 'binance.com',
        'gate': 'gate.io',
        'kucoin': 'kucoin.com',
        'mexc': 'mexc.com',
        'bitget': 'bitget.com'
    };

    return link?.includes(exchangeUrls[exchangeName.toLowerCase()]);
};

    return (
        <div className={styles['trackerListItem']}>
            <div className={styles['trackerListItem__header']}>
                <h5 className={styles['trackerListItem__title']}>
                    {token?.toUpperCase().replace('USDT', '')}
                </h5>
                <div className={styles['trackerListItem__headerBtns']}>
                    <>
                        <button
                            className={styles['trackerListItem__hiderBtn']}
                            onClick={() => onSendReportMessage(Item)}
                        >
                            <Svg
                                id={reportFakeIcon}
                                className={
                                    styles['trackerListItem__reportIcon']
                                }
                            />
                        </button>
                        <button
                            onClick={() => {
                                onAddToHidden(Item);
                            }}
                            className={styles['trackerListItem__hiderBtn']}
                        >
                            <Svg
                                className={styles['trackerListItem__hideIcon']}
                                id={eyeIcon}
                            />
                        </button>
                        <button
                            className={styles['trackerListItem__hiderBtn']}
                            onClick={() => {
                                onAddToBlackList(token);
                            }}
                        >
                            <Svg
                                className={styles['trackerListItem__crossIcon']}
                                id={crossIcon}
                            />
                        </button>
                    </>
                </div>
            </div>

            <div className={`${styles['trackerListItem__resultBlock']} ${isUp ? styles['trackerListItem__resultBlock_up'] : styles['trackerListItem__resultBlock_fall']}`}>
            <p className={styles['trackerListItem__resultBlockText']}>
    <Svg 
        className={styles['trackerListItem__resultBlockIcon']}
        id={isUp ? arrowUp : arrowFall}
    />
    <span className={styles['trackerListItem__resultBlockValue']}>
        {change.toFixed(2)}%
    </span>
</p>

    <p className={styles['trackerListItem__resultBlockText']}>
        <span>
            {type === trackerTypes.CEX ? 'Current price: ' : 'Price: '}
        </span>
        <span className={styles['trackerListItem__resultBlockValue']}>
            {current_price}$
        </span>
    </p>

    <p className={styles['trackerListItem__resultBlockText']}>
        <span>
            {type === trackerTypes.CEX ? 'Previous price: ' : 'TVL: '}
        </span>
        <span>
            {type === trackerTypes.CEX ? last_price : tvl}$
        </span>
    </p>

    <p className={styles['trackerListItem__resultBlockText']}>
        <span>
            {type === trackerTypes.CEX ? 'Network: ' : 'Blockchain: '}
        </span>
        <span className={styles['trackerListItem__resultBlockValue']}>
            {(type === trackerTypes.CEX ? exchange : blockchain).charAt(0).toUpperCase() + 
            (type === trackerTypes.CEX ? exchange : blockchain).slice(1)}
        </span>
    </p>
</div>



            <div
                className={`${styles['trackerListItem__resultBlock']} ${isUp ? styles['trackerListItem__resultBlock_up'] : styles['trackerListItem__resultBlock_fall']}`}
            >
                <h3 className={styles['trackerListItem__gridTitle']}>Stock exchanges:</h3>
                <div className={styles['trackerListItem__gridContainer']}>
    <div 
        className={`${styles['trackerListItem__gridItem']} ${isExchangeActive('bybit') ? styles['trackerListItem__gridItem_active'] : ''}`}
        onClick={() => isExchangeActive('bybit') && window.open(link, '_blank')}
    >
        <Svg id={bybitIcon} className={styles['trackerListItem__exchangeIcon']} />
        Bybit
    </div>
    <div 
        className={`${styles['trackerListItem__gridItem']} ${isExchangeActive('binance') ? styles['trackerListItem__gridItem_active'] : ''}`}
        onClick={() => isExchangeActive('binance') && window.open(link, '_blank')}
    >
        <Svg id={binanceIcon} className={styles['trackerListItem__exchangeIcon']} />
        Binance
    </div>
    <div 
        className={`${styles['trackerListItem__gridItem']} ${isExchangeActive('gate') ? styles['trackerListItem__gridItem_active'] : ''}`}
        onClick={() => isExchangeActive('gate') && window.open(link, '_blank')}
    >
        <Svg id={gateIcon} className={styles['trackerListItem__exchangeIcon']} />
        Gate
    </div>
    <div 
        className={`${styles['trackerListItem__gridItem']} ${isExchangeActive('kucoin') ? styles['trackerListItem__gridItem_active'] : ''}`}
        onClick={() => isExchangeActive('kucoin') && window.open(link, '_blank')}
    >
        <Svg id={kucoinIcon} className={styles['trackerListItem__exchangeIcon']} />
        Kucoin
    </div>
    <div 
        className={`${styles['trackerListItem__gridItem']} ${isExchangeActive('mexc') ? styles['trackerListItem__gridItem_active'] : ''}`}
        onClick={() => isExchangeActive('mexc') && window.open(link, '_blank')}
    >
        <Svg id={mexcIcon} className={styles['trackerListItem__exchangeIcon']} />
        Mexc
    </div>
    <div 
        className={`${styles['trackerListItem__gridItem']} ${isExchangeActive('bitget') ? styles['trackerListItem__gridItem_active'] : ''}`}
        onClick={() => isExchangeActive('bitget') && window.open(link, '_blank')}
    >
        <Svg id={bitgetIcon} className={styles['trackerListItem__exchangeIcon']} />
        Bitget
    </div>
</div>

            </div>

            <div className={styles['trackerListItem__footer']}>
    <div className={styles['trackerListItem__footerBtns']}>
        {type === trackerTypes.DEX && (
            <MainBtn 
                className={styles['trackerListItem__snipeBtn']}
                onClick={() => {
                    window.open(snipeLink, '_blank', 'width=600,height=700');
                }}
            >
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1293_45601)">
                        <path d="M8 0.25C3.58524 0.25 0 3.83524 0 8.25C0 12.6648 3.58524 16.25 8 16.25C12.4148 16.25 16 12.6648 16 8.25C16 3.83524 12.4148 0.25 8 0.25ZM8 0.842593C12.0945 0.842593 15.4074 4.15548 15.4074 8.25C15.4074 12.3445 12.0945 15.6574 8 15.6574C3.90548 15.6574 0.592593 12.3445 0.592593 8.25C0.592593 4.15548 3.90548 0.842593 8 0.842593ZM8 1.43519C4.23982 1.43519 1.18519 4.48982 1.18519 8.25C1.18519 12.0102 4.23982 15.0648 8 15.0648C11.7602 15.0648 14.8148 12.0102 14.8148 8.25C14.8148 4.48982 11.7602 1.43519 8 1.43519ZM7.7037 2.03495V5.28704C7.70139 5.45053 7.832 5.58499 7.9955 5.58748C8.16255 5.59003 8.29867 5.45403 8.2963 5.28704V2.03495C11.5001 2.1847 14.0654 4.74991 14.2152 7.9537H10.963C10.5623 7.94801 10.5623 8.55198 10.963 8.5463H14.2152C14.0654 11.7501 11.5001 14.3153 8.2963 14.4651V11.213C8.29867 11.0459 8.16255 10.91 7.9955 10.9125C7.832 10.915 7.70139 11.0495 7.7037 11.213V14.4651C4.49991 14.3153 1.9347 11.7501 1.78495 8.5463H5.03704C5.43775 8.55198 5.43775 7.94801 5.03704 7.9537H1.78495C1.9347 4.74997 4.49991 2.18476 7.7037 2.03495Z" fill="#E7E7E8"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1293_45601">
                            <rect width="16" height="16" fill="white" transform="translate(0 0.25)"/>
                        </clipPath>
                    </defs>
                </svg>
                SNIPE
            </MainBtn>
        )}
    </div>
    <div className={styles['trackerListItem__footerBlock']}>
        <div>
            <p className={styles['trackerListItem__footerText']}>
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: '5px', verticalAlign: 'bottom'}}>
                    <rect x="2.33325" y="2" width="12" height="12" rx="6" fill="#5B5B7B" fillOpacity="0.16"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.33325 1.5C6.18488 1.5 4.57551 2.39727 3.51316 3.28256C3.25521 3.49751 3.02862 3.71242 2.83325 3.91478V2.66667C2.83325 2.39052 2.60939 2.16667 2.33325 2.16667C2.05711 2.16667 1.83325 2.39052 1.83325 2.66667V5.33333C1.83325 5.60948 2.05711 5.83333 2.33325 5.83333H4.66659C4.94273 5.83333 5.16659 5.60948 5.16659 5.33333C5.16659 5.05719 4.94273 4.83333 4.66659 4.83333H3.34496C3.55748 4.59466 3.82707 4.32267 4.15334 4.05078C5.091 3.2694 6.48163 2.5 8.33325 2.5C11.3894 2.5 13.8333 4.96137 13.8333 8C13.8333 11.0376 11.3708 13.5 8.33325 13.5C5.29569 13.5 2.83325 11.0376 2.83325 8C2.83325 7.72386 2.60939 7.5 2.33325 7.5C2.05711 7.5 1.83325 7.72386 1.83325 8C1.83325 11.5899 4.7434 14.5 8.33325 14.5C11.9231 14.5 14.8333 11.5899 14.8333 8C14.8333 4.41122 11.9438 1.5 8.33325 1.5ZM8.83325 5.33333C8.83325 5.05719 8.60939 4.83333 8.33325 4.83333C8.05711 4.83333 7.83325 5.05719 7.83325 5.33333V8C7.83325 8.16718 7.9168 8.32329 8.0559 8.41603L10.0559 9.74936C10.2857 9.90253 10.5961 9.84045 10.7493 9.61068C10.9025 9.38092 10.8404 9.07048 10.6106 8.91731L8.83325 7.73241V5.33333Z" fill="#5B5B7B"/>
                </svg>
                Spotted: <span style={{color: '#C1CAFC'}}>{setDateText(spotedText)}</span>
            </p>
        </div>
        <div>
            <a className={styles['trackerListItem__footerTextbtm']} target="_blank" rel="noreferrer" href={HoldersLink}>
                HOLDERS
            </a>
            <span className={styles['trackerListItem__dividersec']}>|</span>
            <a className={styles['trackerListItem__footerTextbtm']} target="_blank" rel="noreferrer" href={DexScreenerLink}>
                DEX SCREENER
            </a>
            <span className={styles['trackerListItem__dividersec']}>|</span>
            <a className={styles['trackerListItem__footerTextbtm']} target="_blank" rel="noreferrer" href={GoPlusLink}>
                GoPlus
            </a>
        </div>
    </div>
</div>
        </div>
    );
};

export default TrackerPageListItem;
