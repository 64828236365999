import {
    arbitrageIcon,
    ArbitrageIconActive,
    closedArbitrageIcon,
    closedArbitrageIconActive,
    documentIcon,
    futures,
    newsIcon,
    outflowIcon,
    settingsIcon,
    sniperIcon,
    subscriptionIcon,
    supportIcon,
    telegramIcon2,
    trackerLink,
    trackerLinkActive,
    twitterIcon,
} from '../../../../assets/svg';
import {
    arbitrageTypes,
    fundingTypes,
    newsTypes,
    telegramLink,
    trackerTypes,
    twitterLink,
} from '../../../../constants';
import {
    adminNewsPagePath,
    arbitragePagePath,
    cexOutflowPagePath,
    closedArbitragePagePath,
    dexTerminalPagePath,
    futuresPagePath,
    newsPagePath,
    settingsPagePath,
    subscribtionPagePath,
    supportPagePath,
    trackerPagePath,
} from '../../../../router/path';

export const navLinks = [
    {
        title: 'Arbitration News',
        icon: newsIcon,
        path: newsPagePath,
        adminPath: adminNewsPagePath,
        isPrivate: true,
        sublinks: [
            {
                title: 'Актуальные новости',
                href: newsTypes.actuals,
                isPrivate: true,
            },
            {
                title: 'Прошедшие новости',
                href: newsTypes.latest,
                isPrivate: true,
            },
            {
                title: 'News Free',
                href: newsTypes.free,
            },
        ],
    },
    {
        title: 'Arbitrage',
        icon: arbitrageIcon,
        activeIcon: ArbitrageIconActive,
        path: arbitragePagePath,
        isPrivate: true,
        sublinks: [
            {
                title: 'Cex-Cex',
                href: arbitrageTypes.cexToCex,
                isPrivate: true,
            },
            {
                title: 'Cex-Dex',
                href: arbitrageTypes.cexToDex,
                isPrivate: true,
            },
            {
                title: 'Dex-Dex',
                href: arbitrageTypes.dexToDex,
                isPrivate: true,
            },
            {
                title: 'Free',
                href: arbitrageTypes.free,
            },
        ],
    },
    {
        title: 'Closed Arbitrage',
        icon: closedArbitrageIcon,
        activeIcon: closedArbitrageIconActive,
        path: closedArbitragePagePath,
        isPrivate: true,
        sublinks: [
            {
                title: 'Cex-Cex',
                href: arbitrageTypes.cexToCex,
                isPrivate: true,
            },
            {
                title: 'Cex-Dex',
                href: arbitrageTypes.cexToDex,
                isPrivate: true,
            },
            {
                title: 'Free',
                href: arbitrageTypes.free,
            },
        ],
    },
    {
        title: 'Tracker',
        icon: trackerLink,
        activeIcon: trackerLinkActive,
        path: trackerPagePath,
        isPrivate: true,
        sublinks: [
            { title: 'Cex', href: trackerTypes.CEX, isPrivate: true },
            { title: 'Dex', href: trackerTypes.DEX, isPrivate: true },
        ],
    },
    {
        title: 'Futures',
        icon: futures,
        path: futuresPagePath,
        isPrivate: true,
        sublinks: [
            {
                title: 'Funding Arbitrage',
                href: fundingTypes.funding,
                isPrivate: true,
            },
            {
                title: 'Futures Arbitrage',
                href: fundingTypes.futures,
                isPrivate: true,
            },
        ],
    },
    {
        title: 'CEX Outflows',
        icon: outflowIcon,
        path: cexOutflowPagePath,
        isPrivate: true,
    },
    {
        title: 'Dex Terminal',
        icon: sniperIcon,
        path: dexTerminalPagePath,
        isPrivate: true,
    },
    // {
    //     title: 'Listings',
    //     icon: listingIcon,
    //     disabled: true,
    //     path: sniperWidgetPagePath,
    //     isPrivate: true,
    // },
    {
        title: 'Settings',
        icon: settingsIcon,
        path: settingsPagePath,
        isPrivate: true,
    },
    {
        title: 'Subscription',
        icon: subscriptionIcon,
        path: subscribtionPagePath,
    },
    {
        title: 'Docs',
        icon: documentIcon,
        path: subscribtionPagePath,
    },
    {
        title: 'Support',
        icon: supportIcon,
        path: supportPagePath,
    },
];

export const socials = [
    { link: twitterLink, iconId: twitterIcon },
    { link: telegramLink, iconId: telegramIcon2 },
];
