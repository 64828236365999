import React, { useCallback, useState } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';
import ChainPicker from '../shared/ChainPicker';
import ChainPositions from './ChainPositions';
import Header from './Header';
import styles from './styles.module.scss';
import WithdrawMenu from './WithdrawMenu';

const Portfolio = ({ closePortfolioHandler }) => {
    const [isWithdrawOpened, setIsWithdrawOpened] = useState();

    const ref = useOutsideClick(() => {
        if (!isWithdrawOpened) {
            closePortfolioHandler();
        }
    });

    const closeWithdrawMenu = useCallback(() => {
        setIsWithdrawOpened(false);
    }, []);

    const openWithdrawMenu = useCallback(() => {
        setIsWithdrawOpened(true);
    }, []);

    return (
        <div className={styles.wrapper}>
            {isWithdrawOpened && (
                <WithdrawMenu closeWithdrawMenu={closeWithdrawMenu} />
            )}
            <div ref={ref} className={styles.portfolioContainer}>
                <Header openWithdrawMenu={openWithdrawMenu} />
                <ChainPicker
                    containerClassName={styles.portfolioChainsList}
                    itemClassName={styles.chainBtn}
                />
                <ChainPositions />
            </div>
        </div>
    );
};

export default Portfolio;
