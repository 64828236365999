import React, { useEffect, useState } from 'react';
import styles from './Chart.module.scss';
import { chartChainsMap } from './config';

const Chart = ({ chain = '', tokenAddress = '' }) => {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkURL = async () => {
            try {
                const response = await fetch(
                    `https://www.gmgn.cc/kline/${chartChainsMap[chain]}/${tokenAddress}`,
                    {
                        mode: 'no-cors',
                    }
                );
                if (response.type !== 'opaque') {
                    throw Error(
                        'Could not load chart, please, check your data or try another token'
                    );
                }
                setError(false);
            } catch {
                setError(true);
            } finally {
                setIsLoading(false);
            }
        };

        checkURL();
    }, [chain, tokenAddress]);

    if (isLoading) {
        return (
            <div className={styles.chartContainer}>
                <h3 className={styles.title}>Loading...</h3>
            </div>
        );
    }

    return (
        <div className={styles.chartContainer}>
            <h3 className={styles.title}>Chart</h3>
            <div className={styles.chartWrapper}>
                {error ? (
                    <h4 className={styles.errorMsg}>
                        Не удалось загрузить график. Пожалуйста, проверьте
                        данные или повторите попытку позже.
                    </h4>
                ) : (
                    <iframe
                        className={styles.chartFrame}
                        title="sample"
                        src={`https://www.gmgn.cc/kline/${chartChainsMap[chain]}/${tokenAddress}`}
                    />
                )}
            </div>
        </div>
    );
};

export default Chart;
