import React, { useState } from 'react';
import { crossIcon } from '../../../../../assets/svg';
import MainBtn from '../../../../layout/MainBtn/MainBtn';
import MainInput from '../../../../layout/MainInput/MainInput';
import Svg from '../../../../layout/Svg/Svg';
import styles from './styles.module.scss';
import ethIcon from './eth.svg';
import solIcon from './sol.svg';
import bscIcon from './bsc.svg';





const BalanceModal = ({
    withdrawChain,
    withdrawToken,
    userId,
    onCloseModal,
}) => {
    const [withdrawAddress, setWithdrawAddress] = useState('');
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [selectedToken, setSelectedToken] = useState('');
    const [selectedNetwork, setSelectedNetwork] = useState('');
    const [isNetworkOpen, setIsNetworkOpen] = useState(false);
    const networks = ['ETHEREUM', 'SOLANA', 'BSC'];
    const networkIcons = {
        ETHEREUM: ethIcon,
        SOLANA: solIcon,
        BSC: bscIcon,
    };

    const TokenIcon = () => (
        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="10" height="15" rx="1.66667" fill="#5871F2" fillOpacity="0.08"/>
            <path d="M3.92932 3.75497C4.33755 3.0479 5.35812 3.0479 5.76635 3.75497L6.68486 5.34588C7.09309 6.05295 6.5828 6.93679 5.76635 6.93679H3.92932C3.11287 6.93679 2.60258 6.05295 3.01081 5.34588L3.92932 3.75497Z" fill="#5871F2"/>
            <path d="M3.92932 12.245C4.33755 12.9521 5.35812 12.9521 5.76635 12.245L6.68486 10.6541C7.09309 9.94705 6.5828 9.06321 5.76635 9.06321H3.92932C3.11287 9.06321 2.60258 9.94705 3.01081 10.6541L3.92932 12.245Z" fill="#5871F2"/>
        </svg>
    );

    return (
        <>
            <div className={styles.overlay} onClick={onCloseModal} />
            <div className={styles.modal}>
                <div className={styles.modal__header}>
                    <h2>Withdraw</h2>
                </div>
                <div className={styles.modal__gradientDivider} />
                
                <div className={styles.modal__divider} />
                
                <div className={styles.modal__content}>
                <div className={styles.modal__field}>
                    <label>TOKEN</label>
                    <div 
                        className={styles.modal__select}
                        onClick={() => setIsNetworkOpen(!isNetworkOpen)}
                    >
                        <span>{selectedNetwork || 'Select token'}</span>
                        <TokenIcon />
                        
                        {isNetworkOpen && (
                            <div className={styles.modal__selectDropdown}>
                                {networks.map((network) => (
                                    <button
                                        key={network}
                                        className={styles.modal__selectItem}
                                        onClick={() => {
                                            setSelectedNetwork(network);
                                            setIsNetworkOpen(false);
                                        }}
                                    >
                                        <div className={styles.modal__selectItemIcon}>
                                            <img src={networkIcons[network]} alt={network} />
                                        </div>
                                        <span>{network}</span>
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                    <div className={styles.modal__field}>
                        <label>ADDRESS WALLET</label>
                        <input 
                            type="text"
                            value={withdrawAddress}
                            onChange={(e) => setWithdrawAddress(e.target.value)}
                            placeholder="Enter wallet address"
                        />
                    </div>

                    <div className={styles.modal__field}>
                        <label>NETWORK</label>
                        <div className={styles.modal__select}>
                            <span>{selectedNetwork || 'Select network'}</span>
                            <TokenIcon />
                        </div>
                    </div>

                    <div className={styles.modal__field}>
                        <label>AMOUNT</label>
                        <input 
                            type="text"
                            value={withdrawAmount}
                            onChange={(e) => setWithdrawAddress(e.target.value)}
                            placeholder="0"
                        />
                    </div>
                </div>

                <div className={styles.modal__gradientDivider} />

                <div className={styles.modal__buttons}>
                    <button className={styles.modal__buttonCancel} onClick={onCloseModal}>
                        Cancel
                    </button>
                    <button className={styles.modal__buttonConfirm}>
                        Withdraw
                    </button>
                </div>
            </div>
        </>
    );
};

export default BalanceModal;

