import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwapIcon from '../../../assets/icons/sniper/swap.svg';
import { sniperLightDivider } from '../../../assets/svg';
import { chainsDefTokens, hintsData } from '../../../pages/DexTerminal/config';
import {
    changeSniperGasPrice,
    requestTrades,
} from '../../../redux/action/sniper';
import MainBtn from '../../layout/MainBtn/MainBtn';
import Svg from '../../layout/Svg/Svg';
import Hint from '../shared/Hint';
import OptionsMenu from '../shared/OptionsMenu';
import CustomWalletMenu from './CustomWalletMenu';
import InfoBlock from './InfoBlock';
import InputsBox from './InputsBox';
import styles from './styles.module.scss';
import TokenPicker from './TokenPicker';
import TransactionsJournal from './TransactionsJournal';
import WidgetHeader from './WidgetHeader';

const WidgetBlock = ({
    isChartOpened,
    isPosTableOpened,
    togglePanel,
    amountChangeHandler,
    tokenChangeHandler,
    side,
    sideChangeHandler,
    token,
    amountIn,
    amountOut,
    id,
    initialGasPrice,
    tvl,
    showNotify,
}) => {
    const [wallet, setWallet] = useState('');
    const [amount, setAmount] = useState(0);
    const [isCustomWallet, setIsCustomWallet] = useState(false);
    const [isHistoryOpened, setIsHistoryOpened] = useState(false);
    const [isTokenPickerOpened, setIsTokenPickerOpened] = useState(false);
    const [amountOnWallet, setAmountOnWallet] = useState(0);
    const [hintsView, setHintsView] = useState({
        isOptions: false,
        isWallet: false,
    });

    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);
    const trades = useSelector((state) => state.sniper.trades);
    const blockchain = useSelector((state) => state.sniper.blockchain);
    const slippage = useSelector((state) => state.sniper.slippage);
    const gasPrice = useSelector((state) => state.sniper.gasPrice);

    const defaultWallets = useMemo(() => {
        return {
            solana: user?.sol_contract,
            eth: user?.eth_contract,
            bsc: user?.bsc_contract,
        };
    }, [user]);

    useEffect(() => {
        if (user?.id && blockchain) {
            dispatch(requestTrades(user.id, blockchain));
            setWallet(defaultWallets[blockchain]);
        }
    }, [dispatch, user, blockchain, defaultWallets]);

    useEffect(() => {
        if (initialGasPrice) {
            dispatch(changeSniperGasPrice(initialGasPrice));
        }
        if (amountIn) {
            setAmount(amountIn);
        }
    }, [initialGasPrice, amountIn, dispatch]);

    useEffect(() => {
        if (!isNaN(amount)) {
            amountChangeHandler(amount);
        }
    }, [amount, amountChangeHandler]);

    useEffect(() => {
        if (trades) {
            const tokenOnWallet = trades.find(
                (trade) => trade.tokenOut_address === token?.address
            );

            if (tokenOnWallet) {
                setAmountOnWallet(
                    tokenOnWallet.amount_out /
                        Math.pow(10, tokenOnWallet.output_decimals)
                );
            }
        }
    }, [trades, token]);

    const onWalletChange = (e) => {
        setWallet(e.target.value);
    };

    const changeWallet = (wallet) => {
        setWallet(wallet);
    };

    const onTokenAmountChange = (e) => {
        setAmount(Number(e.target.value));
    };

    const changeTokenAmount = (amount) => {
        setAmount(amount);
    };

    const onToggleCustomWallet = () => {
        setIsCustomWallet((prev) => !prev);
    };

    const toggleHistory = useCallback(() => {
        setIsHistoryOpened((prev) => !prev);
    }, []);

    const openTokenPicker = useCallback(() => {
        setIsTokenPickerOpened(true);
    }, []);

    const closeTokenPicker = useCallback(() => {
        setIsTokenPickerOpened(false);
    }, []);

    const changeHintsView = useCallback((type) => {
        const actions = {
            closeOptions: (prev) => ({ ...prev, isOptions: false }),
            closeWallet: (prev) => ({ ...prev, isWallet: false }),
            closeAll: () => ({ isOptions: false, isWallet: false }),
            openAll: () => ({ isOptions: true, isWallet: true }),
        };

        setHintsView((prev) => actions[type]?.(prev) ?? prev);
    }, []);

    const closeWalletHint = useCallback(() => {
        changeHintsView('closeWallet');
    }, [changeHintsView]);

    const onSubmitTransaction = () => {
        console.log({
            event: 'swap',
            blockchain,
            id,
            token: token?.name,
            side,
            amount: amount || 0,
            slippage: slippage || 0,
            gas_price: gasPrice || 0,
            token_address: token?.address,
            wallet,
        });

        showNotify(
            'Transaction success',
            'success',
            'http://sample.com',
            5000,
            `Swap ${amountIn} ${side === 'buy' ? chainsDefTokens[blockchain] : token?.name} for ${amountOut} ${side === 'buy' ? token?.name : chainsDefTokens[blockchain]} was successful. 1${side === 'buy' ? chainsDefTokens[blockchain] : token?.name} = ${side === 'buy' ? amountOut / amountIn : amountIn / amountOut}${side === 'buy' ? token?.name : chainsDefTokens[blockchain]}`
        );
    };

    const { icon, title, info } = hintsData.wallet;

    return (
        <>
            {isTokenPickerOpened && (
                <TokenPicker
                    tokenChangeHandler={tokenChangeHandler}
                    closeTokenPicker={closeTokenPicker}
                />
            )}
            <section className={styles['widgetBlock']}>
                <div className={styles.blur} />
                <WidgetHeader
                    changeHintsView={changeHintsView}
                    togglePanel={togglePanel}
                    toggleHistory={toggleHistory}
                    isChartOpened={isChartOpened}
                    isPosTableOpened={isPosTableOpened}
                    isHistoryOpened={isHistoryOpened}
                    hintsView={hintsView}
                />
                <OptionsMenu
                    hintsView={hintsView}
                    changeHintsView={changeHintsView}
                    isWithHint={true}
                />
                <InputsBox
                    side={side}
                    amount={amount}
                    token={token}
                    amountOut={amountOut}
                    onTokenAmountChange={onTokenAmountChange}
                    changeTokenAmount={changeTokenAmount}
                    openTokenPicker={openTokenPicker}
                    sideChangeHandler={sideChangeHandler}
                    amountOnWallet={amountOnWallet}
                />

                <InfoBlock
                    side={side}
                    tokenName={token?.name || ''}
                    amountIn={amountIn}
                    amountOut={amountOut}
                    slippage={slippage}
                    tvl={tvl}
                />

                <div className={styles.customWalletPicker}>
                    <div
                        className={cn(
                            styles.hintHighlight,
                            hintsView.isWallet && styles.hintHighlight_visible
                        )}
                    />

                    <Hint
                        isOpened={hintsView.isWallet}
                        icon={icon}
                        title={title}
                        info={info}
                        onHintClose={closeWalletHint}
                        className={styles.walletHint}
                    />

                    <span>Add wallet for withdraw</span>
                    <button
                        className={cn(
                            styles.walletToggleBtn,
                            isCustomWallet && styles.walletToggleBtn_active
                        )}
                        onClick={onToggleCustomWallet}
                    />
                </div>

                {isCustomWallet && (
                    <CustomWalletMenu
                        wallet={wallet}
                        onWalletChange={onWalletChange}
                        changeWallet={changeWallet}
                        hintsView={hintsView}
                        changeHintsView={changeHintsView}
                    />
                )}

                <MainBtn
                    className={styles.confirmBtn}
                    onClick={onSubmitTransaction}
                    disabled={
                        !wallet || amount <= 0 || slippage <= 0 || gasPrice <= 0
                    }
                >
                    <img src={SwapIcon} alt="swap" />
                    <span>Swap</span>
                </MainBtn>
                {isHistoryOpened && (
                    <>
                        <Svg
                            className={styles.lightDivider}
                            id={sniperLightDivider}
                        />

                        <TransactionsJournal />
                    </>
                )}
            </section>
        </>
    );
};

export default WidgetBlock;
