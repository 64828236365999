import cn from 'classnames';
import React, { useState } from 'react';
import WalletIcon from '../../../assets/icons/sniper/wallet.svg';
import EditIcon from '../../../assets/icons/sniper/walletsMenu/edit.svg';
import RemoveIcon from '../../../assets/icons/sniper/walletsMenu/remove.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { formatWalletAddress } from '../../../utils/functions/format';
import ExtendedInput from '../../layout/ExtendedInput';
import styles from './WalletMenu.module.scss';

const WalletItem = ({ wallet, onEditWallet, onRemoveWallet }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(wallet.wallet_label);
    const [newAddress, setNewAddress] = useState(wallet.wallet_address);

    const handleSave = () => {
        onEditWallet(wallet.wallet_id, newName, newAddress);
        setIsEditing(false);
    };

    const editingRef = useOutsideClick(() => {
        setIsEditing(false);
    });

    return (
        <>
            {isEditing ? (
                <div className={styles.walletItem} ref={editingRef}>
                    <ExtendedInput
                        wrapperClassName={cn(
                            styles.walletInput,
                            styles.walletInput_name
                        )}
                        type="text"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        placeholder="New Name"
                    />
                    <ExtendedInput
                        wrapperClassName={cn(
                            styles.walletInput,
                            styles.walletInput_address
                        )}
                        iconWrapperClassName={styles.walletInputIconWrapper}
                        iconClassName={styles.walletInputIcon}
                        icon={WalletIcon}
                        type="text"
                        value={newAddress}
                        onChange={(e) => setNewAddress(e.target.value)}
                        placeholder="New Address"
                    />
                    <button
                        className={styles.walletSaveBtn}
                        onClick={handleSave}
                    >
                        Save
                    </button>
                </div>
            ) : (
                <div className={styles.walletItem}>
                    <p className={styles.walletName}>
                        {wallet.wallet_label.toLocaleUpperCase()}
                    </p>
                    <div className={styles.walletInfo}>
                        <div className={styles.walletAddress}>
                            <img src={WalletIcon} alt="wallet address" />
                            <p className={styles.walletAddressText}>
                                {formatWalletAddress(wallet.wallet_address)}
                            </p>
                        </div>
                        <div className={styles.walletBtns}>
                            <button
                                className={cn(
                                    styles.walletBtn,
                                    styles.walletBtn_edit
                                )}
                                onClick={() => setIsEditing(true)}
                            >
                                <img src={EditIcon} alt="edit wallet" />
                            </button>
                            <button
                                className={cn(
                                    styles.walletBtn,
                                    styles.walletBtn_remove
                                )}
                                onClick={() => {
                                    onRemoveWallet(wallet.wallet_id);
                                }}
                            >
                                <img src={RemoveIcon} alt="remove wallet" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default WalletItem;
