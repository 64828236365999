import cn from 'classnames';
import React, { forwardRef } from 'react';
import styles from './styles.module.scss';

const NumericInput = forwardRef(
    (
        {
            className,
            value,
            placeholder,
            onChange,
            onFocus,
            onBlur,
            fontSizes = { normal: '14px', small: '12px', extraSmall: '10px' },
            sizeBreaks = { breakSmall: 5, breakExtraSmall: 10 },
            isPositive = false,
        },
        ref
    ) => {
        const { normal, small, extraSmall } = fontSizes;
        const { breakSmall, breakExtraSmall } = sizeBreaks;

        const styleVars = {
            '--font-size':
                value.toString().length < breakSmall
                    ? normal
                    : value.toString().length < breakExtraSmall
                      ? small
                      : extraSmall,
        };

        return (
            <input
                style={styleVars}
                ref={ref}
                className={cn(styles.numInput, className)}
                type="text"
                value={
                    value === 0 ? '' : value.toString().replace(/^0+(\d)/, '$1')
                }
                placeholder={placeholder}
                onChange={(e) => {
                    let inputValue = e.target.value;

                    if (isPositive && inputValue.startsWith('-')) {
                        return;
                    }

                    if (inputValue === '-0.') {
                        onChange('-0.');
                        return;
                    }
                    if (inputValue === '0.') {
                        onChange('0.');
                        return;
                    }

                    if (inputValue === '0' && value === 0) {
                        onChange('0.0');
                        return;
                    }

                    if (Number(inputValue) >= 1000000000000000) return;

                    if (
                        inputValue === '' ||
                        /^[-]?\d*\.?\d*$/.test(inputValue)
                    ) {
                        onChange(inputValue);
                    }
                }}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        );
    }
);

export default NumericInput;
