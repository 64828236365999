import cn from 'classnames';
import React from 'react';
import { chainsIconsMap } from '../../../pages/DexTerminal/config';
import PosWidget from './PosWidget';
import styles from './styles.module.scss';

const PosItem = ({ pos, showNotify }) => {
    const {
        img,
        name,
        investedNative,
        investedUsdt,
        blockchain,
        initialPos,
        initialMC,
        curPos,
        curMC,
        soldUsdt,
        soldNative,
        remainingUsdt,
        remainingNative,
        changeInPnLUsdt,
        changeInPnLPercent,
        address,
        priceNative,
        amount,
    } = pos;

    return (
        <div className={styles.posTableItem}>
            <div className={styles.posItemFace}>
                <div className={styles.posImgContainer}>
                    <img src={img} alt={name} />
                </div>
                <span className={styles.posItemName}>{name}</span>
            </div>
            <div className={styles.posItemInfo}>
                <span
                    className={cn(
                        styles.posItemTitle,
                        styles.posItemTitle_green
                    )}
                >
                    ${investedUsdt}
                </span>
                <div className={styles.posItemNativePrice}>
                    <span className={styles.posItemSubtitle}>
                        {investedNative}
                    </span>
                    <img src={chainsIconsMap[blockchain]} alt="" />
                </div>
            </div>
            <div className={styles.posItemInfo}>
                <span className={styles.posItemTitle}>{initialPos}</span>
                <span className={styles.posItemSubtitle}>MC {initialMC}</span>
            </div>
            <div className={styles.posItemInfo}>
                <span className={styles.posItemTitle}>{curPos}</span>
                <span className={styles.posItemSubtitle}>MC {curMC}</span>
            </div>
            <div className={styles.posItemInfo}>
                <span
                    className={cn(styles.posItemTitle, styles.posItemTitle_red)}
                >
                    ${soldUsdt}
                </span>
                <div>
                    <span className={styles.posItemSubtitle}>{soldNative}</span>

                    <img src={chainsIconsMap[blockchain]} alt="" />
                </div>
            </div>
            <div className={styles.posItemInfo}>
                <span
                    className={cn(styles.posItemTitle, styles.posItemTitle_red)}
                >
                    ${remainingUsdt}
                </span>
                <div>
                    <span className={styles.posItemSubtitle}>
                        {' '}
                        {remainingNative}
                    </span>

                    <img src={chainsIconsMap[blockchain]} alt="" />
                </div>
            </div>
            <div className={cn(styles.posItemInfo, styles.posItemInfo_last)}>
                <span
                    className={cn(styles.posItemTitle, styles.posItemTitle_red)}
                >
                    ${changeInPnLUsdt}
                </span>
                <span className={styles.posItemSubtitle}>
                    {changeInPnLPercent}%
                </span>
            </div>
            <PosWidget
                address={address}
                priceNative={priceNative}
                amount={amount}
                showNotify={showNotify}
                name={name}
            />
        </div>
    );
};

export default PosItem;
