import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import ChainIcon from '../../../../assets/icons/sniper/walletsMenu/chain.svg';
import Divider from '../../../../assets/icons/sniper/walletsMenu/divider.png';
import SelectIcon from '../../../../assets/icons/sniper/walletsMenu/select.svg';
import { sniperBlockchains } from '../../../../constants';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import {
    chainsIconsMap,
    chainsNamesMap,
} from '../../../../pages/DexTerminal/config';
import ExtendedInput from '../../../layout/ExtendedInput';
import styles from './styles.module.scss';

const WithdrawMenu = ({ closeWithdrawMenu }) => {
    const [view, setView] = useState({ isToken: false, isChain: false });
    const [blockchain, setBlockchain] = useState('');
    const [menuHeight, setMenuHeight] = useState(0);

    const closeRef = useOutsideClick(() => {
        closeWithdrawMenu();
    });

    useEffect(() => {
        if (!closeRef.current) return;

        const updateHeight = () => {
            setMenuHeight((closeRef.current?.offsetHeight || 0) + 2);
        };

        const observer = new ResizeObserver(updateHeight);
        observer.observe(closeRef.current);

        updateHeight();

        return () => observer.disconnect();
    }, []);

    return (
        <>
            <div className={styles.border} style={{ height: menuHeight }} />
            <div ref={closeRef} className={styles.withdrawMenuContainer}>
                <h4 className={styles.menuTitle}>Withdraw</h4>
                <div className={styles.lightDivider}>
                    <img src={Divider} alt="divider" />
                </div>
                <div
                    className={cn(
                        styles.withdrawMenuInput,
                        styles.withdrawMenuInput_select
                    )}
                    onClick={() => {
                        setView((prev) => ({
                            isChain: !prev.isChain,
                            isToken: false,
                        }));
                    }}
                >
                    {view.isChain && (
                        <div className={styles.withdrawMenuSelect}>
                            {sniperBlockchains.map((chain) => (
                                <button
                                    className={styles.withdrawMenuSelectItem}
                                    onClick={() => setBlockchain(chain)}
                                    key={chain}
                                >
                                    <div
                                        className={
                                            styles.selectItemImgContainer
                                        }
                                    >
                                        <img
                                            src={chainsIconsMap[chain]}
                                            alt={chain}
                                        />
                                    </div>
                                    <span className={styles.selectItemName}>
                                        {chainsNamesMap[chain]}
                                    </span>
                                </button>
                            ))}
                        </div>
                    )}
                    <h5 className={styles.withdrawMenuInputHeader}>NETWORK</h5>
                    <ExtendedInput
                        wrapperClassName={styles.withdrawMenuInput}
                        className={styles.disabledInput}
                        placeholder={'Select Network'}
                        value={chainsNamesMap[blockchain]}
                        readOnly
                        icon={
                            blockchain !== ''
                                ? chainsIconsMap[blockchain]
                                : ChainIcon
                        }
                        onFocus={(e) => e.preventDefault()}
                    />
                    <img
                        className={styles.selectIcon}
                        src={SelectIcon}
                        alt="select"
                    />
                </div>
                <div>
                    <h5>Token</h5>
                </div>
                <div>
                    <h5>Address wallet</h5>
                </div>
                <div>
                    <div>
                        <h5>Amount</h5>
                    </div>
                </div>
                <div className={styles.lightDivider}>
                    <img src={Divider} alt="divider" />
                </div>
                <div className={styles.withdrawBtnsContainer}>
                    <button
                        className={cn(
                            styles.withdrawBtn,
                            styles.withdrawBtn_cancel
                        )}
                    >
                        Cancel
                    </button>
                    <button
                        className={cn(
                            styles.withdrawBtn,
                            styles.withdrawBtn_withdraw
                        )}
                    >
                        Withdraw
                    </button>
                </div>
            </div>
        </>
    );
};

export default WithdrawMenu;
