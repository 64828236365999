import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { hintsData } from '../../../../pages/DexTerminal/config';
import ChainPicker from '../../shared/ChainPicker';
import Hint from '../Hint';
import ExtendedSettings from './ExtendedSettings';
import OptionsBlock from './OptionsBlock';
import styles from './styles.module.scss';

const OptionsMenu = ({
    containerClassName,
    isWithHint,
    hintsView,
    changeHintsView,
    chainBtnsClassName,
}) => {
    const blockchain = useSelector((state) => state.sniper.blockchain);

    const [isSettingsOpened, setIsSettingsOpened] = useState(false);

    const toggleIsSettingsOpened = useCallback(() => {
        setIsSettingsOpened((prev) => !prev);
    }, []);

    const closeSettings = useCallback(() => setIsSettingsOpened(false), []);

    const closeOptionsHint = useCallback(() => {
        changeHintsView('closeOptions');
    }, [changeHintsView]);

    const { icon, gasTitle, jitoTitle, info } = hintsData.options;

    const hintCondition = isWithHint && hintsView?.isOptions;

    return (
        <div className={cn(styles.settingsMenu, containerClassName)}>
            <div
                className={cn(
                    styles.hintHighlight,
                    hintCondition && styles.hintHighlight_visible
                )}
            />

            <Hint
                icon={icon}
                title={blockchain === 'solana' ? jitoTitle : gasTitle}
                info={info}
                onHintClose={closeOptionsHint}
                isOpened={hintCondition}
            />

            <OptionsBlock
                toggleIsSettingsOpened={toggleIsSettingsOpened}
                isSettingsOpened={isSettingsOpened}
            />
            <ChainPicker itemClassName={chainBtnsClassName} />

            <ExtendedSettings
                isOpened={isSettingsOpened}
                closeSettings={closeSettings}
            />
        </div>
    );
};

export default OptionsMenu;
