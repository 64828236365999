import { getLSItem } from '../../utils/functions/localStorage';
import { lsProps } from '../../utils/lsProps';

export const baseUrl = `https://${process.env.REACT_APP_BASE_URL}api`;
export const sniperBaseUrl = `${process.env.REACT_APP_WIDGET_LINK}api`;

export const baseConfig = {
    headers: {
        'Content-Type': 'application/json',
    },
};

export const authConfig = () => {
    const token = getLSItem(lsProps.token, true);
    return {
        headers: {
            Authorization: token ? `Bearer ${token}` : null,
        },
    };
};

export const setEmptyFieldsError = (formData) => {
    let emptyField = Object.keys(formData).find((item) => !formData[item]);

    if (emptyField) {
        throw {
            message: `${emptyField} обязательно к заполнению`,
            status: 400,
        };
    }
};

// auth
export const signupUrl = '/signup';
export const siginUrl = '/signin';
export const getUserUrl = '/user';
export const forgotPasswordUrl = '/forgotPassword';
export const resetPasswordUrl = '/resetPassword';
export const checkIsSubscribedUrl = '/isSubscribed';
export const changePassUrl = '/changePass';
export const editUserDataUrl = '/changeUser';

// news
export const getNewsUrl = '/news';
export const createNewsUrl = '/createNews';
export const editNewsUrl = '/editNews';
export const deleteNewsUrl = '/deleteNews';

// arbitrage
export const getArbitrageUrl = '/arb';
export const getClosedArbitrageUrl = '/closed-arb';

// cexOutflows
export const getCEXOutflowsUrl = '/cexOutflows';

// trackers
export const getCEXTrackerUrl = '/cexTracker';
export const getDEXTrackerUrl = '/dexTracker';

// funding
export const getFundingUrl = '/fundingArb';
export const getFuturesUrl = '/futuresArb';

// filters
export const createFilterUrl = '/createFilter';

//subsctiprion
export const createPaymentLink = '/createPaymentLink';

//notification
export const changeNotificationsUrl = '/addNotification';

// report
export const reportUrl = '/report';

//settings
export const checkPayUrl = '/checkPayment';

//sniper
export const getTokensUrl = '/tokens';
export const getTradesUrl = '/trades';
export const getPositionsUrl = '/positions';
export const addWallet = '/addWallet';
export const editWallet = '/editWallet';
export const deleteWallet = '/deleteWallet';

export const fetchRequest = async (
    fetchUrl,
    method = 'GET',
    body = null,
    config = authConfig(),
    isSniper
) => {
    const options = {
        method: method,
        ...config,
    };
    if (method !== 'GET' && body) {
        options.body = JSON.stringify(body);
    }

    console.log(options);
    const response = await fetch(
        `${isSniper ? sniperBaseUrl : baseUrl}${fetchUrl}`,
        options
    );

    console.log(options, response);

    if (!response.ok) {
        const error = new Error(
            `Произошла ошибка (Статус: ${response.status})`
        );
        error.status = response.status;
        throw error;
    }

    let resData;
    try {
        resData = await response.json();
    } catch (error) {
        throw new Error('Ошибка загрузки данных с сервера');
    }

    return resData;
};

export const setError = (err, type) => (dispatch) => {
    const errors = [
        {
            text: 'Empty email',
            translate: 'Эл. адрес не существует',
        },
        {
            text: 'Email already exists',
            translate: 'Эл. адрес уже существует',
        },
        {
            text: 'Wrong password',
            translate: 'Неверный пароль',
        },
        {
            text: 'No user',
            translate: 'Эл. адрес не существует',
        },
    ];

    const { message } = err;

    let payload = '';
    if (message && message.includes('Unexpected token')) {
        const curError = errors.find((item) => message.includes(item.text));

        if (curError) {
            payload = curError.translate;
        } else {
            payload = message.slice(
                message.indexOf(`, "`) + 3,
                message.indexOf(`" is not valid JSON`)
            );
        }
    } else {
        payload = message !== undefined ? message : err;
    }
    dispatch({ type, payload });
};

export const sendFirebaseTokenToServer = async (url, token) => {
    try {
        const data = await fetchRequest(url, 'POST', { token });
        console.log('Server response:', data);
    } catch (error) {
        console.error('Error sending token to server:', error);
    }
};

const fetchDexData = async (chain, address) => {
    const dexScreenerUrl = process.env.REACT_APP_DEX_SCREENER_REQ_URL;
    const url = `${dexScreenerUrl}${chain}/${address}`;
    const response = await fetch(url);
    const data = await response.json();

    return data;
};

export const fetchSolTokens = async (solContract) => {
    const solRpcUrl = process.env.REACT_APP_SOLANA_RPC_URL;
    const solPubKey = process.env.REACT_APP_SOLANA_PUB_KEY;

    const response = await fetch(solRpcUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            jsonrpc: '2.0',
            id: 1,
            method: 'getTokenAccountsByOwner',
            params: [
                solContract,
                {
                    programId: solPubKey,
                },
                {
                    encoding: 'jsonParsed',
                },
            ],
        }),
    });
    const tokensData = await response.json();

    const tokenAccounts = await tokensData.result.value.map(async (token) => {
        const address = token.account.data.parsed.info.mint;

        const amount =
            (token.account.data.parsed.info.tokenAmount.amount ?? 0) /
            Math.pow(
                10,
                token.account.data.parsed.info.tokenAmount.decimals ?? 1
            );

        const dexData = await fetchDexData('solana', address);

        if (dexData.length === 0) return null;

        const actualPair =
            dexData.length === 1
                ? dexData[0]
                : dexData.reduce((max, current) => {
                      return current.marketCap > max.marketCap ? current : max;
                  });

        const img = actualPair.info?.imageUrl || '';
        const name = actualPair.baseToken.name;
        const priceNative = actualPair.priceNative;
        const priceUsdt = actualPair.priceUsd;
        const blockchain = 'sol';
        const investedUsdt = 0;
        const investedPriceUsdt = 0;
        const investedNative = 0;
        const initialPos = 0;
        const initialMC = 0;
        const curPos = 0;
        const curMC = actualPair.marketCap;
        const soldUsdt = 0;
        const soldNative = 0;
        const remainingUsdt = amount * priceUsdt;
        const remainingNative = amount * priceNative;
        const changeInPnLPercent =
            ((priceUsdt - investedPriceUsdt) / priceUsdt) * 100;
        const changeInPnLUsdt = amount * changeInPnLPercent;

        return {
            img,
            name,
            address,
            amount,
            blockchain,
            investedUsdt,
            investedNative,
            initialPos,
            initialMC,
            curPos,
            curMC,
            soldUsdt,
            soldNative,
            remainingUsdt,
            remainingNative,
            changeInPnLUsdt,
            changeInPnLPercent,
        };
    });

    const resultData = await Promise.all(tokenAccounts);

    return resultData;
};

export const fetchEthTokens = async (ethAddress) => {
    // const ethScanApiKey = process.env.REACT_APP_ETH_SCAN_API_KEY;
    // const ethScanUrl = `https://api.etherscan.io/api?module=account&action=tokentx&address=${ethAddress}&apikey=${ethScanApiKey}`;
    // const response = await fetch(ethScanUrl);
    // const tokensData = await response.json();
    // const tokenAccounts = await Promise.all(tokensData.result.map(async (token) => {
    //     const address = token.contractAddress;
    //     const amount = token.value / Math.pow(10, token.tokenDecimal);
    //     const dexData = await fetchDexData('ethereum', address);
    //     if (!dexData || dexData.length === 0) return null;
    //     const actualPair = dexData.length === 1
    //         ? dexData[0]
    //         : dexData.reduce((max, current) => current.marketCap > max.marketCap ? current : max);
    //     const priceUsdt = actualPair.priceUsd;
    //     const priceNative = actualPair.priceNative;
    //     const img = actualPair.baseToken.imageUrl || ''
    //     const name = actualPair.baseToken.name
    //     return {
    //         img,
    //         name: ,
    //         address,
    //         amount,
    //         blockchain: 'eth',
    //         investedUsdt: 0,
    //         investedNative: 0,
    //         initialPos: 0,
    //         initialMC: 0,
    //         curPos: 0,
    //         curMC: actualPair.marketCap,
    //         soldUsdt: 0,
    //         soldNative: 0,
    //         remainingUsdt: amount * priceUsdt,
    //         remainingNative: amount * priceNative,
    //         changeInPnLUsdt: amount * ((priceUsdt - 0) / priceUsdt) * 100,
    //         changeInPnLPercent: ((priceUsdt - 0) / priceUsdt) * 100
    //     };
    // }));
    // return tokenAccounts.filter(token => token !== null);
};

export const fetchBscTokens = async (bscAddress) => {
    // const bscScanApiKey = process.env.REACT_APP_BSC_SCAN_API_KEY;
    // const bscScanUrl = `https://api.bscscan.com/api?module=account&action=tokentx&address=${bscAddress}&apikey=${bscScanApiKey}`;
    // const response = await fetch(bscScanUrl);
    // const tokensData = await response.json();
    // const tokenAccounts = await Promise.all(tokensData.result.map(async (token) => {
    //     const address = token.contractAddress;
    //     const amount = token.value / Math.pow(10, token.tokenDecimal);
    //     const dexData = await fetchDexData('bsc', address);
    //     if (!dexData || dexData.length === 0) return null;
    //     const actualPair = dexData.length === 1
    //         ? dexData[0]
    //         : dexData.reduce((max, current) => current.marketCap > max.marketCap ? current : max);
    //     const priceUsdt = actualPair.priceUsd;
    //     const priceNative = actualPair.priceNative;
    //     return {
    //         img: actualPair.baseToken.imageUrl || '',
    //         name: actualPair.baseToken.name,
    //         address,
    //         amount,
    //         blockchain: 'bsc',
    //         investedUsdt: 0,
    //         investedNative: 0,
    //         initialPos: 0,
    //         initialMC: 0,
    //         curPos: 0,
    //         curMC: actualPair.marketCap,
    //         soldUsdt: 0,
    //         soldNative: 0,
    //         remainingUsdt: amount * priceUsdt,
    //         remainingNative: amount * priceNative,
    //         changeInPnLUsdt: amount * ((priceUsdt - 0) / priceUsdt) * 100,
    //         changeInPnLPercent: ((priceUsdt - 0) / priceUsdt) * 100
    //     };
    // }));
    // return tokenAccounts.filter(token => token !== null);
};
