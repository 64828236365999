import React, { memo } from 'react';
import styles from './MainBtn.module.scss';

const MainBtn = memo(({ className, isPassive, children, icon, ...properties }) => {
    return (
        <button
            className={`${styles['mainBtn']} ${isPassive ? styles['mainBtn_passive'] : ''} ${className ? className : ''}`}
            {...properties}
        >
            {icon && <span className={styles['mainBtn__icon']}>{icon}</span>}
            {children}
        </button>
    );
});

export default MainBtn;
