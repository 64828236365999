import React from 'react';
import { useSelector } from 'react-redux';
import ColumnTitle from './ColumnTitle';
import PosItem from './PosItem';
import styles from './styles.module.scss';

const PosList = ({ showNotify }) => {
    const positions = useSelector((state) => state.sniper.positions);
    const blockchain = useSelector((state) => state.sniper.blockchain);

    const filteredPos = positions.filter(
        (pos) => pos.blockchain === blockchain
    );

    const posTableColumns = [
        {
            title: `TOKEN (${filteredPos.length})`,
            hint: 'show coins that you bought.',
            subTitle: 'Token ',
        },
        {
            title: `INVESTED`,
            subTitle: 'Invested ',
            hint: "shows what you own, its cost, and how much it's worth at any moment. It helps you track your exposure to the market and make informed decisions about holding, buying more, or selling.",
        },
        {
            title: `INITIAL POSITION`,
            hint: 'refers to the starting state of your investment or trade when you execute a swap',
            subTitle: 'Initial position ',
        },
        {
            title: `WORTH`,
            hint: 'refers to the total value of the assets you receive after executing a cryptocurrency swap. This metric helps you understand the economic impact of the swap, including factors like the exchange rate, fees, and slippage, so you can assess whether the trade was worthwhile.',
            subTitle: 'Worth ',
        },
        {
            title: `SOLD`,
            hint: 'refers to the total value or quantity of the cryptocurrency you are giving up (or selling) in a crypto swap transaction. It helps you track how much of your initial asset you’ve "sold" to acquire another cryptocurrency and is essential for analyzing trade performance and portfolio adjustments.',
            subTitle: 'Sold ',
        },
        {
            title: 'REMAINING',
            hint: 'refers to the amount of the input cryptocurrency that is left over or not used after a swap transaction. This metric is important when you perform partial swaps or account for transaction fees, ensuring you know how much of your original asset is still available.',
            subTitle: 'Remaining ',
        },
        {
            title: 'CHANGE IN P&L',
            hint: 'measures the difference in the value of your portfolio before and after a cryptocurrency swap. This metric helps evaluate whether the swap resulted in a gain or a loss, reflecting its impact on your overall financial position.',
            subTitle: 'Change in P&L ',
        },
    ];

    return (
        <div className={styles.posTable}>
            <div className={styles.posTableHeader}>
                {posTableColumns.map((column) => (
                    <ColumnTitle key={column.title} data={column} />
                ))}
            </div>
            <div className={styles.posTableList}>
                {filteredPos.map((pos, index) => (
                    <React.Fragment key={pos.address}>
                        {index !== 0 && (
                            <div className={styles.posListDivider} />
                        )}
                        <PosItem pos={pos} showNotify={showNotify} />
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default PosList;
