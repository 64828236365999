import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import defaultTokenIcon from '../../../../../assets/icons/sniper/default.svg';
import InputHighlightIcon from '../../../../../assets/icons/sniper/input-highlight.png';
import SelectIcon from '../../../../../assets/icons/sniper/select-white.svg';
import USDTIcon from '../../../../../assets/icons/sniper/usdt.svg';
import WalletIcon from '../../../../../assets/icons/sniper/wallet-purple.svg';
import styles from './styles.module.scss';

const TokenBlock = ({
    type,
    side,
    amount,
    onAmountChange,
    isActive,
    token,
    openTokenPicker,
    blockchain,
    amountOnWallet,
    changeTokenAmount,
}) => {
    const [imgSrc, setImgSrc] = useState(defaultTokenIcon);
    const [usdtValue, setUsdtValue] = useState(0);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const user = useSelector((state) => state.auth.user);

    const userBalances = {
        solana: user?.sol_balance,
        eth: user?.eth_balance,
        bsc: user?.bsc_balance,
    };

    const defCoinsUsdtPricesNames = {
        solana: 'solana',
        eth: 'ethereum',
        bsc: 'binancecoin',
    };

    useEffect(() => {
        const fetchTokenData = async () => {
            try {
                if (type === side) {
                    if (token?.imageUrl && token?.priceUsdt) {
                        setImgSrc(token.imageUrl);
                        setUsdtValue(token?.priceUsdt);
                    }
                } else {
                    const res = await fetch(
                        process.env.REACT_APP_PRICE_CHECK_LINK
                    );
                    if (!res.ok)
                        throw new Error(
                            `Error: ${res.status} ${res.statusText}`
                        );

                    const data = await res.json();
                    setUsdtValue(data[defCoinsUsdtPricesNames[blockchain]].usd);
                }
            } catch (error) {
                console.error('Failed to fetch token data:', error);
            }
        };
        fetchTokenData();
    }, [token]);

    const renderAmountDisplay = () =>
        isActive && onAmountChange ? (
            <>
                <img
                    className={cn(
                        styles.inputHighlight,
                        isInputFocused && styles.inputHighlight_visible
                    )}
                    src={InputHighlightIcon}
                    alt="highlight"
                />
                <input
                    onFocus={() => {
                        setIsInputFocused(true);
                    }}
                    onBlur={() => {
                        setIsInputFocused(false);
                    }}
                    placeholder="0"
                    type="number"
                    onChange={onAmountChange}
                    className={styles.amount}
                    value={
                        amount === 0
                            ? amount.toString().replace(/^0+/, '')
                            : amount
                    }
                />
            </>
        ) : (
            <p className={cn(styles.amount, styles.amount_inactive)}>
                {amount}
            </p>
        );

    const handleTokenAmountChange = (multiplier) => {
        const baseAmount =
            type === side ? amountOnWallet : userBalances[blockchain];
        changeTokenAmount(baseAmount * multiplier);
    };

    const renderTokenPickerButton = () => (
        <button
            onClick={openTokenPicker}
            className={cn(styles.tokenWrapper, styles.tokenWrapper_active)}
        >
            <img className={styles.tokenIcon} src={imgSrc} alt={token?.name} />
            <span className={styles.tokenName}>
                {token?.name || 'Select coin'}
            </span>
            <img src={SelectIcon} alt="select" />
        </button>
    );

    const renderDefaultTokenDisplay = () => (
        <div className={styles.tokenWrapper}>
            <img className={styles.tokenIcon} src={USDTIcon} alt="token" />
            <span className={styles.tokenName}>USDT</span>
        </div>
    );

    return (
        <div
            className={cn(
                styles.section,
                isActive && styles.section_active,
                isActive && isInputFocused && styles.section_focused
            )}
        >
            <div className={styles.price}>
                <h2 className={styles.title}>
                    {`YOU’RE ${type === 'sell' ? 'SELLING' : 'BUYING'}`}
                </h2>
                {renderAmountDisplay()}
            </div>

            <div className={styles.inputOptions}>
                <div className={styles.inputButtons}>
                    <div className={styles.inputWalletCount}>
                        <div className={styles.inputWalletImgContainer}>
                            <img src={WalletIcon} alt="wallet" />
                        </div>
                        <span className={styles.inputWalletCountText}>
                            {type === side
                                ? `${amountOnWallet} ${token?.name ?? ''}`
                                : `${0} USDT`}
                        </span>
                    </div>

                    {type === 'sell' && (
                        <div className={styles.btnsContainer}>
                            <button
                                onClick={() => handleTokenAmountChange(0.5)}
                                className={styles.walletBtn}
                            >
                                HALF
                            </button>
                            <button
                                onClick={() => handleTokenAmountChange(1)}
                                className={styles.walletBtn}
                            >
                                MAX
                            </button>
                        </div>
                    )}
                </div>

                <div className={styles.tokenDataBox}>
                    {type === side && (
                        <span className={styles.tokenUsdPrice}>
                            ≈ {(usdtValue * amount).toFixed(2)} USD
                        </span>
                    )}

                    {type === side
                        ? renderTokenPickerButton()
                        : renderDefaultTokenDisplay()}
                </div>
            </div>
        </div>
    );
};

export default TokenBlock;
