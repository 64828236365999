import React, { Fragment } from 'react';
import Divider from '../../../assets/icons/sniper/walletsMenu/divider.png';
import WalletItem from './WalletItem';
import styles from './WalletMenu.module.scss';

const WalletsList = ({ wallets, onEditWallet, onRemoveWallet }) => {
    return (
        <div className={styles.walletsList}>
            {wallets.map((wallet, index) => (
                <Fragment key={index}>
                    <WalletItem
                        wallet={wallet}
                        onEditWallet={onEditWallet}
                        onRemoveWallet={onRemoveWallet}
                    />
                    {index !== wallets.length - 1 && (
                        <div className={styles.dividerContainer}>
                            <img
                                alt="divider"
                                className={styles.divider}
                                src={Divider}
                            />
                        </div>
                    )}
                </Fragment>
            ))}
        </div>
    );
};

export default WalletsList;
