import cn from 'classnames';
import React, { useState } from 'react';
import WalletIcon from '../../../assets/icons/sniper/wallet.svg';
import ChainIcon from '../../../assets/icons/sniper/walletsMenu/chain.svg';
import TagIcon from '../../../assets/icons/sniper/walletsMenu/name.svg';
import SelectIcon from '../../../assets/icons/sniper/walletsMenu/select.svg';
import { sniperBlockchains } from '../../../constants';
import {
    chainsIconsMap,
    chainsNamesMap,
} from '../../../pages/DexTerminal/config';
import ExtendedInput from '../../layout/ExtendedInput';
import styles from './WalletMenu.module.scss';

const AddWalletForm = ({ onAddWallet }) => {
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [chain, setChain] = useState('');
    const [view, setView] = useState({
        isNameActive: false,
        isAddressActive: false,
        isChainActive: false,
    });

    const onChangeName = (e) => {
        setName(e.target.value);
    };

    const onChangeAddress = (e) => {
        setAddress(e.target.value);
    };

    const onChangeChain = (chain) => {
        setChain(chain);
    };

    return (
        <div className={styles.addWalletForm}>
            <div className={styles.addWalletInputsContainer}>
                <div className={styles.addWalletFormRow}>
                    <div className={styles.addWalletInputItem}>
                        <h5 className={styles.addWalletInputHeader}>
                            NEW WALLET NAME
                        </h5>
                        <ExtendedInput
                            className={styles.disabledInput}
                            wrapperClassName={cn(
                                styles.addWalletInput,
                                view.isNameActive &&
                                    styles.addWalletInput_active
                            )}
                            customFocus={view.isNameActive}
                            placeholder={'Name of your wallet'}
                            value={name}
                            onChange={onChangeName}
                            icon={TagIcon}
                            onFocus={() => {
                                setView({
                                    isNameActive: true,
                                    isAddressActive: false,
                                    isChainActive: false,
                                });
                            }}
                            onBlur={(prev) => {
                                setView({
                                    isNameActive: false,
                                    isAddressActive: prev.isAddressActive,
                                    isChainActive: prev.isChainActive,
                                });
                            }}
                        />
                    </div>
                    <div
                        className={cn(
                            styles.addWalletInputItem,
                            styles.addWalletInputItem_select
                        )}
                        onClick={() => {
                            setView((prev) => {
                                return {
                                    isChainActive: !prev.isChainActive,
                                    isAddressActive: false,
                                    isNameActive: false,
                                };
                            });
                        }}
                    >
                        {view.isChainActive && (
                            <div className={styles.addWalletSelect}>
                                {sniperBlockchains.map((chain) => (
                                    <button
                                        className={styles.addWalletSelectItem}
                                        onClick={() => {
                                            onChangeChain(chain);
                                        }}
                                        key={chain}
                                    >
                                        <div
                                            className={
                                                styles.selectItemImgContainer
                                            }
                                        >
                                            <img
                                                src={chainsIconsMap[chain]}
                                                alt={chain}
                                            />
                                        </div>

                                        <span className={styles.selectItemName}>
                                            {chainsNamesMap[chain]}
                                        </span>
                                    </button>
                                ))}
                            </div>
                        )}
                        <h5 className={styles.addWalletInputHeader}>NETWORK</h5>
                        <ExtendedInput
                            wrapperClassName={styles.addWalletInput}
                            className={styles.disabledInput}
                            placeholder={'Select Network'}
                            value={chainsNamesMap[chain]}
                            readOnly={true}
                            icon={
                                chain !== '' ? chainsIconsMap[chain] : ChainIcon
                            }
                            onFocus={(e) => {
                                e.preventDefault();
                            }}
                        />
                        <img
                            className={styles.selectIcon}
                            src={SelectIcon}
                            alt="select"
                        />
                    </div>
                </div>

                <div className={styles.addWalletInputItem}>
                    <h5 className={styles.addWalletInputHeader}>
                        NEW WALLET ADDRESS
                    </h5>
                    <ExtendedInput
                        className={styles.disabledInput}
                        wrapperClassName={cn(
                            styles.addWalletInput,
                            view.isAddressActive && styles.addWalletInput_active
                        )}
                        customFocus={view.isAddressActive}
                        placeholder={'Wallet address'}
                        value={address}
                        onChange={onChangeAddress}
                        icon={WalletIcon}
                        onFocus={() => {
                            setView({
                                isNameActive: false,
                                isAddressActive: true,
                                isChainActive: false,
                            });
                        }}
                        onBlur={(prev) => {
                            setView({
                                isNameActive: prev.isNameActive,
                                isAddressActive: false,
                                isChainActive: prev.isChainActive,
                            });
                        }}
                    />
                </div>
            </div>
            <button
                className={styles.addWalletFormBtn}
                disabled={name === '' || address === '' || chain === ''}
                onClick={() => {
                    onAddWallet(name, address, chain);
                    setName('');
                    setAddress('');
                }}
            >
                Add wallet
            </button>
        </div>
    );
};

export default AddWalletForm;
