import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '../../../../assets/icons/sniper/search.svg';
import { sniperLightDivider } from '../../../../assets/svg';
import { useDebounce } from '../../../../hooks/useDebounce';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { requestExternalTokens } from '../../../../redux/action/sniper';
import { getRandomItems } from '../../../../utils/functions/filters';
import ExtendedInput from '../../../layout/ExtendedInput';
import Svg from '../../../layout/Svg/Svg';
import styles from './styles.module.scss';
import TokenItem from './TokenItem';

const TokenPicker = ({ closeTokenPicker, tokenChangeHandler }) => {
    const dispatch = useDispatch();

    const tokensList = useSelector((state) => state.sniper.tokens);
    const extTokensList = useSelector((state) => state.sniper.externalTokens);
    const blockchain = useSelector((state) => state.sniper.blockchain);

    const [search, setSearch] = useState('');
    const [isInputFocus, setIsInputFocus] = useState(false);
    const [tokenType, setTokenType] = useState('default');
    const [filteredTokens, setFilteredTokens] = useState([]);

    const debouncedSearch = useDebounce(
        (query) => dispatch(requestExternalTokens(query)),
        1000
    );

    useEffect(() => {
        if (search.length >= 2) {
            debouncedSearch(search);
        } else {
            setTokenType('default');
            setFilteredTokens(getRandomItems(tokensList, 5));
        }
    }, [search, tokensList]);

    useEffect(() => {
        if (search.length >= 2 && extTokensList.length) {
            setTokenType('external');

            setFilteredTokens(
                extTokensList
                    .filter(
                        (extToken) =>
                            extToken.chainId ===
                            (blockchain === 'eth' ? 'ethereum' : blockchain)
                    )
                    .map((extToken) => {
                        return {
                            ...extToken,
                            isApproved: tokensList.find((defToken) => {
                                return (
                                    defToken.Address.toLowerCase() ===
                                    extToken.baseToken.address.toLowerCase()
                                );
                            }),
                        };
                    })
            );
        }
    }, [extTokensList, blockchain, tokensList, search]);

    const ref = useOutsideClick(() => closeTokenPicker());

    return (
        <div className={styles.wrapper}>
            <div ref={ref} className={styles.menuContainer}>
                <ExtendedInput
                    wrapperClassName={cn(
                        styles.searchInput,
                        isInputFocus && styles.searchInput_focus
                    )}
                    placeholder="Search by token name or paste address"
                    icon={SearchIcon}
                    onFocus={() => setIsInputFocus(true)}
                    customFocus={isInputFocus}
                    onBlur={() => setIsInputFocus(false)}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <Svg className={styles.lightDivider} id={sniperLightDivider} />
                <div className={styles.tokensList}>
                    {filteredTokens.map((token, index) => (
                        <TokenItem
                            tokenType={tokenType}
                            tokenChangeHandler={tokenChangeHandler}
                            token={token}
                            key={index}
                            closeTokenPicker={closeTokenPicker}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TokenPicker;
