import BscIcon from '../../assets/icons/sniper/chains/bsc.svg';
import EthIcon from '../../assets/icons/sniper/chains/eth.svg';
import SolIcon from '../../assets/icons/sniper/chains/solana.svg';

import OptionsHintIcon from '../../assets/icons/sniper/hints/options.svg';
import WalletHintIcon from '../../assets/icons/sniper/hints/wallet.svg';

export const chainsIconsMap = {
    solana: SolIcon,
    eth: EthIcon,
    bsc: BscIcon,
};

export const chainsNamesMap = {
    solana: 'SOLANA',
    eth: 'ETHEREUM',
    bsc: 'BNB',
};

export const chainsDefTokens = {
    solana: 'SOL',
    eth: 'ETH',
    bsc: 'BSC',
};

export const hintsData = {
    wallet: {
        icon: WalletHintIcon,
        title: 'Wallet for withdraw',
        info: [
            {
                subTitle: 'Jito is',
                description:
                    ' exciting Updates in the Crypto World! Stay tuned for the latest trends and innovations that are shaping the future of digital currencies. ',
            },
        ],
    },
    options: {
        icon: OptionsHintIcon,
        gasTitle: 'Gas & Slippage',
        jitoTitle: 'Jito & Slippage',
        info: [
            {
                subTitle: 'Jito is',
                description:
                    ' exciting Updates in the Crypto World! Stay tuned for the latest trends and innovations that are shaping the future of digital currencies. ',
            },
            {
                subTitle: 'Jito is',
                description:
                    ' exciting Updates in the Crypto World! Stay tuned for the latest trends and innovations that are shaping the future of digital currencies. ',
            },
        ],
    },
};
