export const subscriptionTypes = {
    free: 'free',
    arb: 'arb',
};

export const newsTypes = {
    free: 'free',
    latest: 'latest',
    actuals: 'actuals',
};

export const arbitrageTypes = {
    free: 'free',
    cexToCex: 'cex-cex',
    cexToDex: 'cex-dex',
    dexToDex: 'dex-dex',
};

export const cexOutflowTypes = {
    cexOutflows: 'cex-outflows',
};

export const fundingTypes = {
    funding: 'funding-arb',
    futures: 'futures-arb',
};

export const trackerTypes = {
    CEX: 'cex-tracker',
    DEX: 'dex-tracker',
};

export const sniperBlockchains = ['solana', 'eth', 'bsc'];

//links

export const telegramLink = 'https://t.me/ownr777';
export const twitterLink = 'https://twitter.com/1whale_io';
export const subscribeTelegramLink = 'https://t.me/ownr777'; //"https://t.me/OneWhaleNews"
export const mailLink = 'mailto:info@1whale.io';
