import {
    editEmailImage,
    editUsernameImage,
    passwordKeyImage,
} from '../../../assets/images';
import { togglePush } from '../../../redux/action/notifications';
import PushIcon from '../../../assets/icons/push-icon.svg';
import ArbitrageIcon from '../../../assets/icons/arbitrage-icon.svg';
import ClosedArbitrageIcon from '../../../assets/icons/closed-arbitrage-icon.svg';
import TrackerIcon from '../../../assets/icons/tracker-icon.svg';
import CexIcon from '../../../assets/icons/cex-icon.svg';

export const getPassFields = () => [
    {
        icon: passwordKeyImage,
        placeholder: 'CURRENT PASSWORD',
        key: 'old_password',
        type: 'password',
        label: 'CURRENT PASSWORD'
    },
    {
        icon: passwordKeyImage,
        placeholder: 'NEW PASSWORD',
        key: 'new_password',
        type: 'password',
        label: 'NEW PASSWORD'
    },
    {
        icon: passwordKeyImage,
        placeholder: 'REPEAT NEW PASSWORD',
        key: 'repeat_password',
        type: 'password',
        label: 'REPEAT NEW PASSWORD'
    },
];

export const getUserFields = (user) => [
    {
        icon: editEmailImage,
        placeholder: 'Email',
        key: 'email',
        type: 'email',
        initialValue: user?.email || '',
        title: 'EMAIL'
    },
    {
        icon: editUsernameImage,
        placeholder: 'Имя пользователя',
        key: 'username',
        initialValue: user?.username || '',
        title: 'USER NAME'
    },
];

export const getCommonButtons = (user) => [
    {
        text: 'Connect telegram',
        title: 'TELEGRAM NOTIFICATIONS',
        primary: true,
        icon: (
            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.4357 4.11877C21.7271 2.69815 20.3317 1.51758 18.979 2.04024L2.39724 8.44683C0.886592 9.03049 0.819281 11.1429 2.2897 11.8215L5.90074 13.4882L7.61857 19.5005C7.7078 19.8128 7.95562 20.0547 8.27002 20.1362C8.58443 20.2176 8.91848 20.1266 9.14814 19.8969L11.7943 17.2508L15.501 20.0308C16.577 20.8378 18.1267 20.25 18.3969 18.9325L21.4357 4.11877ZM3.05798 10.157L19.6397 3.75037L16.601 18.5642L12.2583 15.3071C11.8934 15.0333 11.3827 15.0696 11.0601 15.3922L9.92674 16.5256L10.2673 14.6527L16.9398 7.98027C17.2646 7.65554 17.2989 7.14063 17.0201 6.77568C16.7413 6.41071 16.2356 6.30835 15.8369 6.5362L6.62192 11.8018L3.05798 10.157ZM7.74549 13.2713L8.30134 15.2169L8.51475 14.0431C8.5481 13.8596 8.63661 13.6907 8.76845 13.5589L10.8034 11.5241L7.74549 13.2713Z" fill="white"/>
            </svg>
        ),
        onClick: () => {
            const username = process.env.REACT_APP_TG_BOT_USERNAME;
            const slicedId = String(Math.abs(user?.id)).slice(-7);

            const generateRandomDigits = (length) => {
                let randomDigits = '';
                for (let i = 0; i < length; i++) {
                    randomDigits += Math.floor(Math.random() * 10);
                }
                return randomDigits;
            };

            const randomDigits = generateRandomDigits(32 - slicedId.length);
            const telegramUrl = `https://t.me/${username}?start=${randomDigits + slicedId}`;
            window.open(telegramUrl, '_blank');
        },
    },
];

export const getNotificationButtons = () => [
    {
        title: 'Push notification',
        description: 'To top up the balance, transfer money to the contract address',
        onTogglePush: togglePush,
        icon: PushIcon
    },
    {
        section: 'arbitrage',
        title: 'Arbitrage',
        description: 'To top up the balance, transfer money to the contract address',
        icon: ArbitrageIcon
    },
    {
        section: 'closedArbitrage',
        title: 'Closed Arbitrage',
        description: 'To top up the balance, transfer money to the contract address',
        icon: ClosedArbitrageIcon
    },
    {
        section: 'tracker',
        title: 'Tracker',
        description: 'To top up the balance, transfer money to the contract address',
        icon: TrackerIcon
    },
    {
        section: 'cexOutflows',
        title: 'CEX Outflows',
        description: 'To top up the balance, transfer money to the contract address',
        icon: CexIcon
    },
];