import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import TransactionItem from './TransactionItem';

const TransactionsJournal = () => {
    const trades = useSelector((state) => state.sniper.trades);
    const blockchain = useSelector((state) => state.sniper.blockchain);

    return (
        <>
            <div className={styles.transactionsHeader}>
                <h3 className={styles.title}>TRANSACTIONS</h3>
            </div>

            <div className={styles.transactionsList}>
                {trades.length > 0 ? (
                    trades
                        .slice(0, 10)
                        .map((trade) => (
                            <TransactionItem
                                key={trade.id}
                                trade={trade}
                                blockchain={blockchain}
                            />
                        ))
                ) : (
                    <>
                        <span>No transactions yet...</span>
                    </>
                )}
            </div>
        </>
    );
};

export default TransactionsJournal;
