import React, { useEffect, useState } from 'react';
import ApprovedIcon from '../../../../assets/icons/sniper/approved.svg';
import CopyIcon from '../../../../assets/icons/sniper/copy-purple.svg';
import FallbackTokenIcon from '../../../../assets/icons/sniper/default.svg';
import {
    formatNumberToFiveChars,
    formatWalletAddress,
} from '../../../../utils/functions/format';
import styles from './styles.module.scss';

const TokenItem = ({
    token,
    tokenChangeHandler,
    closeTokenPicker,
    tokenType,
}) => {
    const [tokenData, setTokenData] = useState(null);

    useEffect(() => {
        if (tokenType === 'default') {
            const getTokenData = async () => {
                console.log(token);
                try {
                    const res = await fetch(
                        `${process.env.REACT_APP_SCREENER_LINK}tokens/${token.Address}`
                    );

                    if (!res.ok) {
                        throw new Error(
                            `Error: ${res.status} ${res.statusText}`
                        );
                    }

                    const data = await res.json();
                    if (data.pairs) {
                        const imgUrl = data?.pairs[0]?.info?.imageUrl || '';
                        const marketCap = data?.pairs[0]?.marketCap || 0;
                        const liquidity = data?.pairs[0]?.liquidity?.usd || 0;
                        const dayVol = data?.pairs[0]?.volume?.h24 || 0;
                        const name = data?.pairs[0]?.baseToken?.name || '';
                        const priceUsdt = data?.pairs[0]?.priceUsd || 0;

                        setTokenData({
                            imgUrl,
                            marketCap,
                            liquidity,
                            dayVol,
                            name,
                            priceUsdt,
                        });
                    }
                } catch (error) {
                    console.error('Failed to fetch token data:', error);
                    setTokenData({
                        imgUrl: '',
                        marketCap: 0,
                        liquidity: 0,
                        dayVol: 0,
                        name: '',
                        priceUsdt: 0,
                    });
                }
            };
            getTokenData();
        }

        if (tokenType === 'external') {
            setTokenData({
                imgUrl: token.info?.imageUrl || '',
                marketCap: token.marketCap || 0,
                liquidity: token.liquidity?.usd || 0,
                dayVol: token.volume?.h24 || 0,
                name: token.baseToken?.name || '',
                priceUsdt: token.priceUsd || 0,
            });
        }
    }, [token, tokenType]);

    if (!tokenData) return <></>;

    return (
        <div
            onClick={() => {
                tokenChangeHandler(
                    tokenType === 'default'
                        ? {
                              name: token.Symbol,
                              priceUsdt: tokenData.priceUsdt,
                              address: token.Address,
                              imageUrl: tokenData.imgUrl,
                          }
                        : {
                              name: token.baseToken.symbol,
                              address: token.baseToken.address,
                              priceUsdt: tokenData.priceUsdt,
                              imageUrl: tokenData.imgUrl,
                          }
                );
                closeTokenPicker();
            }}
            className={styles.tokenItem}
        >
            <div className={styles.tokenImgContainer}>
                <img src={tokenData.imgUrl || FallbackTokenIcon} alt="token" />
            </div>
            <div className={styles.tokenExtraData}>
                <p className={styles.tokenName}>
                    <span>
                        {tokenType === 'default'
                            ? token.Symbol
                            : token.baseToken.symbol}
                    </span>
                    <span className={styles.nameDivider}>/</span>
                    <span> {tokenData.name}</span>
                    {(tokenType === 'default' || token.isApproved) && (
                        <img src={ApprovedIcon} alt="approved" />
                    )}
                </p>
                <div className={styles.tokenInfo}>
                    <p className={styles.tokenInfoItem}>
                        <span className={styles.tokenInfoTitle}>Mkt Cap:</span>
                        <span className={styles.tokenInfoValue}>
                            {formatNumberToFiveChars(tokenData.marketCap)}
                        </span>
                    </p>
                    <p className={styles.tokenInfoItem}>
                        <span className={styles.tokenInfoTitle}>
                            Liquidity:
                        </span>
                        <span className={styles.tokenInfoValue}>
                            {formatNumberToFiveChars(tokenData.liquidity)}
                        </span>
                    </p>
                    <p className={styles.tokenInfoItem}>
                        <span className={styles.tokenInfoTitle}>24H Vol:</span>
                        <span className={styles.tokenInfoValue}>
                            {formatNumberToFiveChars(tokenData.dayVol)}
                        </span>
                    </p>
                </div>
            </div>
            <div className={styles.tokenLabels}>
                <span className={styles.tokenPrice}>
                    {Math.ceil(Number(tokenData.priceUsdt) * 1000) / 1000}
                </span>
                <div className={styles.tokenAddress}>
                    <span className={styles.tokenAddressTitle}>Token: </span>
                    <span className={styles.tokenAddressValue}>
                        {formatWalletAddress(
                            tokenType === 'default'
                                ? token.Address
                                : token.baseToken.address
                        )}
                    </span>
                    <button
                        onClick={() => {
                            navigator.clipboard
                                .writeText(token.address)
                                .catch((err) => {
                                    console.error(
                                        'Не удалось скопировать текст: ',
                                        err
                                    );
                                });
                        }}
                        className={styles.tokenAddressBtn}
                    >
                        <img src={CopyIcon} alt="copy" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TokenItem;
