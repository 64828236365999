import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { chainsDefTokens } from '../../../pages/DexTerminal/config';
import { formatNumberToFiveChars } from '../../../utils/functions/format';
import styles from './styles.module.scss';

const PosWidget = ({ name, address, priceNative, amount, showNotify }) => {
    const blockchain = useSelector((state) => state.sniper.blockchain);
    const user = useSelector((state) => state.auth.user);
    const slippage = useSelector((state) => state.sniper.slippage);
    const gasPrice = useSelector((state) => state.sniper.gasPrice);

    const [side, setSide] = useState('sell');
    const [amountToSwap, SetAmountToSwap] = useState(amount ?? 0);

    const defaultWallets = useMemo(() => {
        return {
            solana: user?.sol_contract,
            eth: user?.eth_contract,
            bsc: user?.bsc_contract,
        };
    }, [user]);

    const onSubmitTransaction = () => {
        console.log({
            event: 'swap',
            blockchain,
            id: user?.id,
            token: name,
            side,
            amount: amountToSwap || 0,
            slippage: slippage || 0,
            gas_price: gasPrice || 0,
            token_address: address,
            wallet: defaultWallets[blockchain],
        });

        showNotify(
            'Transaction success',
            'success',
            'http://sample.com',
            5000,
            `Swap success`
        );
    };

    return (
        <div className={styles.posItemWidget}>
            <div className={styles.posWidgetSideBtns}>
                <button
                    className={cn(
                        styles.posWidgetSideBtn,
                        side === 'buy' && styles.posWidgetSideBtn_buy
                    )}
                    onClick={() => {
                        setSide('buy');
                    }}
                >
                    Buy
                </button>
                <button
                    onClick={() => {
                        setSide('sell');
                    }}
                    className={cn(
                        styles.posWidgetSideBtn,
                        side === 'sell' && styles.posWidgetSideBtn_sell
                    )}
                >
                    Sell
                </button>
            </div>
            <div className={styles.posWidgetSwapContainer}>
                <div className={styles.posWidgetSwapInputContainer}>
                    <span className={styles.posWidgetSwapInputLabel}>
                        Amount
                    </span>
                    <input
                        value={amountToSwap}
                        onChange={(e) => {
                            SetAmountToSwap(e.target.value);
                        }}
                        className={styles.posWidgetSwapInput}
                        type="number"
                    />
                    <span className={styles.posWidgetInputAvgAmount}>
                        ≈ {formatNumberToFiveChars(amountToSwap * priceNative)}
                        {chainsDefTokens[blockchain]}
                    </span>
                </div>
                <button
                    onClick={onSubmitTransaction}
                    className={styles.posWidgetSwapBtn}
                >
                    Swap
                </button>
            </div>
            <input
                min={amount / 100}
                max={amount}
                step={amount / 100}
                value={amountToSwap}
                onChange={(e) => {
                    SetAmountToSwap(e.target.value);
                }}
                className={styles.posWidgetRangeInput}
                type="range"
            />
        </div>
    );
};

export default PosWidget;
