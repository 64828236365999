import React from 'react';
import { useSelector } from 'react-redux';
import CopyIcon from '../../../assets/icons/sniper/copy-purple.svg';
import ExternalLinkIcon from '../../../assets/icons/sniper/link-purple.svg';
import WithdrawIcon from '../../../assets/icons/sniper/withdraw.svg';
import { chainsIconsMap } from '../../../pages/DexTerminal/config';
import { formatWalletAddress } from '../../../utils/functions/format';
import styles from './styles.module.scss';

const Header = ({ openWithdrawMenu }) => {
    const user = useSelector((state) => state.auth.user);
    const positions = useSelector((state) => state.sniper.positions);

    const allWorth = positions.reduce((acc, pos) => {
        return acc + pos.priceUsdt;
    }, 0);

    const defaultWallets = [
        {
            icon: chainsIconsMap['solana'],
            address: user?.sol_contract,
            link: `${process.env.REACT_APP_SOL_SCAN_LINK}account/${user?.sol_wallet}`,
        },
        {
            icon: chainsIconsMap['eth'],
            address: user?.eth_contract,
            link: `${process.env.REACT_APP_ETH_SCAN_LINK}address/${user?.eth_wallet}`,
        },
        {
            icon: chainsIconsMap['bsc'],
            address: user?.bsc_contract,
            link: `${process.env.REACT_APP_BSC_SCAN_LINK}address/${user?.bsc_wallet}`,
        },
    ];

    return (
        <div className={styles.header}>
            <div className={styles.headerInfo}>
                <p className={styles.worth}>${allWorth.toFixed(2)}</p>
                <button
                    onClick={openWithdrawMenu}
                    className={styles.withdrawBtn}
                >
                    <img src={WithdrawIcon} alt="withdraw" />
                    <span>Withdraw</span>
                </button>
            </div>
            <div className={styles.walletsList}>
                {defaultWallets.map((wallet) => (
                    <div
                        key={wallet.address}
                        className={styles.walletsListItem}
                    >
                        <div className={styles.chainImgContainer}>
                            <img src={wallet.icon} alt="blockchain" />
                        </div>
                        <span className={styles.walletAddress}>
                            {formatWalletAddress(wallet.address)}
                        </span>
                        <button
                            className={styles.actionBtn}
                            onClick={() => {
                                navigator.clipboard
                                    .writeText(wallet.address)
                                    .catch((err) => {
                                        console.error(
                                            'Не удалось скопировать текст: ',
                                            err
                                        );
                                    });
                            }}
                        >
                            <img src={CopyIcon} alt="copy" />
                        </button>
                        <button
                            onClick={() => {
                                window.open(wallet.link, '_blank');
                            }}
                            className={styles.actionBtn}
                        >
                            <img src={ExternalLinkIcon} alt="external" />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Header;
