import React, { useState } from 'react';
import ChartActiveIcon from '../../../../assets/icons/sniper/widgetHeader/chart-active.svg';
import ChartHoverIcon from '../../../../assets/icons/sniper/widgetHeader/chart-hover.svg';
import ChartIcon from '../../../../assets/icons/sniper/widgetHeader/chart.svg';
import HintsActiveIcon from '../../../../assets/icons/sniper/widgetHeader/hints-active.svg';
import HintsHoverIcon from '../../../../assets/icons/sniper/widgetHeader/hints-hover.svg';
import HintsIcon from '../../../../assets/icons/sniper/widgetHeader/hints.svg';
import HistoryActiveIcon from '../../../../assets/icons/sniper/widgetHeader/history-active.svg';
import HistoryHoverIcon from '../../../../assets/icons/sniper/widgetHeader/history-hover.svg';
import HistoryIcon from '../../../../assets/icons/sniper/widgetHeader/history.svg';
import TableActiveIcon from '../../../../assets/icons/sniper/widgetHeader/table-active.svg';
import TableHoverIcon from '../../../../assets/icons/sniper/widgetHeader/table-hover.svg';
import TableIcon from '../../../../assets/icons/sniper/widgetHeader/table.svg';
import styles from './styles.module.scss';

const HeaderButton = ({
    name,
    hover,
    isOpened,
    iconDefault,
    iconHover,
    iconActive,
    onMouseEnter,
    onMouseLeave,
    onClick,
    disabled,
}) => {
    return (
        <button
            disabled={disabled}
            onMouseEnter={() => onMouseEnter(name)}
            onMouseLeave={onMouseLeave}
            className={styles.headerBtn}
            onClick={onClick}
        >
            <img
                src={
                    hover === name
                        ? iconHover
                        : isOpened
                          ? iconActive
                          : iconDefault
                }
                alt={`toggle ${name}`}
            />
        </button>
    );
};

const WidgetHeader = ({
    togglePanel,
    toggleHistory,
    isChartOpened,
    isPosTableOpened,
    isHistoryOpened,
    changeHintsView,
    hintsView,
}) => {
    const [hover, setHover] = useState(null);

    const handleMouseEnter = (buttonName) => {
        setHover(buttonName);
    };

    const handleMouseLeave = () => {
        setHover(null);
    };

    return (
        <div className={styles.widgetHeader}>
            <h3 className={styles.title}>Swap</h3>
            <div className={styles.headerBtns}>
                <HeaderButton
                    name="table"
                    hover={hover}
                    isOpened={isPosTableOpened}
                    iconDefault={TableIcon}
                    iconHover={TableHoverIcon}
                    iconActive={TableActiveIcon}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => togglePanel('table')}
                />

                <HeaderButton
                    name="chart"
                    hover={hover}
                    isOpened={isChartOpened}
                    iconDefault={ChartIcon}
                    iconHover={ChartHoverIcon}
                    iconActive={ChartActiveIcon}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => togglePanel('chart')}
                />

                <HeaderButton
                    name="history"
                    hover={hover}
                    isOpened={isHistoryOpened}
                    iconDefault={HistoryIcon}
                    iconHover={HistoryHoverIcon}
                    iconActive={HistoryActiveIcon}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={toggleHistory}
                />

                <HeaderButton
                    name="hints"
                    hover={hover}
                    isOpened={hintsView?.isOptions || hintsView?.isWallet}
                    disabled={hintsView?.isOptions || hintsView?.isWallet}
                    iconDefault={HintsIcon}
                    iconHover={HintsHoverIcon}
                    iconActive={HintsActiveIcon}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                        changeHintsView(
                            hintsView?.isOptions || hintsView?.isWallet
                                ? 'closeAll'
                                : 'openAll'
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default WidgetHeader;
