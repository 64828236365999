import React, { Fragment, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Svg from '../../../../layout/Svg/Svg';
import ActiveIndicator from './ActiveIndicator';
import TransitionProvider from '../../../../../providers/TransitionProvider';
import styles from './styles.module.scss';
import ProgressLine from './ProgressLine';

const NavigationLinks = ({
    allowedLinks,
    user,
    pathname,
    hash,
    isCompact,
    openLink,
    handleLinkClick,
    toggleAccordion,
    onToggleCompact,
    activeNavLinks,
    onCloseBurger,
    isMobile,
    navLinks
}) => {
    const [closingItems, setClosingItems] = useState([]);

    return allowedLinks.map(({ title, icon, path, adminPath, sublinks }, index) => {
        const linkPath = user?.role === 'admin' && adminPath ? adminPath : path;
        const isAccordionOpen = openLink === linkPath;

        const handleAccordionToggle = (linkPath) => {
            if (isAccordionOpen) {
                const items = [...sublinks].reverse();
                
                // Запускаем анимацию исчезновения
                items.forEach((item, index) => {
                    setTimeout(() => {
                        setClosingItems(prev => [...prev, item.href]);
                    }, index * 100);
                });
                
                // Закрываем аккордеон сразу после исчезновения текста
                setTimeout(() => {
                    toggleAccordion(linkPath);
                }, (items.length - 1) * 100);
                
                // Очищаем состояние closingItems после полного закрытия
                setTimeout(() => {
                    setClosingItems([]);
                }, items.length * 100 + 300);
            } else {
                toggleAccordion(linkPath);
            }
        };

        const onClickLinks = (isNavLink) => {
            if (isMobile && (!isNavLink || (isNavLink && !sublinks) || sublinks.length === 1)) {
                onCloseBurger();
            }
        };

        return (
            <Fragment key={index}>
                {index === navLinks.length - 1 && <b className={styles['sideBarLinks__linebtm']} />}

                {sublinks ? (
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            if (isCompact) {
                                onToggleCompact(false);
                            }
                            handleAccordionToggle(linkPath);
                        }}
                        className={`${styles['sideBarLinks__link']} ${isAccordionOpen ? styles['sideBarLinks__link_active'] : ''}`}
                    >
                        {isAccordionOpen && <ActiveIndicator />}
                        <Svg
                            className={styles['sideBarLinks__linkIcon']}
                            id={isAccordionOpen || pathname === linkPath ? `${icon}Active` : icon}
                        />
                        <span className={styles['sideBarLinks__linkText']}>
                            {title}
                        </span>
                        <Svg
                            id="arrowDown"
                            className={styles['sideBarLinks__linkArrowIcon']}
                        />
                    </button>
                ) : (
                    <NavLink
                        onClick={(e) => {
                            if (!handleLinkClick({ path: linkPath })) {
                                e.preventDefault();
                            }
                        }}
                        to={linkPath}
                        className={activeNavLinks}
                    >
                        {pathname === linkPath && <ActiveIndicator />}
                        <Svg
                            className={styles['sideBarLinks__linkIcon']}
                            id={pathname === linkPath ? `${icon}Active` : icon}
                        />
                        <span className={styles['sideBarLinks__linkText']}>
                            {title}
                        </span>
                    </NavLink>
                )}

                {index === navLinks.length - 1 && <b className={styles['sideBarLinks__linebtm']} />}

                {sublinks && (
                    <TransitionProvider
                        duration={300}
                        inProp={isAccordionOpen}
                        style={'height'}
                        height={'300px'}
                        className={styles['sideBarLinks__acardeon']}
                    >
                        <ProgressLine 
                            totalItems={sublinks.length}
                            activeIndex={sublinks.findIndex(item => hash.includes(item.href))}
                        />
                        {sublinks.map((item, index) => (
                            <Link
                                key={index}
                                onClick={(e) => {
                                    if (!handleLinkClick(item)) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }
                                }}
                                to={`${linkPath}#${item.href}`}
                                className={`${styles['sideBarLinks__acardeonItem']} ${
                                    hash.includes(item.href) ? styles['sideBarLinks__acardeonItem_active'] : ''
                                } ${closingItems.includes(item.href) ? styles['closing'] : ''}`}
                                style={{"--item-index": index}}
                            >
                                <span>{item.title}</span>
                            </Link>
                        ))}
                    </TransitionProvider>
                )}
            </Fragment>
        );
    });
};

export default NavigationLinks;
