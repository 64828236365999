export const formatWalletAddress = (input) => {
    if (input.length > 9) {
        input = `${input.slice(0, 4)}...${input.slice(-3)}`;
    }
    return input.toUpperCase();
};

export const formatNumberToFiveChars = (value) => {
    if (value >= 1000) {
        return `${(value / 1000).toFixed(1)}k`;
    }
    return value.toFixed(4).slice(0, 5);
};
