import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormValue } from '../../../../hooks/useFormValue';
import NewPortalProvider from '../../../../providers/NewPortalProvider';
import TransitionProvider from '../../../../providers/TransitionProvider';
import { changeFilters } from '../../../../redux/action/filters';
import Backdrop from '../../../layout/Backdrop/Backdrop';
import CrossBtn from '../../../layout/CrossBtn/CrossBtn';
import { configureFilters } from './config';
import styles from './FilterModal.module.scss';
import FilterModalItem from './FilterModalItem';
import ModalButtons from './ModalButtons';

const FilterModal = memo(({ show, onClose, pageType, onSuccessFilterApply }) => {
    const dispatch = useDispatch();
    const userFilters = useSelector((state) => state.filters.filters);
    const currentBlockchain = useSelector((state) => state.filters.currentBlockchain);

    const [chosenBlockchainValue, setChosenBlockchainValue] = useState('');
    const [chosenBlockchain, setChosenBlockchain] = useState('Выберите blockchain');
    const [sortValue, setSortValue] = useState('');
    const [isBlockchainSelectOpened, setIsBlockchainSelectOpened] = useState(false);

    const filters = configureFilters(userFilters || {}, pageType, chosenBlockchainValue) || [];

    const initialData = {
        ...filters.reduce((acc, cur) => {
            if (cur) {
                acc[cur.filterKey] =
                    cur.filterType === 'checkbox'
                        ? cur.selectedFilterOptions || []
                        : cur.filterType === 'select'
                        ? cur.selectedFilterOption
                        : cur.filterValue || 0;
            }
            return acc;
        }, {}),
        sort: sortValue
    };

    const { onChange, formData, setFormData } = useFormValue(initialData);

    const onToggleCheckBox = (name, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [name]: prevState[name]?.includes(value)
                ? prevState[name].filter((filterItem) => filterItem !== value)
                : [...(prevState[name] || []), value],
        }));
    };

    const onToggleBlockchainSelect = () => {
        setIsBlockchainSelectOpened((prev) => !prev);
    };

    const onChooseBlockchainSelect = (title, value) => {
        setChosenBlockchain(title);
        setChosenBlockchainValue(value);
        setIsBlockchainSelectOpened(false);
        setFormData((prevState) => ({
            ...prevState,
            profit: userFilters.blockchainFilters[value]?.Profit || prevState.profit,
            min_amount: userFilters.blockchainFilters[value]?.MinAmount || prevState.min_amount,
            max_amount: userFilters.blockchainFilters[value]?.MaxAmount || prevState.max_amount,
            sort: userFilters.blockchainFilters[value]?.sort || prevState.sort,
            blockchain: value,
        }));
    };

    const handleSortChange = (value) => {
        setSortValue(value);
        setFormData(prev => ({
            ...prev,
            sort: value
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const resData = Object.keys(formData).reduce((acc, cur) => {
            acc[cur] = Array.isArray(formData[cur])
                ? formData[cur]
                : formData[cur] === ''
                ? formData[cur]
                : isNaN(Number(formData[cur]))
                    ? formData[cur]
                    : +formData[cur];
            return acc;
        }, {});

        const onSuccess = () => {
            dispatch(onSuccessFilterApply());
            onClose(true);
        };

        dispatch(changeFilters(resData, onSuccess));
    };

    useEffect(() => {
        setFormData({ ...formData, kind: pageType });
    }, [pageType]);

    return (
        <>
            <Backdrop inProp={show} onClose={onClose} highZIndex />
            <NewPortalProvider>
                <TransitionProvider
                    className={styles.filterModal}
                    inProp={show}
                    style="right"
                >
                    <CrossBtn
                        btnClassName={styles.filterModal__crossBtn}
                        onClick={onClose}
                    />
                    <form
                        className={`${styles.filterModal__container} scrollbarDef`}
                        method="POST"
                        onSubmit={onSubmit}
                    >
                        {filters.filter(Boolean).map((filter, index) => (
                            filter.filterKey === 'sort' ? (
                                <div key={index}>
                                    <div className={styles.filterLabel}>{filter.filterLabel}</div>
                                    <div className={styles.sortToggle}>
                                        <button
                                            type="button"
                                            className={`${styles.sortToggle__button} ${
                                                sortValue === 'profit' ? styles['sortToggle__button--active'] : ''
                                            }`}
                                            onClick={() => handleSortChange('profit')}
                                        >
                                            Profit
                                        </button>
                                        <button
                                            type="button"
                                            className={`${styles.sortToggle__button} ${
                                                sortValue === 'spotted' ? styles['sortToggle__button--active'] : ''
                                            }`}
                                            onClick={() => handleSortChange('spotted')}
                                        >
                                            Spotted
                                        </button>
                                    </div>
                                    <b className={styles['sideBarLinks__linebtm']} />
                                </div>
                            ) : (
                                <FilterModalItem
                                    key={index}
                                    filter={filter}
                                    formData={formData}
                                    onToggleCheckBox={onToggleCheckBox}
                                    onToggleBlockchainSelect={onToggleBlockchainSelect}
                                    onInputChange={onChange}
                                    chosenBlockchain={chosenBlockchain}
                                    onChooseBlockchainSelect={onChooseBlockchainSelect}
                                    isBlockchainSelectOpened={isBlockchainSelectOpened}
                                />
                            )
                        ))}

                        <ModalButtons onCancel={onClose} />
                    </form>
                </TransitionProvider>
            </NewPortalProvider>
        </>
    );
});

export default FilterModal;
