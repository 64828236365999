import React, { useState } from 'react';
import ExternalLinkIcon from '../../../../assets/icons/sniper/link.svg';
import ResultArrowIcon from '../../../../assets/icons/sniper/result-arrow.svg';
import SwitchIcon from '../../../../assets/icons/sniper/switch.svg';
import TimeIcon from '../../../../assets/icons/sniper/time.svg';
import {
    chainsIconsMap,
    chainsNamesMap,
} from '../../../../pages/DexTerminal/config';
import { formatToTimeAgo } from '../../../../utils/functions/date';
import styles from './styles.module.scss';

const TransactionItem = ({ trade }) => {
    const [revert, setRevert] = useState(false);

    const formattedAmountIn =
        trade.amount_in / Math.pow(10, trade.input_decimals);
    const formattedAmountOut =
        trade.amount_out / Math.pow(10, trade.output_decimals);

    const txLinkStart = `${trade.blockchain === 'solana' ? process.env.REACT_APP_SOL_SCAN_LINK : trade.blockchain === 'eth' ? process.env.REACT_APP_ETH_SCAN_LINK : process.env.REACT_APP_BSC_SCAN_LINK}tx/`;

    return (
        <div className={styles.transactionContainer}>
            <div className={styles.transactionHeader}>
                <div className={styles.chainImgContainer}>
                    <img
                        src={chainsIconsMap[trade.blockchain]}
                        alt={chainsNamesMap[trade.blockchain]}
                    />
                </div>
                <p className={styles.amountContainer}>
                    <span className={styles.amountVal}>
                        {formattedAmountIn}
                    </span>
                    <span className={styles.amountName}>
                        {trade.tokenIn_symbol}
                    </span>
                </p>
                <img src={ResultArrowIcon} alt="" />
                <p className={styles.amountContainer}>
                    <span className={styles.amountVal}>
                        {formattedAmountOut}
                    </span>
                    <span className={styles.amountName}>
                        {trade.tokenOut_symbol}
                    </span>
                </p>
                <button
                    onClick={() => {
                        window.open(`${txLinkStart}${trade.tx_id}`, '_blank');
                    }}
                    className={styles.linkBtn}
                >
                    <img src={ExternalLinkIcon} alt="external" />
                </button>
            </div>
            <div className={styles.transactionFooter}>
                <div className={styles.transactionPrice}>
                    <span>
                        1{' '}
                        {revert ? trade.tokenOut_symbol : trade.tokenIn_symbol}{' '}
                        ={' '}
                        {revert
                            ? formattedAmountIn / formattedAmountOut
                            : formattedAmountOut / formattedAmountIn}{' '}
                        {revert ? trade.tokenIn_symbol : trade.tokenOut_symbol}
                    </span>

                    <button
                        onClick={() => {
                            setRevert((prev) => !prev);
                        }}
                        className={styles.switchBtn}
                    >
                        <img src={SwitchIcon} alt="switch" />
                    </button>
                </div>
                <div className={styles.transactionTime}>
                    <img src={TimeIcon} alt="time" />
                    {formatToTimeAgo(trade.timestamp)}
                </div>
            </div>
        </div>
    );
};

export default TransactionItem;
