import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useOutsideClick from '../../../hooks/useOutsideClick';
import {
    addWallet,
    deleteWallet,
    fetchRequest,
} from '../../../redux/action/fetchTools';
import ChainPicker from '../shared/ChainPicker';
import AddWalletForm from './AddWalletForm';
import Notify from './Notify';
import styles from './WalletMenu.module.scss';
import WalletsList from './WalletsList';

const WalletMenu = ({ closeWalletMenuHandler }) => {
    const blockchain = useSelector((state) => state.sniper.blockchain);
    const user = useSelector((state) => state.auth.user);
    const [notify, setNotify] = useState(null);
    const [wallets, setWallets] = useState([]);

    const ref = useOutsideClick(closeWalletMenuHandler);

    useEffect(() => {
        if (user.user_wallets) {
            setWallets(user.user_wallets[blockchain] || []);
        }
    }, [user, blockchain]);

    const showNotify = (text, type, timeout) => {
        setNotify({ text, type });
        setTimeout(() => {
            setNotify(null);
        }, timeout);
    };

    const onEditWallet = async (id) => {
        try {
            const res = await fetchRequest(deleteWallet, 'POST', {
                user_id: user?.id,
                wallet_id: id,
                blockchain: blockchain,
            });

            if (res.ok) {
                if (res.message) {
                    showNotify(res.message, 'success', 3000);
                } else {
                    showNotify('Wallet deleted', 'success', 3000);
                }
            }
        } catch (error) {
            if (error.message) {
                showNotify(error.message, 'error', 3000);
            } else {
                showNotify('Failed to delete wallet', 'error', 3000);
            }
        }
    };

    const onRemoveWallet = (id) => {
        setWallets((prevWallets) =>
            prevWallets.filter((wallet) => wallet.wallet_id !== id)
        );
        showNotify('Wallet removed', 'error', 3000);
    };

    const onAddWallet = async (name, address, blockchain) => {
        try {
            const res = await fetchRequest(addWallet, 'POST', {
                user_id: user?.id,
                wallet_label: name,
                wallet_address: address,
                blockchain: blockchain,
            });

            if (res.ok) {
                if (res.message) {
                    showNotify(res.message, 'success', 3000);
                } else {
                    showNotify('New wallet added', 'success', 3000);
                }
            }
        } catch (error) {
            if (error.message) {
                showNotify(error.message, 'error', 3000);
            } else {
                showNotify('Failed to add wallet', 'error', 3000);
            }
        }
    };

    return (
        <div className={styles.wrapper}>
            <div ref={ref} className={styles.menuContainer}>
                <h4 className={styles.title}>Add Wallet</h4>
                <ChainPicker
                    containerClassName={styles.walletsChainsList}
                    itemClassName={styles.chainBtn}
                />
                <WalletsList
                    onEditWallet={onEditWallet}
                    onRemoveWallet={onRemoveWallet}
                    wallets={wallets}
                    showNotify={showNotify}
                />
                <AddWalletForm onAddWallet={onAddWallet} />
                <button
                    className={styles.closeBtn}
                    onClick={closeWalletMenuHandler}
                >
                    Cancel
                </button>
            </div>
            {notify !== null && <Notify notifyData={notify} />}
        </div>
    );
};

export default WalletMenu;
