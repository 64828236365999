import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sniperSwitchIcon } from '../../../../assets/svg';
import { formatNumberToFiveChars } from '../../../../utils/functions/format';
import Svg from '../../../layout/Svg/Svg';
import styles from './styles.module.scss';

const InfoBlock = ({
    side: initialSide,
    tokenName,
    amountOut,
    amountIn: initialAmountIn,
    slippage,
    tvl,
}) => {
    const blockchain = useSelector((state) => state.sniper.blockchain);

    const [side, setSide] = useState('sell');
    const [amountIn, setAmountIn] = useState(0);
    const [avgPrice, setAvgPrice] = useState(0);

    useEffect(() => {
        if (initialAmountIn && initialSide) {
            setAmountIn(initialAmountIn);
            setSide(initialSide);
        }
    }, [initialAmountIn, initialSide]);

    useEffect(() => {
        if (amountOut && amountIn) {
            setAvgPrice(
                side === 'buy' ? amountOut / amountIn : amountIn / amountOut
            );
        }
        if (amountOut === 0) {
            setAvgPrice(0);
        }
    }, [amountOut, amountIn, initialSide, side]);

    const handleSwitch = () => {
        setSide((prevSide) => (prevSide === 'buy' ? 'sell' : 'buy'));
    };

    return (
        <div className={styles.infoBlock}>
            <div className={styles.priceContainer}>
                <span className={styles.subTitle}>
                    1{' '}
                    {(side === 'sell' ? tokenName : 'USDT').toLocaleUpperCase()}{' '}
                    ={' '}
                    <span className={styles.amountHighlight}>
                        {' '}
                        {formatNumberToFiveChars(avgPrice)}{' '}
                    </span>
                    <span className={styles.highlightedInfo}>
                        {(side === 'buy'
                            ? tokenName
                            : 'USDT'
                        ).toLocaleUpperCase()}
                    </span>
                </span>

                <button className={styles.switchBtn} onClick={handleSwitch}>
                    <Svg id={sniperSwitchIcon} className={styles.switchIcon} />
                </button>
            </div>

            <p className={cn(styles.subTitle, styles.subTitle_center)}>
                <span className={styles.highlightedInfo}>TVL:</span>
                <span className={styles.amountHighlight}>
                    {formatNumberToFiveChars(tvl)}${' '}
                </span>
            </p>

            <span className={cn(styles.subTitle, styles.subTitle_right)}>
                MIN ={' '}
                <span className={styles.amountHighlight}>
                    {formatNumberToFiveChars(
                        amountOut - (amountOut * slippage) / 100
                    )}
                </span>
                <span className={styles.highlightedInfo}>
                    {' '}
                    {(initialSide === 'buy'
                        ? tokenName
                        : 'usdt'
                    ).toLocaleUpperCase()}
                </span>
            </span>
        </div>
    );
};

export default InfoBlock;
