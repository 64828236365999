import React from 'react';
import { useDispatch } from 'react-redux';
import Svg from '../../../layout/Svg/Svg';
import styles from './styles.module.scss';

const NotificationsButtonBox = ({
    onTogglePush,
    onToggleTelegram,
    onToggleWeb,
    isPushEnabled,
    isTelegramEnabled,
    isWebEnabled,
    icon,
    title,
    description,
}) => {
    const dispatch = useDispatch();

    const handlePushToggle = () => {
        onTogglePush && dispatch(onTogglePush());
    };

    return (
        <div className={styles['notificationBlock']}>
            <div className={styles['notificationBlockContent']}>
                <img src={icon} alt={title} className={styles['notificationBlockIcon']} />
                <div className={styles['notificationBlockTextContainer']}>
                    <h6 className={styles['notificationBlockTitle']}>{title}</h6>
                    <p className={styles['notificationBlockText']}>{description}</p>
                </div>
            </div>
            <div className={styles['notificationBlockBtnsContainer']}>
                {!onTogglePush && onToggleTelegram && (
                    <button
                        onClick={onToggleTelegram}
                        className={`${styles['notificationBlockBtn']} ${isTelegramEnabled ? styles['notificationBlockBtn_active'] : ''}`}
                    >
                    </button>
                )}
                {onTogglePush && (
                    <button
                        onClick={handlePushToggle}
                        className={`${styles['notificationBlockBtn']} ${isPushEnabled ? styles['notificationBlockBtn_active'] : ''} `}
                    >
                    </button>
                )}
                {!onTogglePush && onToggleWeb && (
                    <button
                        onClick={onToggleWeb}
                        className={`${styles['notificationBlockBtn']} ${isWebEnabled && styles['notificationBlockBtn_active']} `}
                    >
                    </button>
                )}
            </div>
        </div>
    );
};

export default NotificationsButtonBox;
