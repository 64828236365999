import React, { memo, useState } from 'react';
import {
    crossIcon,
    externalLink,
    eyeIcon,
    reportFakeIcon,
} from '../../../../assets/svg';
import {
    formatDateWithTime,
    getTimeDifference,
    setDateText,
} from '../../../../utils/functions/date';
import MainBtn from '../../../layout/MainBtn/MainBtn';
import Svg from '../../../layout/Svg/Svg';
import {
    exchangeColors,
    formatExchangeTitle,
    openNewBrowserWindow,
    setOrderText,
} from './config';

import useOutsideClick from '../../../../hooks/useOutsideClick';
import {
    fetchRequest,
    getCEXOutflowsUrl,
} from '../../../../redux/action/fetchTools';
import styles from './ArbitragePageListItem.module.scss';

const ArbitragePageListItem = memo(
    ({
        item,
        AskCount,
        AskAmount,
        BidAmount,
        AskAmountUSDT,
        BidAmountUSDT,
        BidPrice,
        AskPrice,
        BidCount,
        Chain,
        FeeUSDT,
        Profit,
        SpotFee,
        Symbol,
        onSendReportMessage,
        onAddToBlackList,
        onAddToHidden,
        Ex1,
        Ex2,
        isArb,
        Link1,
        Link2,
        Straight,
        Spoted,
        isCexToDex,
        isDexToDex,
        ProfitPct,
        Contract,
        ContractLink,
        ContractAsk,
        ContractAskLink,
        ContractBid,
        ContractBidLink,
        DexScreenerLink,
        HoldersLink,
        GoPlusLink,
        BuyTaxAsk,
        SellTaxAsk,
        BuyTaxBid,
        SellTaxBid,
        SellTax,
        BuyTax,
        CanChangeAsk,
        CanChangeBid,
        CanChange,
        userId,
        userWallets,
    }) => {
        const spotedText = getTimeDifference(Spoted);

        const [isInputOutflowHintOpened, setIsInputOutflowHintOpened] =
            useState(false);
        const [isOutputOutflowHintOpened, setIsOutputOutflowHintOpened] =
            useState(false);
        const [outflowsHint, setOutflowsHint] = useState(null);

        const inputOutflowRef = useOutsideClick(() => {
            setIsInputOutflowHintOpened(false);
        });
        const outputOutflowRef = useOutsideClick(() => {
            setIsOutputOutflowHintOpened(false);
        });

        let exAsk = Straight ? Ex1 : Ex2;
        let exBid = !Straight ? Ex1 : Ex2;
        const linkAsk = Straight ? Link1 : Link2;
        const linkBid = !Straight ? Link1 : Link2;

        const blockchainMap = {
            defilamaEth: 'eth',
            defilamaBsc: 'bsc',
            jupiter: 'solana',
        };

        const truncateAddress = (address) => {
            if (!address) return '';
            return `${address.slice(0, 6)}...${address.slice(-6)}`;
        };

        const CopyIcon = () => (
            <svg 
                width="18" 
                height="19" 
                viewBox="0 0 18 19" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg" 
                style={{marginLeft: '8px', cursor: 'pointer'}}
                className={styles.copyIcon}
            >
                <rect y="0.25" width="18" height="18" rx="4.5" fill="white" fillOpacity="0.14"/>
                <path d="M4.83325 7.58325C4.83325 6.40474 4.83325 5.81549 5.19937 5.44937C5.56549 5.08325 6.15474 5.08325 7.33325 5.08325H8.16658C9.3451 5.08325 9.93435 5.08325 10.3005 5.44937C10.6666 5.81549 10.6666 6.40474 10.6666 7.58325V8.41658C10.6666 9.5951 10.6666 10.1844 10.3005 10.5505C9.93435 10.9166 9.3451 10.9166 8.16659 10.9166H7.33325C6.15474 10.9166 5.56549 10.9166 5.19937 10.5505C4.83325 10.1844 4.83325 9.5951 4.83325 8.41659V7.58325Z" fill="white" fillOpacity="0.16"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M7.33325 4.77075L7.31039 4.77075C6.74056 4.77074 6.28126 4.77074 5.92002 4.8193C5.54498 4.86973 5.2292 4.9776 4.9784 5.2284C4.7276 5.4792 4.61973 5.79498 4.5693 6.17002C4.52074 6.53126 4.52074 6.99056 4.52075 7.56039L4.52075 7.58325V8.41659L4.52075 8.43945C4.52074 9.00928 4.52074 9.46858 4.5693 9.82982C4.61973 10.2049 4.7276 10.5206 4.9784 10.7714C5.2292 11.0222 5.54498 11.1301 5.92002 11.1805C6.21794 11.2206 6.58256 11.2276 7.02087 11.2288C7.02161 11.6653 7.02689 12.0263 7.0643 12.3203C7.10947 12.6752 7.20588 12.9762 7.43126 13.2222C7.46197 13.2557 7.49413 13.2879 7.52764 13.3186C7.7736 13.544 8.07468 13.6404 8.42957 13.6855C8.7719 13.7291 9.20502 13.7291 9.74231 13.7291H9.76381H10.6666H10.6895C11.2593 13.7291 11.7186 13.7291 12.0798 13.6805C12.4549 13.6301 12.7706 13.5222 13.0214 13.2714C13.2722 13.0206 13.3801 12.7049 13.4305 12.3298C13.4791 11.9686 13.4791 11.5093 13.4791 10.9395V10.9166V10.0138V9.99231C13.4791 9.45502 13.4791 9.0219 13.4355 8.67957C13.3904 8.32468 13.294 8.0236 13.0686 7.77764C13.0379 7.74413 13.0057 7.71197 12.9722 7.68126C12.7262 7.45588 12.4252 7.35947 12.0703 7.3143C11.7763 7.27689 11.4153 7.27161 10.9788 7.27087C10.9776 6.83256 10.9706 6.46794 10.9305 6.17002C10.8801 5.79498 10.7722 5.4792 10.5214 5.2284C10.2706 4.9776 9.95486 4.86973 9.57982 4.8193C9.21858 4.77074 8.75928 4.77074 8.18945 4.77075L8.16658 4.77075H7.33325Z" fill="white" fillOpacity="0.38"/>
            </svg>
        );

        const snipeType =
            formatExchangeTitle(exAsk) === 'SWAP'
                ? 'buy'
                : formatExchangeTitle(exBid) === 'SWAP'
                  ? 'sell'
                  : null;

        const blockchain =
            snipeType === 'buy'
                ? blockchainMap[exAsk] || exAsk
                : snipeType === 'sell'
                  ? blockchainMap[exBid] || exBid
                  : '';

        const tokenAddress =
            snipeType === 'buy'
                ? ContractAsk
                : snipeType === 'sell'
                  ? ContractBid
                  : '';

        const amount =
            snipeType === 'buy'
                ? AskAmountUSDT
                : snipeType === 'sell'
                  ? BidAmount
                  : 0;

        const wallet =
            snipeType === 'buy'
                ? userWallets[blockchainMap[exAsk]]
                : snipeType === 'sell'
                  ? userWallets[blockchainMap[exBid]]
                  : '';

        const snipeLink = `${process.env.REACT_APP_WIDGET_LINK}?blockchain=${blockchain}&id=${userId}&token=${Symbol}&token_address=${tokenAddress}&side=${snipeType}&amount=${amount}&wallet=${wallet}`;

        const exchangeItem = exchangeColors.find((item) => {
            if (isCexToDex && (item.ex === exBid || item.ex === exAsk)) {
                return true;
            } else {
                return false;
            }
        });

        const askItem = exchangeColors.find(
            (item) => isDexToDex && item.ex === exAsk
        );

        const bidItem = exchangeColors.find(
            (item) => isDexToDex && item.ex === exBid
        );

        const copyToClipboard = (text) => {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    // alert('Адрес Контракта скопирован в буфер обмена!');
                })
                .catch((err) => {
                    // console.error('Не удалось скопировать текст: ', err);
                });
        };

        return (
            <div className={styles['arbitrageListItem']}>
                <div className={styles['arbitrageListItem__header']}>
                    <h5 className={styles['arbitrageListItem__title']}>
                        {Symbol.toUpperCase().replace('USDT', '')}
                    </h5>
                    <div className={styles['arbitrageListItem__headerBtns']}>
                        {isArb ? (
                            <>
                                <button
                                    className={
                                        styles['arbitrageListItem__hiderBtn']
                                    }
                                    onClick={() => onSendReportMessage(item)}
                                >
                                    <Svg
                                        id={reportFakeIcon}
                                        className={
                                            styles[
                                                'arbitrageListItem__reportIcon'
                                            ]
                                        }
                                    />
                                </button>
                                <button
                                    onClick={() =>
                                        onAddToHidden({ Symbol, Ex1, Ex2 })
                                    }
                                    className={
                                        styles['arbitrageListItem__hiderBtn']
                                    }
                                >
                                    <Svg
                                        className={
                                            styles[
                                                'arbitrageListItem__hideIcon'
                                            ]
                                        }
                                        id={eyeIcon}
                                    />
                                </button>
                                <button
                                    className={
                                        styles['arbitrageListItem__hiderBtn']
                                    }
                                    onClick={() => onAddToBlackList(Symbol)}
                                >
                                    <Svg
                                        className={
                                            styles[
                                                'arbitrageListItem__crossIcon'
                                            ]
                                        }
                                        id={crossIcon}
                                    />
                                </button>
                            </>
                        ) : null}
                    </div>
                </div>
                <div
                    className={`${styles['arbitrageListItem__resultBlock']} ${styles['arbitrageListItem__resultBlock_output']}`}
                >
                    {isInputOutflowHintOpened && (
                        <div
                            ref={inputOutflowRef}
                            className={
                                styles['arbitrageListItem__outflowsHint']
                            }
                        >
                            {outflowsHint.error
                                ? outflowsHint.error
                                : outflowsHint.token &&
                                    outflowsHint.amount &&
                                    outflowsHint.amountUsdt &&
                                    outflowsHint.time
                                  ? `${outflowsHint.token} - ${outflowsHint.amount}(${outflowsHint.amountUsdt}$) - ${formatDateWithTime(outflowsHint.time)}`
                                  : 'Выводы не найдены'}
                            {!outflowsHint.error && outflowsHint.extLink && (
                                <a
                                    className={
                                        styles[
                                            'arbitrageListItem__outflowsHintLink'
                                        ]
                                    }
                                    href={outflowsHint.extLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    TX
                                    <Svg
                                        className={
                                            styles[
                                                'arbitrageListItem__outflowsHintLinkIcon'
                                            ]
                                        }
                                        id={externalLink}
                                    />
                                </a>
                            )}
                        </div>
                    )}
                    {!isDexToDex && !isCexToDex && (
                        <MainBtn
                            onClick={async () => {
                                try {
                                    const res = await fetchRequest(
                                        getCEXOutflowsUrl,
                                        'POST',
                                        {
                                            id: userId,
                                            Token: Symbol,
                                            Ex: Ex1,
                                            Blockchain: Chain,
                                        }
                                    );

                                    setOutflowsHint({
                                        amount: res.Amount,
                                        amountUsdt: res.AmountUSDT,
                                        time: res.Timestamp,
                                        token: res.Token,
                                        extLink: res.Tx,
                                        error: null,
                                    });
                                    setIsInputOutflowHintOpened(
                                        (prev) => !prev
                                    );
                                } catch (error) {
                                    setOutflowsHint({
                                        error: 'Ошибка при загрузке',
                                    });
                                    setIsInputOutflowHintOpened(
                                        (prev) => !prev
                                    );
                                }
                            }}
                            className={styles['arbitrageListItem__outflowsBtn']}
                        >
                            OUTFLOWS
                        </MainBtn>
                    )}
                    {isCexToDex && snipeType === 'sell' && (
                        <MainBtn
                            onClick={async () => {
                                try {
                                    const res = await fetchRequest(
                                        getCEXOutflowsUrl,
                                        'POST',
                                        {
                                            id: userId,
                                            Token: Symbol,
                                            Ex: Ex1,
                                            Blockchain: Chain,
                                        }
                                    );

                                    setOutflowsHint({
                                        amount: res.Amount,
                                        amountUsdt: res.AmountUSDT,
                                        time: res.Timestamp,
                                        token: res.Token,
                                        extLink: res.Tx,
                                        error: null,
                                    });
                                    setIsInputOutflowHintOpened(
                                        (prev) => !prev
                                    );
                                } catch (error) {
                                    setOutflowsHint({
                                        error: 'Ошибка при загрузке',
                                    });
                                    setIsInputOutflowHintOpened(
                                        (prev) => !prev
                                    );
                                }
                            }}
                            className={styles['arbitrageListItem__outflowsBtn']}
                        >
                            OUTFLOWS
                        </MainBtn>
                    )}
                    <p className={styles['arbitrageListItem__resultBlockText']}>
                        <span
                            className={
                                styles[
                                    'arbitrageListItem__resultBlockText_green'
                                ]
                            }
                        >
                            BUY:{' '}
                        </span>
                        <span style={{color: '#FFFFFF61'}}>[</span>
                        {AskAmountUSDT.toFixed(4)} USDT
                        <span style={{color: '#FFFFFF61'}}>]
                        </span> <span style={{fontSize: '10px', opacity: '0.8', marginLeft: '5px', marginTop: '5px'}}>{AskAmount.toFixed(4)}</span>
                        </p>
                    <p className={styles['arbitrageListItem__resultBlockText']}>
                        <span
                            className={
                                styles[
                                    'arbitrageListItem__resultBlockText_green'
                                ]
                            }
                        >
                            ASK:{' '}
                        </span>
                        {AskPrice} - {AskCount} {setOrderText(AskCount)}
                    </p>
                    {!isDexToDex ? (
                        <p
                            className={
                                styles['arbitrageListItem__resultBlockText']
                            }
                        >
                            <span
                                className={
                                    styles[
                                        'arbitrageListItem__resultBlockText_green'
                                    ]
                                }
                            >
                                Сеть:{' '}
                            </span>
                            {Chain}
                        </p>
                    ) : null}
                    <p className={styles['arbitrageListItem__resultBlockText']}>
                        <span
                            className={
                                styles[
                                    'arbitrageListItem__resultBlockText_green'
                                ]
                            }
                        >
                            Комиссия Перевода:{' '}
                        </span>
                        {FeeUSDT.toFixed(4)}$
                    </p>

                    <p className={styles['arbitrageListItem__resultBlockText']}>
                        <span
                            className={
                                styles[
                                    'arbitrageListItem__resultBlockText_green'
                                ]
                            }
                        >
                            Купить:{' '}
                        </span>
                        <a
                            className={
                                styles['arbitrageListItem__resultBlockLinkText']
                            }
                            target={'_blank'}
                            rel="noreferrer"
                            href={linkAsk}
                        >
                            {formatExchangeTitle(exAsk)}
                        </a>
                    </p>
                    {!isCexToDex && isDexToDex ? (
                        <p
                            className={
                                styles['arbitrageListItem__resultBlockText']
                            }
                        >
                            <span
                                className={
                                    styles[
                                        'arbitrageListItem__resultBlockText_green'
                                    ]
                                }
                                onClick={() => copyToClipboard(ContractAsk)}
                                style={{ cursor: 'pointer' }}
                            >
                                Адрес Контракта:
                            </span>
                            {/* <span className={styles["arbitrageListItem__resultBlockText_green"]}>Адрес Контракта: </span> */}
                            <a
                                className={
                                    styles[
                                        'arbitrageListItem__resultBlockLinkText'
                                    ]
                                }
                                target={'_blank'}
                                rel="noreferrer"
                                href={ContractAskLink}
                            >
                                {ContractAsk}
                            </a>
                        </p>
                    ) : null}

                    <div className={styles['arbitrageListItem__infoBlock']}>
                        {!isCexToDex && isDexToDex ? (
                            <div
                                className={
                                    styles['arbitrageListItem__exhcangeBlock']
                                }
                                style={{
                                    backgroundColor: askItem.color,
                                }}
                            >
                                <h6
                                    className={
                                        styles[
                                            'arbitrageListItem__exhcangeBlockText'
                                        ]
                                    }
                                    style={{
                                        color: askItem.colorText || '#fff',
                                    }}
                                >
                                    {askItem.name}
                                </h6>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div
                    className={`${styles['arbitrageListItem__resultBlock']} ${styles['arbitrageListItem__resultBlock_input']}`}
                >
                    {isOutputOutflowHintOpened && (
                        <div
                            ref={outputOutflowRef}
                            className={
                                styles['arbitrageListItem__outflowsHint']
                            }
                        >
                            {outflowsHint.error
                                ? outflowsHint.error
                                : outflowsHint.token &&
                                    outflowsHint.amount &&
                                    outflowsHint.amountUsdt &&
                                    outflowsHint.time
                                  ? `${outflowsHint.token} - ${outflowsHint.amount}(${outflowsHint.amountUsdt}$) - ${formatDateWithTime(outflowsHint.time)}`
                                  : 'Выводы не найдены'}
                            {!outflowsHint.error && outflowsHint.extLink && (
                                <a
                                    className={
                                        styles[
                                            'arbitrageListItem__outflowsHintLink'
                                        ]
                                    }
                                    href={outflowsHint.extLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    TX
                                    <Svg
                                        className={
                                            styles[
                                                'arbitrageListItem__outflowsHintLinkIcon'
                                            ]
                                        }
                                        id={externalLink}
                                    />
                                </a>
                            )}
                        </div>
                    )}
                    {!isDexToDex && !isCexToDex && (
                        <MainBtn
                            onClick={async () => {
                                try {
                                    const res = await fetchRequest(
                                        getCEXOutflowsUrl,
                                        'POST',
                                        {
                                            id: userId,
                                            Token: Symbol,
                                            Ex: Ex2,
                                            Blockchain: Chain,
                                        }
                                    );

                                    setOutflowsHint({
                                        amount: res.Amount,
                                        amountUsdt: res.AmountUSDT,
                                        time: res.Timestamp,
                                        token: res.Token,
                                        extLink: res.Tx,
                                        error: null,
                                    });
                                    setIsOutputOutflowHintOpened(
                                        (prev) => !prev
                                    );
                                } catch (error) {
                                    setOutflowsHint({
                                        error: 'Ошибка при загрузке',
                                    });
                                    setIsOutputOutflowHintOpened(
                                        (prev) => !prev
                                    );
                                }
                            }}
                            className={styles['arbitrageListItem__outflowsBtn']}
                        >
                            OUTFLOWS
                        </MainBtn>
                    )}
                    {isCexToDex && snipeType === 'buy' && (
                        <MainBtn
                            onClick={async () => {
                                try {
                                    const res = await fetchRequest(
                                        getCEXOutflowsUrl,
                                        'POST',
                                        {
                                            id: userId,
                                            Token: Symbol,
                                            Ex: Ex2,
                                            Blockchain: Chain,
                                        }
                                    );

                                    setOutflowsHint({
                                        amount: res.Amount,
                                        amountUsdt: res.AmountUSDT,
                                        time: res.Timestamp,
                                        token: res.Token,
                                        extLink: res.Tx,
                                        error: null,
                                    });
                                    setIsOutputOutflowHintOpened(
                                        (prev) => !prev
                                    );
                                } catch (error) {
                                    setOutflowsHint({
                                        error: 'Ошибка при загрузке',
                                    });
                                    setIsOutputOutflowHintOpened(
                                        (prev) => !prev
                                    );
                                }
                            }}
                            className={styles['arbitrageListItem__outflowsBtn']}
                        >
                            OUTFLOWS
                        </MainBtn>
                    )}

                    <p className={styles['arbitrageListItem__resultBlockText']}>
                        <span
                            className={
                                styles['arbitrageListItem__resultBlockText_red']
                            }
                        >
                            SELL:{' '}
                        </span>
                        <span style={{color: '#FFFFFF61'}}>[</span>
                        {BidAmountUSDT.toFixed(4)} USDT
                        <span style={{color: '#FFFFFF61'}}>]</span>
                        <span style={{fontSize: '10px', opacity: '0.8', marginLeft: '5px', marginTop: '5px'}}>{BidAmount.toFixed(4)}</span>

                    </p>
                    <p className={styles['arbitrageListItem__resultBlockText']}>
                        <span
                            className={
                                styles['arbitrageListItem__resultBlockText_red']
                            }
                        >
                            BID:{' '}
                        </span>
                        {BidPrice} - {BidCount} {setOrderText(BidCount)}
                    </p>
                    {!isDexToDex ? (
                        <p
                            className={
                                styles['arbitrageListItem__resultBlockText']
                            }
                        >
                            <span
                                className={
                                    styles[
                                        'arbitrageListItem__resultBlockText_red'
                                    ]
                                }
                            >
                                Сеть:{' '}
                            </span>
                            {Chain}
                        </p>
                    ) : null}
                    <p className={styles['arbitrageListItem__resultBlockText']}>
                        <span
                            className={
                                styles['arbitrageListItem__resultBlockText_red']
                            }
                        >
                            Продать:{' '}
                        </span>
                        <a
                            className={
                                styles['arbitrageListItem__resultBlockLinkText']
                            }
                            target={'_blank'}
                            rel="noreferrer"
                            href={linkBid}
                        >
                            {formatExchangeTitle(exBid)}
                        </a>
                    </p>
                    {isCexToDex ? (
                    <p className={styles['arbitrageListItem__resultBlockText']}>
                    <span className={styles['arbitrageListItem__resultBlockText_red']}>
                        Адрес Контракта:
                    </span>
                    <a className={styles['arbitrageListItem__resultBlockLinkText']}
                        target={'_blank'}
                        rel="noreferrer"
                        href={ContractLink}>
                        {truncateAddress(Contract)}
                    </a>
                    <span onClick={() => copyToClipboard(Contract)}>
                        <CopyIcon />
                    </span>
                </p>
                ) : null}

                {!isCexToDex && isDexToDex ? (
                    <p className={styles['arbitrageListItem__resultBlockText']}>
                        <span className={styles['arbitrageListItem__resultBlockText_red']}
                            onClick={() => copyToClipboard(ContractBid)}
                            style={{ cursor: 'pointer' }}>
                            Адрес Контракта:
                        </span>
                        <a className={styles['arbitrageListItem__resultBlockLinkText']}
                            target={'_blank'}
                            rel="noreferrer"
                            href={ContractBidLink}>
                            {ContractBid}
                        </a>
                    </p>
                ) : null}

                {!isCexToDex && isDexToDex ? (
                    <div className={styles['arbitrageListItem__exhcangeBlock']}
                        style={{backgroundColor: bidItem.color}}>
                        <h6 className={styles['arbitrageListItem__exhcangeBlockText']}
                            style={{color: bidItem.colorText || '#fff'}}>
                            {bidItem.name}
                        </h6>
                    </div>
                ) : null}

                <div className={styles['arbitrageListItem__infoBlock']}>
                </div>

                {isCexToDex && !isDexToDex ? (
                    <div className={styles['arbitrageListItem__exhcangeBlock']}
                        style={{backgroundColor: exchangeItem?.color}}>
                        <h6 className={styles['arbitrageListItem__exhcangeBlockText']}
                            style={{color: exchangeItem?.colorText || '#fff'}}>
                            {exchangeItem?.name}
                        </h6>
                    </div>
                ) : null}
                </div>


                {isCexToDex && !isDexToDex ? (
                    <div
                        className={
                            styles['arbitrageListItem__purchaseInfoBlock']
                        }
                    >                        
                        <p
                            className={`${styles['arbitrageListItem__infoText']} `}
                        >
                            SellTax:{' '}
                            <span
                                className={`
                            ${
                                SellTax === '0'
                                    ? styles[
                                          'arbitrageListItem__resultBlockText_green'
                                      ]
                                    : SellTax !== '0'
                                      ? styles[
                                            'arbitrageListItem__resultBlockText_red'
                                        ]
                                      : ''
                            }`}
                            >
                                {SellTax}%{' '}
                            </span>
                            <span className={styles['arbitrageListItem__divider']}>|</span>
                            BuyTax:{' '}
                            <span
                                className={`
                            ${
                                BuyTax === '0'
                                    ? styles[
                                          'arbitrageListItem__resultBlockText_green'
                                      ]
                                    : BuyTax !== '0'
                                      ? styles[
                                            'arbitrageListItem__resultBlockText_red'
                                        ]
                                      : ''
                            }`}
                            >
                                {BuyTax}%{' '}
                            </span>
                            <span className={styles['arbitrageListItem__divider']}>|</span>
                            TaxMod:{' '}
                            <span
                                className={`
                            ${
                                CanChange === 'disable'
                                    ? styles[
                                          'arbitrageListItem__resultBlockText_green'
                                      ]
                                    : CanChange === 'enable'
                                      ? styles[
                                            'arbitrageListItem__resultBlockText_red'
                                        ]
                                      : ''
                            }`}
                            >
                                {CanChange.length > 0 ? CanChange : 'unknown'}
                            </span>
                        </p>
                    </div>
                ) : null}
                {isCexToDex && !isDexToDex && (
                    <b className={styles['arbitrageListItem__line']} />
                )}


                <p className={styles['arbitrageListItem__profitText']}>
                    TOTAL COMMISSION:
                    <span
                        className={styles['arbitrageListItem__profitText_white']}
                    >
                        {' '}
                        {SpotFee.toFixed(4)}$
                    </span>
                </p>
                <p className={styles['arbitrageListItem__profitText']}>
                    PROFIT:
                    <span className={styles['arbitrageListItem__profitText_white']}>
                        {' '}
                        {Profit.toFixed(4)}$ 
                        <span className={styles['arbitrageListItem__profitPercentBlock']}>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.333252" y="0.5" width="14" height="14" rx="4" fill="#58F172" fillOpacity="0.16"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.61377 5.91668C8.61377 5.71776 8.77502 5.55651 8.97394 5.55651H11.4739C11.6729 5.55651 11.8341 5.71776 11.8341 5.91668V8.41667C11.8341 8.61558 11.6729 8.77684 11.4739 8.77684C11.275 8.77684 11.1138 8.61558 11.1138 8.41667V6.78621L9.22862 8.67135C9.22098 8.67899 9.21345 8.68653 9.20603 8.69396C9.08498 8.81512 8.99162 8.90856 8.89398 8.97638C8.441 9.29101 7.84021 9.29101 7.38723 8.97638C7.28959 8.90856 7.19623 8.81512 7.07518 8.69396C7.06775 8.68653 7.06023 8.67899 7.05259 8.67135C6.89825 8.51701 6.85378 8.47458 6.81637 8.44859C6.61047 8.30558 6.33738 8.30558 6.13148 8.44859C6.09408 8.47458 6.04961 8.51701 5.89527 8.67135L4.22862 10.338C4.08796 10.4787 3.85992 10.4787 3.71926 10.338C3.57861 10.1973 3.57861 9.9693 3.71926 9.82864L5.38591 8.16199C5.39355 8.15436 5.40107 8.14682 5.4085 8.13939C5.52955 8.01823 5.62291 7.92479 5.72055 7.85697C6.17353 7.54234 6.77432 7.54234 7.2273 7.85697C7.32494 7.92479 7.4183 8.01823 7.53936 8.13939C7.54678 8.14682 7.55431 8.15436 7.56194 8.162C7.71629 8.31634 7.76075 8.35877 7.79816 8.38475C8.00406 8.52776 8.27715 8.52776 8.48305 8.38475C8.52046 8.35877 8.56492 8.31634 8.71927 8.162L10.6044 6.27685H8.97394C8.77502 6.27685 8.61377 6.1156 8.61377 5.91668Z" fill="#58F172"/>
                            </svg>
                            {ProfitPct.toFixed(4)}%
                        </span>
                    </span>
                </p>
                {!isCexToDex && isDexToDex && (
                    <button
                        className={styles['arbitrageListItem__exhcangeBlock']}
                        style={{
                            backgroundColor: '#6F758E',
                        }}
                    >
                        <h5
                            className={styles['arbitrageListItem__bridge']}
                            onClick={() =>
                                openNewBrowserWindow(
                                    ContractAsk,
                                    askItem.id,
                                    ContractBid,
                                    bidItem.id,
                                    AskAmount
                                )
                            }
                        >
                            Bridge
                        </h5>
                    </button>
                )}
                <div className={styles['arbitrageListItem__footer']}>
                    {isCexToDex && (
                        <div
                            className={styles['arbitrageListItem__footerBtns']}
                        >
                            <MainBtn
                                onClick={() => {
                                    window.open(
                                        snipeLink,

                                        '_blank',
                                        'width=600,height=700'
                                    );
                                }}
                                className={
                                    styles['arbitrageListItem__snipeBtn']
                                }
                            >
                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1293_45601)">
                                        <path d="M8 0.25C3.58524 0.25 0 3.83524 0 8.25C0 12.6648 3.58524 16.25 8 16.25C12.4148 16.25 16 12.6648 16 8.25C16 3.83524 12.4148 0.25 8 0.25ZM8 0.842593C12.0945 0.842593 15.4074 4.15548 15.4074 8.25C15.4074 12.3445 12.0945 15.6574 8 15.6574C3.90548 15.6574 0.592593 12.3445 0.592593 8.25C0.592593 4.15548 3.90548 0.842593 8 0.842593ZM8 1.43519C4.23982 1.43519 1.18519 4.48982 1.18519 8.25C1.18519 12.0102 4.23982 15.0648 8 15.0648C11.7602 15.0648 14.8148 12.0102 14.8148 8.25C14.8148 4.48982 11.7602 1.43519 8 1.43519ZM7.7037 2.03495V5.28704C7.70139 5.45053 7.832 5.58499 7.9955 5.58748C8.16255 5.59003 8.29867 5.45403 8.2963 5.28704V2.03495C11.5001 2.1847 14.0654 4.74991 14.2152 7.9537H10.963C10.5623 7.94801 10.5623 8.55198 10.963 8.5463H14.2152C14.0654 11.7501 11.5001 14.3153 8.2963 14.4651V11.213C8.29867 11.0459 8.16255 10.91 7.9955 10.9125C7.832 10.915 7.70139 11.0495 7.7037 11.213V14.4651C4.49991 14.3153 1.9347 11.7501 1.78495 8.5463H5.03704C5.43775 8.55198 5.43775 7.94801 5.03704 7.9537H1.78495C1.9347 4.74997 4.49991 2.18476 7.7037 2.03495Z" fill="#E7E7E8"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1293_45601">
                                        <rect width="16" height="16" fill="white" transform="translate(0 0.25)"/>
                                        </clipPath>
                                    </defs>
                                </svg>

                                SNIPE
                            </MainBtn>
                        </div>
                    )}
                    <div className={styles['arbitrageListItem__footerBlock']}>
                        <div>
                            <p
                                className={
                                    styles['arbitrageListItem__footerText']
                                }
                            >
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: '5px', verticalAlign: 'bottom'}}>
                                    <rect x="2.33325" y="2" width="12" height="12" rx="6" fill="#5B5B7B" fillOpacity="0.16"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.33325 1.5C6.18488 1.5 4.57551 2.39727 3.51316 3.28256C3.25521 3.49751 3.02862 3.71242 2.83325 3.91478V2.66667C2.83325 2.39052 2.60939 2.16667 2.33325 2.16667C2.05711 2.16667 1.83325 2.39052 1.83325 2.66667V5.33333C1.83325 5.60948 2.05711 5.83333 2.33325 5.83333H4.66659C4.94273 5.83333 5.16659 5.60948 5.16659 5.33333C5.16659 5.05719 4.94273 4.83333 4.66659 4.83333H3.34496C3.55748 4.59466 3.82707 4.32267 4.15334 4.05078C5.091 3.2694 6.48163 2.5 8.33325 2.5C11.3894 2.5 13.8333 4.96137 13.8333 8C13.8333 11.0376 11.3708 13.5 8.33325 13.5C5.29569 13.5 2.83325 11.0376 2.83325 8C2.83325 7.72386 2.60939 7.5 2.33325 7.5C2.05711 7.5 1.83325 7.72386 1.83325 8C1.83325 11.5899 4.7434 14.5 8.33325 14.5C11.9231 14.5 14.8333 11.5899 14.8333 8C14.8333 4.41122 11.9438 1.5 8.33325 1.5ZM8.83325 5.33333C8.83325 5.05719 8.60939 4.83333 8.33325 4.83333C8.05711 4.83333 7.83325 5.05719 7.83325 5.33333V8C7.83325 8.16718 7.9168 8.32329 8.0559 8.41603L10.0559 9.74936C10.2857 9.90253 10.5961 9.84045 10.7493 9.61068C10.9025 9.38092 10.8404 9.07048 10.6106 8.91731L8.83325 7.73241V5.33333Z" fill="#5B5B7B"/>
                                </svg>
                                Spotted: <span style={{color: '#C1CAFC'}}>{setDateText(spotedText)}</span>
                            </p>
                        </div>
                        <div>
                            {isCexToDex && !isDexToDex ? (
                                <div>
                                    <a
                                        className={
                                            styles[
                                                'arbitrageListItem__footerText'
                                            ]
                                        }
                                        target={'_blank'}
                                        rel="noreferrer"
                                        href={HoldersLink}
                                    >
                                        HOLDERS{' '}
                                    </a>
                                    <span className={styles['arbitrageListItem__dividersec']}>|</span>
                                    <a
                                        className={
                                            styles[
                                                'arbitrageListItem__footerText'
                                            ]
                                        }
                                        target={'_blank'}
                                        rel="noreferrer"
                                        href={DexScreenerLink}
                                    >
                                        {' '}
                                        
                                        DEX SCREENER
                                    </a>
                                    <span className={styles['arbitrageListItem__dividersec']}>|</span>
                                    <a
                                        className={
                                            styles[
                                                'arbitrageListItem__footerText'
                                            ]
                                        }
                                        target={'_blank'}
                                        rel="noreferrer"
                                        href={GoPlusLink}
                                    >
                                        {' '}
                                        GoPlus
                                    </a>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default ArbitragePageListItem;
