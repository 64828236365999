import React from 'react';
import { useSelector } from 'react-redux';
import {
    chainsDefTokens,
    chainsNamesMap,
} from '../../../pages/DexTerminal/config';
import styles from './styles.module.scss';

const ChainPositions = () => {
    const positions = useSelector((state) => state.sniper.positions);
    const blockchain = useSelector((state) => state.sniper.blockchain);

    const filteredPos = positions.filter(
        (pos) => pos.blockchain === blockchain
    );

    const chainWorth = filteredPos.reduce((acc, pos) => {
        return acc + pos.priceUsdt;
    }, 0);

    return (
        <>
            <div className={styles.chainPositionsHeader}>
                <p className={styles.chainsPositionsData}>
                    <span className={styles.chainPositionsName}>
                        {chainsNamesMap[blockchain]}
                    </span>
                    <span className={styles.chainPositionsCount}>
                        {filteredPos.length} tokens
                    </span>
                </p>
                <span className={styles.chainsPositionsWorth}>
                    ${chainWorth}
                </span>
            </div>

            <div className={styles.posList}>
                {filteredPos.map((pos) => (
                    <div className={styles.posItem} key={pos.address}>
                        <div className={styles.posImgContainer}>
                            <img src={pos.img} alt={pos.name} />
                        </div>
                        <p className={styles.posData}>
                            <span className={styles.posName}>{pos.name}</span>
                            <span className={styles.posPriceNative}>
                                ≈{pos.priceNative} {chainsDefTokens[blockchain]}
                            </span>
                        </p>
                        <span className={styles.posPriceUsdt}>
                            ${pos.priceUsdt}
                        </span>
                    </div>
                ))}
            </div>
        </>
    );
};

export default ChainPositions;
