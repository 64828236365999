import React from 'react';
import { sniperWallet } from '../../../assets/svg';
import Svg from '../../layout/Svg/Svg';
import styles from './Header.module.scss';

const Header = ({ addWalletHandler, openPortfolioHandler }) => {
    return (
        <header className={styles.headerContainer}>
            <h1 className={styles.title}>Dex terminal</h1>
            <div className={styles.headerBtns}>
                <button onClick={addWalletHandler} className={styles.headerBtn}>
                    <Svg className={styles.walletIcon} id={sniperWallet} />
                    <span className={styles.headerBtnText}>Add wallet</span>
                </button>
                <button
                    onClick={openPortfolioHandler}
                    className={styles.headerBtn}
                >
                    <span className={styles.headerBtnText}>Portfolio</span>
                </button>
            </div>
        </header>
    );
};

export default Header;
