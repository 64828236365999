import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CopyIcon from '../../../../assets/icons/sniper/copy.svg';
import SelectIcon from '../../../../assets/icons/sniper/select-white.svg';
import WalletIcon from '../../../../assets/icons/sniper/wallet.svg';
import { formatWalletAddress } from '../../../../utils/functions/format';
import ExtendedInput from '../../../layout/ExtendedInput';
import styles from './styles.module.scss';

const CustomWalletMenu = ({ wallet, onWalletChange, changeWallet }) => {
    const userWallets = useSelector((state) => state.auth.user?.user_wallets);
    const blockchain = useSelector((state) => state.sniper.blockchain);

    const [walletsList, setWalletsList] = useState([]);
    const [isWalletListOpened, setIsWalletListOpened] = useState(false);

    useEffect(() => {
        if (userWallets) {
            setWalletsList(userWallets[blockchain] || []);
        }
    }, [blockchain, userWallets]);

    return (
        <div className={styles.customWalletMenu}>
            <ExtendedInput
                wrapperClassName={styles.mainInput}
                placeholder={'WALLET ADDRESS'}
                value={wallet}
                onChange={onWalletChange}
                type="text"
            />
            <button
                onClick={() => setIsWalletListOpened((prev) => !prev)}
                className={styles.customWalletSelectBtn}
            >
                <span>Select wallet</span>
                <img src={SelectIcon} alt="select" />
            </button>
            {isWalletListOpened && (
                <div className={styles.walletsList}>
                    {walletsList.map((wallet, index) => (
                        <div
                            className={styles.walletsListItem}
                            key={index}
                            onClick={() => {
                                changeWallet(wallet.wallet_address);
                                setIsWalletListOpened(false);
                            }}
                        >
                            <div className={styles.walletItemName}>
                                <div className={styles.walletItemImgContainer}>
                                    <img src={WalletIcon} alt="wallet" />
                                </div>
                                <span>{wallet.wallet_label}</span>
                            </div>
                            <div className={styles.walletItemAddress}>
                                <span>
                                    {formatWalletAddress(wallet.wallet_address)}
                                </span>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard
                                            .writeText(wallet.wallet_address)
                                            .catch((err) => {
                                                console.error(
                                                    'Не удалось скопировать текст: ',
                                                    err
                                                );
                                            });
                                    }}
                                    className={styles.walletItemImgContainer}
                                >
                                    <img src={CopyIcon} alt="copy" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomWalletMenu;
