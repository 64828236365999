import cn from 'classnames';
import React from 'react';
import DarkDividerIcon from '../../../../assets/icons/sniper/hints/dark-divider.png';
import HighlighIcon from '../../../../assets/icons/sniper/input-highlight.png';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import styles from './styles.module.scss';

const Hint = ({ icon, title, info, onHintClose, className, isOpened }) => {
    const ref = useOutsideClick(onHintClose);

    return (
        <div
            ref={ref}
            className={cn(
                styles.hintContainer,
                isOpened && styles.hintContainer_visible,
                className
            )}
        >
            <div className={styles.hintHeader}>
                <img src={icon} alt={title} />
                <p className={styles.hintTitle}>{title}</p>
                <button className={styles.closeBtn} onClick={onHintClose}>
                    Hide
                </button>
                <img
                    className={styles.hintHighlight}
                    src={HighlighIcon}
                    alt="highlight"
                />
            </div>
            <img src={DarkDividerIcon} alt="divider" />
            <div className={styles.hintInfoList}>
                {info.map((info, index) => (
                    <p key={index} className={styles.hintText}>
                        <span className={styles.hintSubTitle}>
                            {info.subTitle}
                        </span>{' '}
                        <span>{info.description}</span>
                    </p>
                ))}
            </div>
        </div>
    );
};

export default Hint;
